import { PropertyMetadata } from '../../../models/DeviceDetail';
import { UiProfileProperty } from '../../../models/UiProfile';

export function overrideNumberUiDefinition(
    uiProperty: UiProfileProperty,
    metadata: PropertyMetadata,
): UiProfileProperty {
    const editor = {
        ...uiProperty.editor,
        ...(metadata.min ? { min: metadata.min } : {}),
        ...(metadata.max ? { max: metadata.max } : {}),
    };
    return { ...uiProperty, editable: metadata.editable, editor };
}
