import type { Intervention } from '../../../interventions/models/Intervention';
import {
    InterventionAction,
    type InterventionCreateRequest,
} from '../../../interventions/models/InterventionCreateRequest';
import type { PendingInterventionCreateRequest } from '../../../interventions/models/PendingInterventionCreateRequest';
import { Formatter } from '../../../shared/models/Formatter';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';
import { DeviceEditablePropertyValue } from '../../components/device-detail/editor-builder/buildEditor';
import formatValue from '../../components/device-detail/viewer-builder/formatValue';
import { DeviceMS } from '../../models/Device';
import { Execution } from '../../models/Execution';

export function buildPendingDeviceAction(
    device: DeviceMS,
    installerId: string,
    updateTask: Execution,
    sourceCommand: UpdateDeviceCommand,
    valueFormatter: Formatter | undefined,
): PendingInterventionCreateRequest {
    return {
        ...buildPendingActionBase(device, installerId, updateTask, sourceCommand),
        action: InterventionAction.UPDATE_DEVICE,
        data: {
            attribute: sourceCommand.property.label,
            value: formatValue(sourceCommand.newValue as DeviceEditablePropertyValue, valueFormatter),
            queued: false,
            translateValue: valueFormatter?.type === 'named-value',
        },
    };
}

export function buildPendingRebootAction(
    device: DeviceMS,
    installerId: string,
    updateTask: Execution,
    sourceCommand: RebootBoxCommand,
): PendingInterventionCreateRequest {
    return {
        ...buildPendingActionBase(device, installerId, updateTask, sourceCommand),
        action: InterventionAction.REBOOT_GATEWAY,
        data: {
            attribute: 'BOX_REBOOT',
            queued: false,
            translateValue: false,
        },
    };
}

export function buildPendingAccessTahomaAction(
    device: DeviceMS,
    installerId: string,
    intervention: Intervention,
): InterventionCreateRequest {
    return {
        action: InterventionAction.ACCESS_TAHOMA,
        id_session: intervention.sessionId,
        device_url: device.deviceurl,
        id_installer: installerId,
        id_site: device.site_id,
        data: {
            attribute: InterventionAction.ACCESS_TAHOMA,
            value: '',
            unit: '',
        },
    };
}

function buildPendingActionBase(
    device: DeviceMS,
    installerId: string,
    updateTask: Execution,
    sourceCommand: UpdateDeviceCommand | RebootBoxCommand,
): Omit<PendingInterventionCreateRequest, 'data' | 'action'> {
    return {
        job_id: updateTask.job_id,
        id_device: device.id,
        id_session: sourceCommand.sessionId,
        device_url: device.deviceurl,
        id_installer: installerId,
        id_site: sourceCommand.device.site_id,
    };
}
