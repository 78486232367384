@if (visible) {
  <div class="spinner-layer" [ngClass]="{ 'full-page': fullPage }">
    <div class="spinner">
      <svg [ngStyle]="{ width: size }">
        @if (showBackgroundCircle) {
          <circle [ngStyle]="{ 'stroke-width': strokeWidth }" class="back" cx="50%" cy="50%" r="30%"></circle>
        }
        <circle [ngStyle]="{ 'stroke-width': strokeWidth }" class="anim" cx="50%" cy="50%" r="30%"></circle>
      </svg>
    </div>
  </div>
}
