import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterServegoChangedMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-servego-changed-item',
    templateUrl: './servego-changed-item.component.html',
    styleUrls: ['./servego-changed-item.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class ServegoChangedComponent implements OnInit {
    @Input()
    content: ToasterServegoChangedMessageContent;

    @Input()
    id: string;

    translatedHeader = '';

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        // Format header
        if (this.content.hasAccess) {
            this.translatedHeader = this.translateService.instant('TOASTER_SERVEGO_GRANTED_HEADER', {
                userName: this.content.userSiteName,
            });
        } else {
            this.translatedHeader = this.translateService.instant('TOASTER_SERVEGO_DENIED_HEADER', {
                username: this.content.userSiteName,
            });
        }
    }

    onClose() {
        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }

    onRedirectToSite() {
        // Redirect to good page
        this.router.navigate([`/site/${this.content.siteId}`]);

        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }
}
