import { NamedValueEditorSpec } from '../../../../models/Editor';
import { UiProfileProperty } from '../../../../models/UiProfile';
import { SectionItemEditor } from '../buildEditor';

export function buildNamedValueEditor(uiProperty: UiProfileProperty, value: number): SectionItemEditor {
    const editor = uiProperty.editor as NamedValueEditorSpec;

    return {
        type: 'namedValue',
        value,
        config: {
            translationKey: uiProperty.label,
            possibleValues: editor.possibleValues,
        },
    };
}
