import { getIntervalStepsNb } from '../../../../../utils/number';
import { PropertyMetadata } from '../../../../models/DeviceDetail';
import { NumberEditorSpec } from '../../../../models/Editor';
import { UiProfileProperty } from '../../../../models/UiProfile';
import { overrideNumberUiDefinition } from '../../ui-definition-overrider/overrideNumberUiDefinition';
import { SectionItemEditor } from '../buildEditor';

export function buildNumberEditor(
    uiProperty: UiProfileProperty,
    value: string | number,
    metadata: PropertyMetadata,
): SectionItemEditor {
    const overriddenUiProperty = overrideNumberUiDefinition(uiProperty, metadata);
    const editor = overriddenUiProperty.editor as NumberEditorSpec;
    const stepsNb = getIntervalStepsNb(editor.max, editor.min, editor.step);

    return {
        type: stepsNb <= 1000 ? 'slider' : 'number',
        value: typeof value === 'string' ? parseInt(value, 10) : value,
        config: {
            min: editor.min,
            max: editor.max,
            step: editor.step,
            ...(overriddenUiProperty.formatter ? { formatter: overriddenUiProperty.formatter } : {}),
            ...(editor.labelMin ? { labelMin: editor.labelMin } : {}),
            ...(editor.labelMax ? { labelMax: editor.labelMax } : {}),
        },
    };
}
