import { Component, Input, OnChanges } from '@angular/core';
import { DEVICE_ICON_COLOR } from '../devices/device-icon/device-icon.component';

@Component({
    selector: 'app-site-icon',
    templateUrl: './site-icon.component.html',
    styleUrls: ['./site-icon.component.scss'],
    standalone: true,
})
export class SiteIconComponent implements OnChanges {
    @Input({ required: true }) sitePin: string;
    @Input() variant = DEVICE_ICON_COLOR.YELLOW;
    @Input() nbBoxes = 1;

    iconPath = null;

    private path = 'assets/img/devices/ic_Box/';

    private mapPINToBoxImage = {
        '02': 'ic_tahoma_V1_xlarge.svg',
        '04': 'ic_connexoon_xlarge.svg', // RTS
        '08': 'ic_connexoon_xlarge.svg', // IO
        '10': 'ic_din_rail_xlarge.svg',
        '11': 'ic_taHoma_v2_xlarge.svg', // RTS
        '12': 'ic_taHoma_v2_xlarge.svg', // IO
        '16': 'ic_tahoma_beecon_xlarge.svg',
        '20': 'ic_taHoma_switch_xlarge.svg',
        '21': 'ic_taHoma_mini_xlarge.svg',
        '22': 'ic_din_railV2_xlarge.svg',
    };

    ngOnChanges() {
        const index = this.sitePin?.substring(0, 2);

        this.iconPath = this.path + (this.mapPINToBoxImage[index] ?? 'ic_generic_xlarge.svg');
    }
}
