import { Injectable } from '@angular/core';
import { JSONPath } from 'jsonpath-plus';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Constants from '../../../shared/const';
import { HttpService } from '../../../shared/services/http.service';
import { DeviceId, DeviceMS, DevicePropertyValue, DeviceState } from '../../models/Device';
import { Device, DeviceProperties, DeviceProperty } from '../../models/DeviceDetail';
import { Execution } from '../../models/Execution';
import { DeviceGateway, RefreshDeviceStatesCommand, UpdateDeviceCommand } from './DeviceGateway';

const deviceRootStates = [
    'available',
    'creation_time',
    'deviceurl',
    'name',
    'product_profile',
    'protocol_profile',
    'software_version',
];

const boxRootStates = [
    'available',
    'capabilities',
    'details.type.label',
    'details.subType.label',
    'details.softwareVersion',
    'details.lastRebootOperation',
    'details.gatewayId',
    'deviceurl',
    'metadata',
    'name',
    'protocols',
    'updatestatus',
    'vertical_definition',
];

const rootStates = [...deviceRootStates, ...boxRootStates];

function mapRootStatesToProperties(data: DeviceMS): DeviceProperties {
    const properties: DeviceProperties = {};

    rootStates.forEach((statePath) => {
        const value = JSONPath({ json: data, path: `$..${statePath}` })[0];
        if (value === undefined) {
            return;
        }
        const propertyId = statePath.replace(/[.]/g, '_').toLowerCase();
        properties[propertyId] = {
            id: propertyId,
            value,
        };
    });

    return properties;
}

function isValueTypeSupported(value: DevicePropertyValue): boolean {
    // TODO validate string[] and number[] (with ajv?)
    return ['string', 'number', 'boolean'].includes(typeof value) || Array.isArray(value) || value === null;
}

function mapStateToProperty(state: DeviceState): DeviceProperty {
    const value = isValueTypeSupported(state.value) ? state.value : 'UNSUPPORTED VALUE: ' + JSON.stringify(state.value);

    // TODO use prefix "state." to distinct from root states?
    return {
        id: state.name,
        value,
        metadata: state.metadata,
    };
}

function mapStatesToProperties(data: DeviceMS): DeviceProperties {
    return data.states.reduce<DeviceProperties>((properties, state) => {
        properties[state.name] = mapStateToProperty(state);
        return properties;
    }, {});
}

@Injectable()
export class HttpDeviceGateway implements DeviceGateway {
    constructor(private httpService: HttpService) {}

    fetchDevice(deviceId: DeviceId): Observable<Device> {
        return this.httpService.get('/device/' + deviceId).pipe(
            map((rawDevice: DeviceMS) => {
                const properties = {
                    ...mapRootStatesToProperties(rawDevice),
                    ...mapStatesToProperties(rawDevice),
                };
                const isBox = Constants.GATEWAYS.includes(rawDevice.vertical_definition);

                return {
                    id: deviceId,
                    deviceUrl: rawDevice.deviceurl,
                    name: rawDevice.name,
                    icon: isBox ? rawDevice.deviceurl : rawDevice.vertical_definition,
                    boxPin: rawDevice.details?.gatewayId,
                    available: rawDevice.available,
                    profileId: isBox ? rawDevice.vertical_definition : rawDevice.protocol_profile,
                    lastUpdateTime: rawDevice.last_update_time,
                    rawData: rawDevice,
                    properties,
                };
            }),
            catchError(() => of(null)),
        );
    }

    updateDevice(updateRequest: UpdateDeviceCommand): Observable<Execution> {
        const { device, sessionId } = updateRequest;

        return this.httpService.patch(`/device`, {
            device: {
                id: device.id,
                type_id: device.type_id,
                deviceurl: device.deviceurl,
                protocol_profile: device.protocol_profile,
                // FIXME Following fields are not required for a device (maybe for gateway ?). To remove lately when Servego API will be refactored
                categories: device.categories,
                definition: device.definition,
                site_id: device.site_id,
                type: device.type,
            },
            command: {
                stateName: updateRequest.property.id,
                type: updateRequest.property.writeType,
                raw: false,
                value: updateRequest.newValue,
            },
            sessionId,
        });
    }

    refreshDeviceStates(refreshDeviceSectionCommand: RefreshDeviceStatesCommand): Observable<Execution[]> {
        const { device, states } = refreshDeviceSectionCommand;

        return this.httpService.patch('/device/' + device.id + '/refresh', {
            device,
            stateNames: states,
        });
    }
}
