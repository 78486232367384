import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { GatewayListItem } from '../../models/Gateway';
import { SetupId } from '../../models/Site';
import { SetupGateway } from './SetupGateway';

@Injectable()
export class HttpSetupGateway implements SetupGateway {
    constructor(private httpService: HttpService) {}

    fetchGateways(setupId: SetupId): Observable<GatewayListItem[]> {
        return this.httpService.get(`/site/${setupId}/gateways`);
    }
}
