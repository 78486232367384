export enum storageTypeDoc {
    USER_MANUAL = 'userManual',
    LEGAL_NOTICE = 'LegalNotice',
}

const constants = {
    OAUTH: {
        ACCESS_TOKEN_NAME: 'oauth2accesstoken',
        REFRESH_TOKEN_NAME: 'oauth2refreshtoken',
        SCOPE: 'oauth2scope',
        EXPIRE: 'oauth2expirein',
    },
    GATEWAYS: [
        'home_secure_gateway',
        'hub_tahoma_2',
        'hub_tahoma_1',
        'hub_tahoma_raildin',
        'hub_connexoon',
        'hub_tahoma_switch',
        'hub_tahoma_raildin_dtc',
    ],
    ALLOWED_GATEWAYS: [
        '04', // connexoon RTS
        '08', // connexoon IO
        '10', // din_rail
        '11', // taHoma_v2 RTS
        '12', // taHoma_v2 IO
        '20', // taHoma_switch
        '22', // din_railV2
    ],
    STORAGE_URL: {
        BASE_PATH: 'https://storage.googleapis.com/',
        USER_MANUAL_PATH: 'servego-docs/Serv-e-Go-User-Manual-',
        LEGAL_NOTICE_PATH: 'sevego-legal-notice/legal-notice-',
    },
    LOCAL_STORAGE_APP_PREFIX: 'serv-e-go',
    LOCAL_STORAGE_BYPASS_ONBOARDING: 'bypassonboarding',
};

export default constants;
