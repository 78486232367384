import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AdminAuthService } from '../services/auth.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {
    constructor(private authService: AdminAuthService) {}

    canActivate(): boolean {
        if (this.authService.authenticated) {
            // Authentication is done
            return true;
        } else {
            // If admin_access_token not valid. Redirect to login page

            if (location.hash.indexOf('?redirectTo') === -1) {
                let redirectTo = (location.hash || '').replace(new RegExp('^#+'), '');
                if (location.hash.indexOf('/password')) {
                    redirectTo = '/admin';
                }
                this.authService.login(redirectTo);
            }
            return false;
        }
    }
}
