import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToasterDeviceStateRefreshFailedMessage, ToasterMessageType } from '../models/Toaster';
import { addToasterMessage } from '../store/actions/addToasterMessage';
import { AppState } from '../store/app-state';

type ToasterErrorMessage = string;

@Injectable()
export class ErrorService {
    constructor(private store: Store<AppState>) {}

    public showToasterError(errorMessage: ToasterErrorMessage | ToasterDeviceStateRefreshFailedMessage, options?: any) {
        const duration = options && options.duration ? options.duration : undefined;
        if ((errorMessage as ToasterDeviceStateRefreshFailedMessage).type) {
            return this.store.dispatch(
                addToasterMessage({
                    message: errorMessage as ToasterDeviceStateRefreshFailedMessage,
                    duration,
                }),
            );
        }
        return this.store.dispatch(
            addToasterMessage({
                message: {
                    id: crypto.randomUUID(),
                    type: ToasterMessageType.ERROR,
                    content: {
                        errorMessage,
                    },
                },
                duration,
            }),
        );
    }
}
