export interface Execution {
    job_id: string;
    state?: string;
    deviceId?: string;
    status?: ExecutionStatus;
    failureType?: string;
}

export interface ExecutionInfo {
    job_id: string;
    siteId: string;
    status: ExecutionStatus;
    state: string;
    section: string;
    deviceId: string;
    date?: number;
}

export interface DeviceStateUpdateExecutionInfo {
    job_id: string;
    siteId: string;
    status: ExecutionStatus;
    state: string;
    deviceId: string;
    oldValue: any;
    newValue: any;
    date?: number;
}

export enum ExecutionStatus {
    INITIALIZED = 'INITIALIZED',
    NOT_TRANSMITTED = 'NOT_TRANSMITTED',
    TRANSMITTED = 'TRANSMITTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    QUEUED_SERVER_SIDE = 'QUEUED_SERVER_SIDE',
    QUEUED_GATEWAY_SIDE = 'QUEUED_GATEWAY_SIDE',
    SESSION_EXPIRED = 'Session Expired',
}
