<app-loader [visible]="userEnvLoading"></app-loader>
<header class="header-wrapper">
    <div class="left">
      <app-somfypro-logo [url]="somfyProUrl"></app-somfypro-logo>
      <div class="separator"><!--&#x7c;--></div>
      <div class="servego-logo-wrapper seg-cursor-pointer">
        <img class="serv-e-go-logo" src="assets/img/servego_yellow.svg" (click)="onServegoHeaderClick()">
      </div>
    </div>
    <div class="menu-items">
      <a [routerLink]="['/sites']"
         routerLinkActive="active"
      >
        <span class="regular">
          {{ 'DASHBOARD' | translate }}
        </span>
      </a>
      <a [routerLink]="['/messages']"
         (click)="analyticService.clickMessagesMenu()"
         routerLinkActive="active"
         class="messages-link"
      >
        <span
          class="regular"
          [matBadgeHidden]="!hasMessages"
          [matBadge]="numberOfUnreadMessages < 100 ? numberOfUnreadMessages : '99+'"
          matBadgeSize="small"
          matBadgeColor="warn">
          {{ 'MESSAGES' | translate }}
        </span>
      </a>
      <app-side-nav [userLogin]="userLogin" />
    </div>
  </header>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-toaster></app-toaster>

<app-iadvize></app-iadvize>
