import { createSelector } from '@ngrx/store';
import { getNbPendingAlerts } from '../../../alerts/store/selectors/alerts';
import { countAssignationSites } from '../../../site-assignation/store/selectors/site-assignation';
import { getUISiteMaxCount } from '../../../site-list/store/selectors/ui';

export const countResults = createSelector(
    getUISiteMaxCount,
    getNbPendingAlerts,
    countAssignationSites,
    (nbSites, nbAlerts, nbAssignationSites) => ({ nbSites, nbAlerts, nbAssignationSites }),
);
