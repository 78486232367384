import { createAction, props } from '@ngrx/store';

export class UserEnvActions {
    static readonly START_LOADING_ENV = 'START_LOADING_ENV';
    static readonly ENV_LOADED = 'ENV_LOADED';
    static readonly USER_LANGUAGE_CHANGED = 'USER_LANGUAGE_CHANGED';
}

export const startLoading = createAction(UserEnvActions.START_LOADING_ENV);

export const envLoaded = createAction(UserEnvActions.ENV_LOADED);

export const userLanguageChanged = createAction(UserEnvActions.USER_LANGUAGE_CHANGED, props<{ language: string }>());
