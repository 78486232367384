import { createReducer, on } from '@ngrx/store';
import { updateDeviceDefinitionUI } from '../actions/ui';

export type UIDeviceDefinitionState = Record<string, any>;
export const UI_DEVICE_DEFINITION_INITIAL_STATE: UIDeviceDefinitionState = {};

export default createReducer(
    UI_DEVICE_DEFINITION_INITIAL_STATE,

    on(
        updateDeviceDefinitionUI,
        (state, { deviceType, definitionUI }): UIDeviceDefinitionState => ({
            ...state,
            [deviceType]: definitionUI,
        }),
    ),
);
