<article>
  <a class="favorite-card" [routerLink]="['/site', favoriteSite.siteId]">
    <img class="close-button" src="assets/img/close.svg" (click)="onDeleteFavorite($event, favoriteSite.siteId)">
    <app-site-icon [sitePin]="favoriteSite.gatewayId" [nbBoxes]="favoriteSite.nbSetupGateways" />
    <div class="client-name">
      <span class="client-first-name">{{ favoriteSite.owner.firstName }}</span>
      <span class="client-last-name">{{ favoriteSite.owner.lastName }}</span>
    </div>
    @if (favoriteSite.alert) {
      <div class="alert">
        <app-alert-severity [severity]="favoriteSite.alert.severity" />
        <span class="alert-date">{{ favoriteSite.alert.date | format: { type: 'shortDate' } }}</span>
      </div>
    }
  </a>
</article>
