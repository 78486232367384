import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExternalLegalDocument, SubscriptionCreationRequest, UserSubscription } from '../../admin/models/subscription';
import { User } from '../../shared/models/User';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class UserLegalDocService {
    constructor(private httpService: HttpService) {}

    getSubscriptionFoCurrentUser(): Observable<UserSubscription[]> {
        return this.httpService.get('/subscription');
    }

    createSubscription(user: User): Observable<string> {
        const subCreateReq: SubscriptionCreationRequest = {
            company_id: user.crmName,
        };
        return this.httpService.post('/subscription', subCreateReq);
    }

    getAllSubscriptionLegalDocuments(subscriptionId: string): Observable<ExternalLegalDocument[]> {
        return this.httpService.get(`/subscription/${subscriptionId}/legaldocument`);
    }

    downloadTranslationFile(subscriptionId: string, translationFileId: string): Observable<any> {
        return this.httpService
            .get(`/subscription/${subscriptionId}/document/${translationFileId}/url`)
            .pipe(map((res: any) => res.documentUrl));
    }

    signDocument(subscription_id: string, translationId: string): Observable<string> {
        return this.httpService.patch(`/subscription/${subscription_id}/sign/${translationId}`, {});
    }
}
