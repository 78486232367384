import { combineReducers, createFeatureSelector } from '@ngrx/store';
import siteListReducer, {
    UI_SITE_LIST_INITIAL_STATE,
    UISiteListState,
} from '../../../site-list/store/reducers/ui.siteList';
import uiDeviceDefinitionsReducer, {
    UI_DEVICE_DEFINITION_INITIAL_STATE,
    UIDeviceDefinitionState,
} from './ui.devicedefinitions';
import siteDetailsReducers, { SITE_DETAILS_INITIAL_STATE, UISiteDetailState } from './ui.siteDetail';
import toasterReducer, { UI_TOASTER_INITIAL_STATE, UIToasterState } from './ui.toaster';

export const UI_INITIAL_STATE = {
    siteDetail: SITE_DETAILS_INITIAL_STATE,
    toaster: UI_TOASTER_INITIAL_STATE,
    sitesList: UI_SITE_LIST_INITIAL_STATE,
    uiDeviceDefinitions: UI_DEVICE_DEFINITION_INITIAL_STATE,
};

export default combineReducers({
    siteDetail: siteDetailsReducers,
    toaster: toasterReducer,
    sitesList: siteListReducer,
    uiDeviceDefinitions: uiDeviceDefinitionsReducer,
});

export interface UIState {
    siteDetail: UISiteDetailState;
    toaster: UIToasterState;
    sitesList: UISiteListState;
    uiDeviceDefinitions: UIDeviceDefinitionState;
}

export const getUIState = createFeatureSelector<UIState>('ui-state');
