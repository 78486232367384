import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';

@Injectable()
export class HttpService {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    public get(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .get<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
    }

    public post(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .post<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
    }

    public patch(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .patch<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
    }

    public delete(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .delete<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
    }

    private getHttpOptions(): { headers: HttpHeaders } {
        const token = localStorage.getItem('access_token');
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
            }),
        };
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            switch (error.status) {
                default:
                    // alert(error.message);
                    console.warn(error.message);
            }
            // Let the app keep running by returning an empty result.
            throw error;
        };
    }
}
