import { createAction, props } from '@ngrx/store';
import { MaintenancePeriod } from '../model/maintenance-period';

export class AdminMaintenancePeriodActions {
    static readonly LOAD_MAINTENANCE_PERIODS = 'LOAD_MAINTENANCE_PERIODS';
    static readonly ADD_CREATED_MAINTENANCE_PERIOD = 'ADD_CREATED_MAINTENANCE_PERIOD';
    static readonly REMOVE_DELETED_MAINTENANCE_PERIOD = 'REMOVE_DELETED_MAINTENANCE_PERIOD';
}

export const loadMaintenancePeriods = createAction(
    AdminMaintenancePeriodActions.LOAD_MAINTENANCE_PERIODS,
    props<{ maintenancePeriods: MaintenancePeriod[] }>(),
);

export const addCreatedMaintenancePeriod = createAction(
    AdminMaintenancePeriodActions.ADD_CREATED_MAINTENANCE_PERIOD,
    props<{ maintenancePeriod: MaintenancePeriod }>(),
);

export const removeDeletedMaintenancePeriod = createAction(
    AdminMaintenancePeriodActions.REMOVE_DELETED_MAINTENANCE_PERIOD,
    props<{ id: string }>(),
);
