import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterDeviceUpdateMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-device-update-item',
    templateUrl: './device-update-item.component.html',
    styleUrls: ['./device-update-item.component.scss'],
    standalone: true,
})
export class DeviceUpdateItemComponent implements OnInit {
    @Input()
    content: ToasterDeviceUpdateMessageContent;

    @Input()
    id: string;

    translatedMessage = '';

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        const stateLabelTranslation = this.translateService.instant(this.content.stateLabel);
        let statusKey = 'TOASTER_DEVICE_UPSATE_STATE_INVALID';
        if (this.content.isUpdateValid) {
            statusKey = this.content?.isQueued
                ? 'TOASTER_DEVICE_UPSATE_STATE_VALID_QUEUED'
                : 'TOASTER_DEVICE_UPSATE_STATE_VALID';
        }
        this.translatedMessage = this.translateService.instant(statusKey, {
            stateLabel: stateLabelTranslation,
        });

        setTimeout(() => {
            this.removeMessage();
        }, 4000);
    }

    removeMessage() {
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }

    onClick() {
        // Redirect to good page
        this.router.navigate([`/site/${this.content.siteId}/${this.content.deviceId}`]);
    }
}
