import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import type { SiteAssignation } from '../../models/SiteAssignation';
import type { SiteAssignationRequest } from '../../models/SiteAssignationRequest';
import { SiteAssignationService } from '../../services/site-assignation.service';
import {
    addPendingSiteAssignation,
    deletePendingSiteAssignation,
    loadPendingSiteAssignations,
} from '../actions/site-assignation';

@Injectable()
export class SiteAssignationApi {
    constructor(
        private store: Store<AppState>,
        private siteAssignationService: SiteAssignationService,
    ) {}

    public getAllSiteAssignationPendingRequest(): Observable<SiteAssignation[]> {
        return this.siteAssignationService.getAllSiteAssignationPendingRequest().pipe(
            tap((pendingRequests: SiteAssignation[]) => {
                this.store.dispatch(loadPendingSiteAssignations({ siteAssignations: pendingRequests }));
            }),
        );
    }

    public createSiteAssignationRequest(
        siteAssignReq: SiteAssignationRequest,
        remindingCustomer = false,
    ): Observable<any> {
        // so far we do nothing we the store, but it may change later
        return this.siteAssignationService.createSiteAssignationRequest(siteAssignReq).pipe(
            tap(() => {
                if (!remindingCustomer) {
                    const siteAssignation: SiteAssignation = {
                        endUserEmail: siteAssignReq.endUserEmail,
                        gatewayId: siteAssignReq.gatewayId,
                        requestTimestamp: Date.now(),
                    };
                    this.store.dispatch(
                        addPendingSiteAssignation({
                            siteAssignation: siteAssignation,
                        }),
                    );
                }
            }),
        );
    }

    public deleteSiteAssignationRequest(gatewayId: string): Observable<any> {
        // so far we do nothing we the store, but it may change later
        return this.siteAssignationService.deleteSiteAssignationRequest(gatewayId).pipe(
            tap(() => {
                this.store.dispatch(deletePendingSiteAssignation({ gatewayId }));
            }),
        );
    }
}
