import type { UserId } from '../../shared/models/User';
import type { SiteId } from '../../site-detail/models/Site';
import type { SessionId } from './Intervention';

export interface InterventionCreateRequest {
    action: InterventionAction;
    id_session: SessionId;
    id_site: SiteId;
    id_installer: UserId;
    device_url: string;
    data: DeviceAction;
}

export enum InterventionAction {
    OPEN_SESSION = 'OPEN_SESSION',
    UPDATE_DEVICE = 'UPDATE_DEVICE',
    CLOSE_SESSION = 'CLOSE_SESSION',
    REBOOT_GATEWAY = 'REBOOT_GATEWAY',
    ACCESS_TAHOMA = 'ACCESS_TAHOMA',
    PAIRING_REMOTE = 'PAIRING_REMOTE',
}

export interface DeviceAction {
    attribute: string;
    translateValue?: boolean;
    unit?: string;
    value?: string | number;
    queued?: boolean;
    oldValue?: string | number;
    npm?: string | number;
}
