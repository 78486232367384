import { getDateFromTimestamp } from '../../utils/date';
import { Alert, ALERT_STATUS } from '../models/Alert';

export function sortAlerts(alerts: Alert[]) {
    return alerts.sort((a, b) => {
        if (a.severity !== b.severity) {
            return b.severity - a.severity;
        }

        const aStatusAsNumber = a.status === ALERT_STATUS.NEW ? 0 : 1;
        const bStatusAsNumber = b.status === ALERT_STATUS.NEW ? 0 : 1;
        if (aStatusAsNumber !== bStatusAsNumber) {
            return bStatusAsNumber - aStatusAsNumber;
        }

        const dateA = getDateFromTimestamp(a.date);
        const dateB = getDateFromTimestamp(b.date);
        if (dateA !== dateB) {
            return dateA > dateB ? -1 : 1;
        }

        return a.device_name.localeCompare(b.device_name);
    });
}
