import { ExternalDocumentTranslation, ExternalLegalDocument } from '../admin/models/subscription';
import { mapLocale } from './language';
import { DEFAULT_LOCALE } from './url';

export const getDocumentTranslationAccordingToCurrentUserLang = (
    doc: ExternalLegalDocument,
    lang: string,
): ExternalDocumentTranslation | null => {
    const translations = doc.document_version.document_translation;
    const lg = mapLocale(lang);

    return (
        translations.find((t) => t.local === lg) ||
        translations.find((t) => t.local === DEFAULT_LOCALE) ||
        translations[0] ||
        null
    );
};
