import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { SiteAssignationService } from '../../services/site-assignation.service';
import { loadPendingSiteAssignations } from '../actions/site-assignation';

@Injectable()
export class SiteAssignationEffects {
    loadSiteAssignationsRequestsAfterInitialization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initialized),
            switchMap(() => this.siteAssignationService.getAllSiteAssignationPendingRequest()),
            map((siteAssignations) => loadPendingSiteAssignations({ siteAssignations })),
        ),
    );

    constructor(
        private actions$: Actions,
        private siteAssignationService: SiteAssignationService,
    ) {}
}
