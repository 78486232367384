import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertSeverityComponent } from '../../../alerts/components/alert-severity/alert-severity.component';
import { ALERT_SEVERITY } from '../../../alerts/models/Alert';
import { getNbNewAlertsBySeverity } from '../../../alerts/store/selectors/alerts';
import { AppState } from '../../../shared/store/app-state';

@Component({
    selector: 'app-alert-indicator',
    standalone: true,
    imports: [AlertSeverityComponent, TranslateModule],
    templateUrl: './alert-indicator.component.html',
    styleUrl: './alert-indicator.component.scss',
})
export class AlertIndicatorComponent implements OnInit {
    public ALERT_SEVERITY = ALERT_SEVERITY;

    private unsubscribe$: Subject<void> = new Subject();

    private readonly nbNewAlertsBySeverity$: Observable<{ high: number; normal: number }>;

    protected nbNewNormalAlerts = 0;
    protected nbNewHighAlerts = 0;

    constructor(
        private store: Store<AppState>,
        private ref: ChangeDetectorRef,
    ) {
        this.nbNewAlertsBySeverity$ = this.store.select(getNbNewAlertsBySeverity);
    }

    ngOnInit() {
        this.nbNewAlertsBySeverity$.subscribe();

        this.nbNewAlertsBySeverity$.pipe(takeUntil(this.unsubscribe$)).subscribe((nbAlerts) => {
            this.nbNewNormalAlerts = nbAlerts.normal;
            this.nbNewHighAlerts = nbAlerts.high;
            this.ref.detectChanges();
        });
    }
}
