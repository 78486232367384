import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { InterventionService } from '../../../interventions/services/intervention.service';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { BoxActions } from '../actions/box-detail';
import { DeviceActions } from '../actions/device-detail';
import { addPendingInterventionAction } from '../actions/ui';
import {
    getPendingBoxRebootActionCreationData,
    getPendingDeviceActionCreationData,
    getTahomaAccessActionCreationData,
} from '../selectors/intervention-actions';

@Injectable()
export class InterventionEffects {
    addInterventionEventWhenOpeningTahomaApp$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BoxActions.tahomaAppAccessAllowed),
                switchMap(({ deviceId }) => this.storeService.select(getTahomaAccessActionCreationData(deviceId))),
                tap((interventionAction) => {
                    this.interventionService.createInterventionEvent(interventionAction).subscribe();
                }),
            ),
        { dispatch: false },
    );

    addPendingActionsAfterDeviceUpdateStarted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateStarted),
            switchMap(({ command, pendingTask }) =>
                this.storeService.select(getPendingDeviceActionCreationData(command, pendingTask)),
            ),
            map((interventionAction) => addPendingInterventionAction({ interventionAction })),
        ),
    );

    addPendingInterventionWhenRebootBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootStarted),
            switchMap(({ command, pendingTask }) =>
                this.storeService.select(getPendingBoxRebootActionCreationData(command, pendingTask)),
            ),
            map((interventionAction) => addPendingInterventionAction({ interventionAction })),
        ),
    );

    constructor(
        private actions$: Actions,
        private interventionService: InterventionService,
        private storeService: StoreService<AppState>,
    ) {}
}
