import { createAction, props } from '@ngrx/store';
import { UpdateUserAccess, UserAccess } from '../../models/access';
import { BackOfficeUser } from '../../models/backOfficeUser';
import { CMSAssociationResult, CrmUser, InstallerSignatureInfo } from '../../models/installer';
import {
    ExternalDocumentTranslation,
    LegalDocument,
    LegalDocumentVersion,
    UserSignature,
    UserSubscription,
} from '../../models/subscription';

export class AdminActions {
    static readonly LOAD_INSTALLERS_CMS_ASSOCIATION = 'LOAD_INSTALLERS_CMS_ASSOCIATION';
    static readonly LOAD_USER_ACCESS = 'LOAD_USER_ACCESS';
    static readonly UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
    static readonly REMOVE_USER_ACCESS = 'REMOVE_USER_ACCESS';
    static readonly SET_SELECTED_INSTALLER = 'SET_SELECTED_INSTALLER';
    static readonly SET_SELECTED_INSTALLER_SIGNATURES = 'SET_SELECTED_INSTALLER_SIGNATURES';
    static readonly CLEAR_SELECTED_INSTALLER_SIGNATURES = 'CLEAR_SELECTED_INSTALLER_SIGNATURES';
    static readonly SET_LEGAL_DOCS_SERVICES = 'SET_LEGAL_DOCS_SERVICES';
    static readonly SET_LEGAL_DOCS_DOCUMENTS = 'SET_LEGAL_DOCS_DOCUMENTS';
    static readonly SET_LEGAL_DOCS_VERSIONS = 'SET_LEGAL_DOCS_VERSIONS';
    static readonly SET_LEGAL_DOCS_TRANSLATIONS = 'SET_LEGAL_DOCS_TRANSLATIONS';
    static readonly ADD_CREATED_LEGAL_DOC = 'ADD_CREATED_LEGAL_DOC';
    static readonly ADD_CREATED_LEGAL_DOC_VERSION = 'ADD_CREATED_LEGAL_DOC_VERSION';
    static readonly ADD_CREATED_LEGAL_DOC_TRANSLATION = 'ADD_CREATED_LEGAL_DOC_TRANSLATION';
    static readonly SET_LEGAL_DOCS_SUBSCRIPTIONS = 'SET_LEGAL_DOCS_SUBSCRIPTIONS';
    static readonly SET_LEGAL_DOCS_SIGNATURES = 'SET_LEGAL_DOCS_SIGNATURES';
    static readonly REMOVE_LEGAL_DOCUMENT = 'REMOVE_LEGAL_DOCUMENT';
    static readonly REMOVE_LEGAL_DOCUMENT_VERSION = 'REMOVE_LEGAL_DOCUMENT_VERSION';
    static readonly SET_CURRENT_BACKOFFICE_USER_INFO = 'SET_CURRENT_BACKOFFICE_USER_INFO';
}

export const loadInstallersCmsAssociation = createAction(
    AdminActions.LOAD_INSTALLERS_CMS_ASSOCIATION,
    props<{ cmsAssociationResults: CMSAssociationResult[] }>(),
);

export const loadUserAccess = createAction(AdminActions.LOAD_USER_ACCESS, props<{ users: UserAccess[] }>());

export const updateUserAccess = createAction(
    AdminActions.UPDATE_USER_ACCESS,
    props<{ userAccess: UpdateUserAccess }>(),
);

export const removeUserAccess = createAction(AdminActions.REMOVE_USER_ACCESS, props<{ userAccess: string }>());

export const setSelectedInstaller = createAction(AdminActions.SET_SELECTED_INSTALLER, props<{ installer: CrmUser }>());

export const setSelectedInstallerSignatures = createAction(
    AdminActions.SET_SELECTED_INSTALLER_SIGNATURES,
    props<{ signatureInfo: InstallerSignatureInfo[] }>(),
);

export const clearSelectedInstallerSignatures = createAction(AdminActions.CLEAR_SELECTED_INSTALLER_SIGNATURES);

export const setServices = createAction(AdminActions.SET_LEGAL_DOCS_SERVICES, props<{ serviceId: string }>());

export const setLegalDocuments = createAction(
    AdminActions.SET_LEGAL_DOCS_DOCUMENTS,
    props<{ documents: LegalDocument[] }>(),
);

export const setLegalDocsVersions = createAction(
    AdminActions.SET_LEGAL_DOCS_VERSIONS,
    props<{ versions: LegalDocumentVersion[] }>(),
);

export const setLegalDocsTranslations = createAction(
    AdminActions.SET_LEGAL_DOCS_TRANSLATIONS,
    props<{ versions: LegalDocumentVersion[] }>(),
);

export const addCreatedLegalDoc = createAction(AdminActions.ADD_CREATED_LEGAL_DOC, props<{ doc: LegalDocument }>());

export const addCreatedLegalDocVersion = createAction(
    AdminActions.ADD_CREATED_LEGAL_DOC_VERSION,
    props<{ version: LegalDocumentVersion }>(),
);

export const addCreatedLegalDocTranslation = createAction(
    AdminActions.ADD_CREATED_LEGAL_DOC_TRANSLATION,
    props<{ transl: ExternalDocumentTranslation }>(),
);

export const setUserSubscriptions = createAction(
    AdminActions.SET_LEGAL_DOCS_SUBSCRIPTIONS,
    props<{ subscriptions: UserSubscription[] }>(),
);

export const setUserSignatures = createAction(
    AdminActions.SET_LEGAL_DOCS_SIGNATURES,
    props<{ signatures: UserSignature[] }>(),
);

export const removeLegalDocument = createAction(AdminActions.REMOVE_LEGAL_DOCUMENT, props<{ deleteDocId: string }>());

export const removeLegalDocVersion = createAction(
    AdminActions.REMOVE_LEGAL_DOCUMENT_VERSION,
    props<{ deleteDocVersionId: string }>(),
);

export const setCurrentBackOfficeUserInfo = createAction(
    AdminActions.SET_CURRENT_BACKOFFICE_USER_INFO,
    props<{ user: BackOfficeUser }>(),
);
