import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ADMIN } from '../const';
import { BackOfficeUser } from '../models/backOfficeUser';
import { Login } from '../models/login';
import { AdminSecurityService } from './security.service';

@Injectable()
export class AdminAuthService {
    error: any;
    loggedInEvent = new EventEmitter<boolean>();
    errorEvent = new EventEmitter<any>();

    constructor(
        private adminSecurityService: AdminSecurityService,
        private router: Router,
    ) {}

    get authenticated(): boolean {
        return this._getAdminSession().accessToken !== null;
    }

    handleLogin(login: Login) {
        this.adminSecurityService.getOAuthToken(login).subscribe(
            (sessionToken) => {
                this._setAdminSession(sessionToken, login.username);
                this.loggedInEvent.emit(true);

                const queryString = location.hash.split('?').length === 1 ? '' : location.hash.split('?').pop();

                const [, redirectTo] = queryString
                    .split('&')
                    .map((param) => param.split('='))
                    .find(([key]) => key === 'redirectTo');

                const target = redirectTo ? decodeURIComponent(redirectTo) : ADMIN.URLS.HOME;
                this.router.navigate([target]);
            },
            (err) => {
                this.errorEvent.emit();
            },
        );
    }

    login(redirectTo: string) {
        // Redirect to login page
        this.router.navigate([ADMIN.URLS.LOGIN], {
            queryParams: { redirectTo: redirectTo },
        });
    }

    logout(redirectTo: string) {
        // Call backend's logout route for him to clean up his mess
        this.adminSecurityService.logout(this._getAdminSession().accessToken).subscribe({
            complete: () => {
                // Remove Admin tokens and profile and update login status subject
                localStorage.removeItem('admin_access_token');
                localStorage.removeItem('admin-username');
                if (this._getSession()) {
                    this.adminSecurityService.logout(this._getSession().accessToken).subscribe({
                        complete: () => {
                            // Remove Front tokens and profile
                            localStorage.removeItem('access_token');
                            // Redirect to logout
                            this.router.navigate([ADMIN.URLS.LOGIN], {
                                queryParams: {
                                    redirectTo: redirectTo,
                                },
                            });
                        },
                    });
                } else {
                    // Redirect to logout
                    this.router.navigate([ADMIN.URLS.LOGIN], {
                        queryParams: { redirectTo: redirectTo },
                    });
                }
            },
        });
    }

    private _setAdminSession(sessionToken: any, adminUsername?: string) {
        // Store oauthToken information in localStorage
        localStorage.setItem('admin_access_token', sessionToken);
        if (adminUsername) {
            localStorage.setItem('admin-username', adminUsername);
        }
    }

    public _getAdminSession() {
        return {
            accessToken: localStorage.getItem('admin_access_token'),
        };
    }

    public _getSession() {
        return {
            accessToken: localStorage.getItem('access_token'),
        };
    }

    getCurrentLoggedInAdminUser(): Observable<BackOfficeUser> {
        return this.adminSecurityService.getCurrentLoggedInUserInfo(this._getAdminSession().accessToken);
    }
}
