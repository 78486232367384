import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import type { GatewayActivationOption } from '../../../shared/components/modal/modal-attach-gateway/GatewayActivationOption';
import { ModalAttachGatewayComponent } from '../../../shared/components/modal/modal-attach-gateway/modal-attach-gateway.component';

@Component({
    selector: 'app-attach-gateway-button',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './attach-gateway-button.component.html',
    styleUrl: './attach-gateway-button.component.scss',
})
export class AttachGatewayButtonComponent {
    constructor(private dialog: MatDialog) {}

    onAttachGatewayClick(): void {
        const activationOptions: GatewayActivationOption = {
            enduserEmail: '',
            gatewayId: '',
            readonly: false,
        };

        this.dialog.open(ModalAttachGatewayComponent, {
            data: activationOptions,
        });
    }
}
