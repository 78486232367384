import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { MessageContent, MessageMS } from '../../models/Message';
import * as MessagesActions from '../actions/messages';

const enum ATTR_NAME {
    LIST = 'list',
    ADMIN_LIST = 'adminList',
}

const deleteMessage = (state, messageId, attrName: ATTR_NAME) => {
    const messageDelete = state[attrName].find((m) => {
        if (attrName === ATTR_NAME.LIST) {
            return m.id_message === messageId;
        } else if (attrName === ATTR_NAME.ADMIN_LIST) {
            return m.id === messageId;
        }
        throw new Error(`Messages list type not supported: "${attrName}"`);
    });
    if (!messageDelete) {
        return state;
    }

    const messageDeleteIndex = state[attrName].indexOf(messageDelete);
    return {
        ...state,
        [attrName]: [...state[attrName].slice(0, messageDeleteIndex), ...state[attrName].slice(messageDeleteIndex + 1)],
    };
};

export interface MessagesState {
    list: MessageMS[];
    adminList: MessageContent[];
}

export const MESSAGES_INITIAL_STATE: MessagesState = {
    list: [],
    adminList: [],
};

export default createReducer(
    MESSAGES_INITIAL_STATE,

    on(MessagesActions.loadMessages, (state, { messages }): MessagesState => {
        return { ...state, list: messages };
    }),

    on(MessagesActions.loadAdminMessages, (state, { messages }): MessagesState => {
        return { ...state, adminList: messages };
    }),

    on(MessagesActions.markMessageAsRead, (state, { messageId }): MessagesState => {
        const messageToMarkAsRead = state.list.find((m) => m.id_message === messageId);
        if (!messageToMarkAsRead) {
            return state;
        }

        const messageToMarkAsReadIndex = state.list.indexOf(messageToMarkAsRead);
        const updatedMessage = { ...messageToMarkAsRead, read: true };

        return {
            ...state,
            list: [
                ...state.list.slice(0, messageToMarkAsReadIndex),
                updatedMessage,
                ...state.list.slice(messageToMarkAsReadIndex + 1),
            ],
        };
    }),

    on(
        MessagesActions.deleteMessage,
        (state, { messageId }): MessagesState => deleteMessage(state, messageId, ATTR_NAME.LIST),
    ),

    on(
        MessagesActions.deleteAdminMessage,
        (state, { messageId }): MessagesState => deleteMessage(state, messageId, ATTR_NAME.ADMIN_LIST),
    ),
);

export const getMessagesState = createFeatureSelector<MessagesState>('messages');
