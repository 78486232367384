/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { getExpireTime } from '../../utils/date';
import { AuthenticationSession } from '../model/AuthenticationSession';

interface OAuth2Secrets {
    clientId: string;
    clientSecret: string;
}

interface RefreshTokenResponse {
    access_token: string;
    scope: string;
    expires_in: number;
    refresh_token: string;
}

@Injectable()
export class SecurityService {
    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    getOAuthToken(jwtToken: string): Observable<AuthenticationSession> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${jwtToken}`,
        });
        return this.http
            .get<AuthenticationSession>(this.appConfig.servegoApi.getOAuthTokenUrl, { headers })
            .pipe(catchError((err) => this.handleError(err)));
    }

    // Implement a method to handle errors if any
    private handleError(err: HttpErrorResponse | any) {
        console.error('An error occurred', err);
        switch (err.status) {
            case 403:
                this.router.navigate(['/unauthorized']).then(() => console.log('nav successfull'));
                break;
            default:
                // alert(error.message);
                console.warn(err.message);
        }
        return observableThrowError(err.message || err);
    }

    refreshOauthToken(refreshToken: string): Observable<AuthenticationSession> {
        const oAuth2Secrets: OAuth2Secrets = this.appConfig.oauth2;
        const formData = new FormData();
        formData.append('client_id', oAuth2Secrets.clientId);
        formData.append('client_secret', oAuth2Secrets.clientSecret);
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refreshToken);
        return this.http.post(this.appConfig.oauth2.refreshTokenUrl, formData).pipe(
            map((apiResponse: RefreshTokenResponse) => {
                return { ...apiResponse, expires_at: getExpireTime(apiResponse.expires_in) };
            }),
            catchError((err) => this.handleError(err)),
        );
    }
}
