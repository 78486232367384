import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterDeviceStateRefreshFailedMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-device-refresh-state-failed-item',
    templateUrl: './device-refresh-state-failed-item.component.html',
    styleUrls: ['./device-refresh-state-failed-item.component.scss'],
    standalone: true,
})
export class DeviceRefreshStateFailedItemComponent implements OnInit {
    @Input()
    content: ToasterDeviceStateRefreshFailedMessageContent;

    @Input()
    id: string;

    translatedMessage = '';

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        const stateLabelTranslation = this.translateService.instant(this.content.stateLabel);
        const statusKey = this.content?.isQueued
            ? 'TOASTER_DEVICE_REFRESH_STATE_VALID_QUEUED'
            : 'TOASTER_DEVICE_REFRESH_STATE_INVALID';
        this.translatedMessage = this.translateService.instant(statusKey, {
            stateLabel: stateLabelTranslation,
        });
    }

    onClick() {
        // Redirect to good page
        this.router.navigate([`/site/${this.content.siteId}?idDevice${this.content.deviceId}&tabIndex=1`]);
    }

    onClose() {
        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }
}
