import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { OauthService } from '../../core/services/oauth.service';
import { DEFAULT_LOCALE } from '../../utils/url';
import { APP_CONFIG } from '../injectionTokens';

@Injectable()
export class HttpService {
    constructor(
        private http: HttpClient,
        private authService: OauthService,
        private translateService: TranslateService,
        private router: Router,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    public get(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.get<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .get<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    public post(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.post<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .post<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    public put(url: string, putData: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.put<any>(this.appConfig.servegoApi.rootUrl + url, putData, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .put<any>(this.appConfig.servegoApi.rootUrl + url, putData, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    public patch(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.patch<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .patch<any>(this.appConfig.servegoApi.rootUrl + url, post, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    public delete(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.delete<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .delete<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    public getDownload(url: string, options: any = { prefix: true }): Observable<any> {
        const httpOptions: any = this.getHttpOptions();
        httpOptions.headers.set('Accept', 'application/pdf');
        httpOptions.responseType = 'blob';
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                flatMap(() => this.http.get<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)),
                catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)),
            );
        } else {
            return this.http
                .get<any>(this.appConfig.servegoApi.rootUrl + url, httpOptions)
                .pipe(catchError(this.handleError<any>(this.appConfig.servegoApi.rootUrl + url)));
        }
    }

    private getHttpOptions(): { headers: HttpHeaders } {
        const headers = {
            Authorization: `Bearer ${this.authService.getAccessToken()}`,
            'Accept-Language': this.translateService.currentLang || DEFAULT_LOCALE,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        return {
            headers: new HttpHeaders(headers),
        };
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            switch (error.status) {
                case 401:
                    // this.authService.logout(`${this.appConfig.sso.servegoServiceUrl}?goto=${encodeURIComponent(location.href)}`);
                    this.authService.logout();
                    break;
                case 403:
                    this.router.navigate(['/unauthorized']);
                    break;
                default:
                    // alert(error.message);
                    console.warn(error.message);
            }
            // Let the app keep running by returning an empty result.
            throw error;
        };
    }
}
