<div class="card">
  <div class="card-header">
    <h1 class="card-title">{{ 'DASHBOARD' | translate }}</h1>
    <app-alert-indicator />
  </div>
  <app-tab-navigation></app-tab-navigation>
  <div class="card-content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-attach-gateway-button />
<app-maintenance-period-card />
<div class="favorite-list">
  <app-favorite-list />
</div>
