import { createAction, props } from '@ngrx/store';
import { ExternalLegalDocument } from '../../../admin/models/subscription';

export class LegalDocumentActions {
    static readonly SET_UNSIGNED_DOCUMENT = 'SET_UNSIGNED_DOCUMENT';
    static readonly SET_SIGNED_DOCUMENT = 'SET_SIGNED_DOCUMENT';
}

export const setUnsignedDocument = createAction(
    LegalDocumentActions.SET_UNSIGNED_DOCUMENT,
    props<{ docs: ExternalLegalDocument[] }>(),
);

export const setSignedDocument = createAction(
    LegalDocumentActions.SET_SIGNED_DOCUMENT,
    props<{ docs: ExternalLegalDocument[] }>(),
);
