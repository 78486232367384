import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import Constants from '../const';
import { ValueStorage } from './ValueStorage';

@Injectable()
export class LocalStorageService implements ValueStorage {
    private storage: any;
    private subjects: Map<string, BehaviorSubject<any>>;

    constructor() {
        if (window.localStorage) {
            this.storage = window.localStorage;
            this.subjects = new Map<string, BehaviorSubject<any>>();
            window.onstorage = (e: StorageEvent) => {
                this.updateSubject(e.key, e.newValue);
            };
        } else {
            throw Error('No Local storage available on this browser');
        }
    }

    public getItem(key: string): any {
        try {
            let item = this.storage.getItem(`${Constants.LOCAL_STORAGE_APP_PREFIX}-${key}`);
            if (item === 'undefined') {
                item = undefined;
            } else {
                item = JSON.parse(item);
            }
            return item;
        } catch (error) {
            console.error(`Error at LocalstorageService.getItem() for key "${key}"`);
            console.error(error);
            throw new Error(error);
        }
    }

    private updateSubject(key: string, value: any) {
        if (!this.subjects.has(key)) {
            this.subjects.set(key, new BehaviorSubject<any>(value));
        }
        this.subjects.get(key).next(value);
    }

    public setItem(key: string, value: any): void {
        this.storage.setItem(`${Constants.LOCAL_STORAGE_APP_PREFIX}-${key}`, JSON.stringify(value));
        this.updateSubject(key, value);
    }

    public deleteItem(key: string): void {
        if (this.subjects.has(key)) {
            this.subjects.get(key).complete();
            this.subjects.delete(key);
        }
        this.storage.removeItem(`${Constants.LOCAL_STORAGE_APP_PREFIX}-${key}`);
    }

    /**
     * https://stackoverflow.com/questions/40393703/rxjs-observable-angular-2-on-localstorage-change#40394581
     * watch data of given key
     * @param key
     */
    // yet this not work: if we manually change value of the key from chrome dev-tool, this is never replayed...
    public watch(key: string): Observable<any> {
        if (!this.subjects.has(key)) {
            this.subjects.set(key, new BehaviorSubject<any>(null));
        }
        let item = this.getItem(key);
        if (item === 'undefined') {
            item = undefined;
        } else {
            item = JSON.parse(item);
        }
        this.subjects.get(key).next(item);
        return this.subjects.get(key).asObservable();
    }
}
