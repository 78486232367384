import { createSelector } from '@ngrx/store';
import { selectAllRouteParams } from '../../../shared/store/selectors/router';
import { DeviceId } from '../../models/Device';
import { SiteId } from '../../models/Site';

export interface DeviceRouteParams {
    siteId: SiteId;
    deviceId: DeviceId;
    tabIndex: number;
}

export const getSiteRouteParams = createSelector(
    selectAllRouteParams,
    ({ idSite, idDevice, tabIndex }): DeviceRouteParams => {
        if (!idSite) {
            return;
        }
        return { siteId: idSite, deviceId: idDevice, tabIndex: tabIndex ? Number(tabIndex) : 0 };
    },
);
