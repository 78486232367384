import ENV_NAMES from './environments/ENV_NAMES';

/**
 * Init Tag Commander
 * Call external script and add them to head / body
 */
export function initializeTagCommander(config) {
    const scriptTag = document.createElement('script');
    if (config.name === ENV_NAMES.PROD) {
        scriptTag.setAttribute('src', 'https://cdn.tagcommander.com/3887/tc_Somfy_20.js');
    } else {
        scriptTag.setAttribute('src', 'https://cdn.tagcommander.com/3887/uat/tc_Somfy_20.js');
    }
    document.head.appendChild(scriptTag);

    // tc_Somfy_21 and tc_Somfy_34 scripts added by Analytics service once lg detected
}
