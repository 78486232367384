import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { Site } from '../../site-detail/models/Site';
import { ApiSite, buildSiteFromApiResult } from '../../utils/buildSiteFromApiResult';
import { SitesList } from '../models/sites';
import { SiteFetchOptions, SitesGateway } from './SitesGateway';

export const NB_OF_SITES_PER_REQUEST = 10;

@Injectable()
export class HttpSitesGateway implements SitesGateway {
    constructor(private httpService: HttpService) {}

    getSites({
        offset = 1,
        limit = NB_OF_SITES_PER_REQUEST,
        keywords = '',
        orderBy = 'activation_date',
    }: SiteFetchOptions): Observable<SitesList> {
        const keywordsParam = keywords !== '' ? `&keywords=${keywords}` : '';
        const orderByParam = orderBy ? `&orderBy=${orderBy}` : '';

        return this.httpService.get(`/site?offset=${offset}&limit=${limit}${keywordsParam}${orderByParam}`).pipe(
            map((apiResults: { count: number; sites: ApiSite[] }) => ({
                count: apiResults.count,
                sites: apiResults.sites
                    .map(buildSiteFromApiResult)
                    // exclude BeeconBox
                    .filter((site: Site) => !site.gatewayId.startsWith('16')),
            })),
        );
    }
}
