import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

interface ModalErrorData {
    body: string;
    title: string;
}

@Component({
    selector: 'app-modal-error',
    templateUrl: './modal-error.component.html',
    styleUrls: ['./modal-error.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule],
})
export class ModalErrorComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalErrorData) {}
}
