import { AdviceNoticeRule } from '../../../models/UiProfile';

export default function resolveNotice(noticeRule: AdviceNoticeRule, value: number) {
    if (typeof noticeRule === 'string') {
        return noticeRule;
    }

    const matchedCondition = Object.keys(noticeRule).find((rule) => {
        const ruleCondition = rule.includes('-')
            ? rule.replace('-', `<=${value} && ${value}<=`)
            : rule.replace(/^([><])/, `${value}$1`);
        return eval(ruleCondition);
    });

    return matchedCondition ? noticeRule[matchedCondition] : null;
}
