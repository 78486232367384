import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Alert, AlertId } from '../../../alerts/models/Alert';
import { getAlertById } from '../../../alerts/store/selectors/alerts';
import { selectAllRouteParams } from '../../../shared/store/selectors/router';
import buildViewer from '../../../site-detail/components/device-detail/viewer-builder/buildViewer';
import { DeviceId } from '../../../site-detail/models/Device';
import { PropertyId } from '../../../site-detail/models/DeviceDetail';
import { DeviceInfo } from '../../../site-detail/store/reducers/device-detail';
import {
    getDeviceProperty,
    getDevices,
    getLastDeviceUpdate,
    getSectionItemEdition,
    getUiProperty,
} from '../../../site-detail/store/selectors/device-detail';
import type { ExpectedAlertResolutionStateValues } from '../../models/AlertResolutionSteps';
import { buildExpectedViewers } from '../../services/buildExpectedViewers';
import { isCurrentValueExpected } from '../../services/isCurrentValueExpected';
import { AlertResolutionState } from '../reducers/alert-resolution';
import { AlertResolutionStepProperty, AlertResolutionVm } from '../view-models/AlertResolutionVm';

export const getAlertResolutionState = createFeatureSelector<AlertResolutionState>('alert-resolution');

export const getAlertResolutionAlertId = createSelector(selectAllRouteParams, ({ alertId }): AlertId => {
    return alertId ?? null;
});

export const getAlertResolutionCurrentStepNumber = createSelector(selectAllRouteParams, ({ stepNumber }) =>
    stepNumber ? Number(stepNumber) : null,
);

export const getAlertDevice = (alertId: AlertId) =>
    createSelector(getAlertById(alertId), getDevices, (alert, devices) => devices[alert?.id_device]);

export const getAlertResolutionError = (alertId: AlertId) =>
    createSelector(getAlertResolutionState, (state) => state.errors[alertId]);

export const getAlertResolutionPath = (alertId: AlertId) =>
    createSelector(
        getAlertResolutionState,
        getAlertById(alertId),
        getAlertDevice(alertId),
        getAlertResolutionCurrentStepNumber,
        getAlertResolutionError(alertId),
        (
            state: AlertResolutionState,
            alert: Alert,
            device: DeviceInfo,
            stepNumber: number,
            errorMessage: string,
        ): AlertResolutionVm => {
            if (errorMessage) {
                return { status: 'error', errorMessage };
            }

            const steps = state?.resolutionPaths[alert?.id_trigger];
            if (!steps) {
                return { status: 'loading' };
            }

            const progressionAsPercentage = (stepNumber * 100) / steps.length;

            return {
                status: 'loaded',
                alertId: alert.id,
                triggerId: alert.id_trigger,
                siteId: alert.id_site,
                deviceId: alert.id_device,
                deviceName: device?.name,
                title: `ALERT_DIAG_${alert.id_trigger}`,
                steps,
                stepNumber,
                progressionAsPercentage,
            };
        },
    );

export const getAlertResolutionStepProperty = (
    deviceId: DeviceId,
    propertyId: PropertyId,
    expectedValues: ExpectedAlertResolutionStateValues | undefined,
) =>
    createSelector(
        getLastDeviceUpdate(deviceId),
        getDeviceProperty(deviceId, propertyId),
        getUiProperty(deviceId, propertyId),
        getSectionItemEdition(deviceId, propertyId),
        (timestamp, property, uiProperty, edition): AlertResolutionStepProperty => {
            if (uiProperty == null && property == null) {
                return null;
            }

            return {
                id: propertyId,
                label: uiProperty?.label,
                viewer: buildViewer(uiProperty, property, { timestamp }),
                expected: buildExpectedViewers(expectedValues, uiProperty, property, timestamp),
                isCurrentValueExpected: isCurrentValueExpected(expectedValues, property?.value),
                edition,
            };
        },
    );
