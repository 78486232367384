import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminHttpService } from '../../../services/http.service';
import { CreateMaintenancePeriodCommand, MaintenancePeriod, UUID } from '../model/maintenance-period';

@Injectable()
export class AdminMaintenancePeriodService {
    constructor(private adminHttpService: AdminHttpService) {}

    list(): Observable<MaintenancePeriod[]> {
        return this.adminHttpService.get('/maintenance-period');
    }

    create(command: CreateMaintenancePeriodCommand): Observable<MaintenancePeriod> {
        return this.adminHttpService.post('/maintenance-period', command);
    }

    delete(id: UUID): Observable<void> {
        return this.adminHttpService.delete(`/maintenance-period/${id}`);
    }
}
