import { GatewayId } from '../site-detail/models/Gateway';
import { Owner, SetupId, Site, SiteId } from '../site-detail/models/Site';
import { stringDateToUnix } from './date';

export interface ApiSite {
    id: SiteId;
    setup_id: SetupId;
    setup_gateways: GatewayId[];
    adressline1: string;
    adressline2: string;
    postal_code: string;
    city: string;
    country: string;
    servego_activated: number;
    servego_requested: number;
    activation_date: string;
    servego_activation_date: string;
    activation_status: string;
    favorite: number;
    external_gateway_id: GatewayId;
    isToolSite: boolean;
    toolsite_info: { label: string };
    owner: Owner;
}

export function buildSiteFromApiResult(apiResult: ApiSite): Site {
    const site: Site = {
        id: apiResult.id,
        setupId: apiResult.setup_id,
        activateDate: stringDateToUnix(apiResult.activation_date),
        gatewayId: apiResult.external_gateway_id,
        servegoActivateDate: apiResult.servego_activation_date
            ? stringDateToUnix(apiResult.servego_activation_date)
            : null,
        address: {
            addressLine1: apiResult.adressline1,
            addressLine2: apiResult.adressline2,
            city: apiResult.city,
            country: apiResult.country,
            postalCode: apiResult.postal_code,
        },
        favorite: apiResult.favorite === 1,
        isServego: apiResult.servego_activated === 1,
        isServegoAsking: apiResult.servego_requested === 1,
        activation_status: apiResult.activation_status,
        setupGateways: apiResult.setup_gateways,
        isToolSite: apiResult.isToolSite,
        owner: apiResult.owner,
    };

    if (apiResult.toolsite_info) {
        site.toolsite_info = {
            label: apiResult.toolsite_info.label ? apiResult.toolsite_info.label : '',
        };
    }

    return site;
}
