import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';
import { EmailFrequency } from '../models/AlertsNotificationConfig';
import { AlertMailingConfigGateway } from './AlertMailingConfigGateway';

@Injectable()
export class HttpAlertMailingConfigGateway implements AlertMailingConfigGateway {
    constructor(private httpService: HttpService) {}

    changeMailingFrequency(frequency: EmailFrequency): Observable<void> {
        return this.httpService.put(`/alert-mailing/frequency`, { value: frequency });
    }

    subscribeToAlertMailing(): Observable<void> {
        return this.httpService.post(`/alert-mailing/subscription`, {});
    }

    unsubscribeToAlertMailing(): Observable<void> {
        return this.httpService.delete(`/alert-mailing/subscription`);
    }
}
