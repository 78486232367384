import { LineChartViewerSpec } from '../../../../models/ViewerSpec';

export interface LineChartViewerVm {
    type: 'lineChart';
    value: {
        values: number[];
        lastUpdatedDate: number;
    };
    config: LineChartViewerConfig;
}

export interface LineChartViewerConfig {
    min: number;
    max: number;
    step: number;
    unit: string;
}

export default function buildLineChartViewer(
    viewer: LineChartViewerSpec,
    value: number[],
    lastUpdatedDate: number,
): LineChartViewerVm {
    return {
        type: 'lineChart',
        value: {
            values: value.map((item) => +item),
            lastUpdatedDate,
        },
        config: {
            min: viewer.min,
            max: viewer.max,
            step: viewer.step,
            unit: viewer.unit ?? '',
        },
    };
}
