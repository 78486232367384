import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';

import type { SiteAssignationRequest } from '../models/SiteAssignationRequest';

@Injectable()
export class SiteAssignationService {
    constructor(private httpService: HttpService) {}

    getAllSiteAssignationPendingRequest(): Observable<any> {
        return this.httpService.get('/site-assignation');
    }

    createSiteAssignationRequest(siteAssignReq: SiteAssignationRequest): Observable<any> {
        return this.httpService.post(`/site-assignation`, siteAssignReq);
    }

    deleteSiteAssignationRequest(gatewayId: string): Observable<any> {
        return this.httpService.delete(`/site-assignation/${gatewayId}`);
    }
}
