<div>
    <div class="duration-inputs">
        <div class="timer-unit">
            <label> {{ 'HOURS' | translate }}</label>
            <input
                    name="hoursValue"
                    [(ngModel)]="hoursValue"
                    matInput
                    type="number"
                    (input)="onUserInput()"
                    appPadZero
                    [padSize]="2"
            />
        </div>
        <div class="timer-separator">:</div>
        <div class="timer-unit">
            <label> {{ 'MINUTES' | translate }}</label>
            <input
                    name="minutesValue"
                    [(ngModel)]="minutesValue"
                    type="number"
                    (input)="onUserInput()"
                    appPadZero
                    [padSize]="2"
            />
        </div>
        <div class="timer-separator"
             [hidden]="config.precision === 'minutes'">
            :
        </div>
        <div class="timer-unit" [hidden]="config.precision === 'minutes'">
            <label> {{ 'SECONDES' | translate }}</label>
            <input
                    name="secondesValue"
                    [(ngModel)]="secondsValue"
                    matInput
                    type="number"
                    (input)="onUserInput()"
                    appPadZero
                    [padSize]="2"
            />
        </div>
    </div>
</div>
