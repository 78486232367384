import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { from, Observable, Subject } from 'rxjs';
import { flatMap, takeUntil } from 'rxjs/operators';
import { IAdvizeComponent } from '../../../admin/components/iAdvize/iAdvize.component';
import { ExternalLegalDocument } from '../../../admin/models/subscription';
import { AppConfig } from '../../../AppConfig';
import { MessageMS } from '../../../messages/models/Message';
import { getMessages } from '../../../messages/store/selectors/messages';
import { ModalSignDocumentComponent } from '../../../shared/components/modal/modal-sign-document/modal-sign-document.component';
import { ToasterComponent } from '../../../shared/components/toaster/root/toaster.component';
import { APP_CONFIG } from '../../../shared/injectionTokens';
import { LoaderComponent } from '../../../shared/loader/loader.component';
import { User } from '../../../shared/models/User';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { UISocketService } from '../../../shared/services/ui.socket.service';
import { AppState } from '../../../shared/store/app-state';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import { getUserEnvLoadingState } from '../../../shared/store/selectors/user-env';
import { SomfyproLogoComponent } from '../../../site-detail/components/dummies-components/logos/somfypro-logo/somfypro-logo.component';
import { getSomfyProUrl } from '../../../utils/url';
import { getUnsignedLegalDocuments } from '../../store/selectors/legalDocument';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoaderComponent,
        SomfyproLogoComponent,
        RouterLinkActive,
        RouterLink,
        MatBadge,
        SideNavComponent,
        NgClass,
        RouterOutlet,
        ToasterComponent,
        IAdvizeComponent,
        TranslateModule,
    ],
})
export class HeaderComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    readonly userEnvLoading$: Observable<boolean>;
    userEnvLoading: boolean;

    readonly userLogin$: Observable<User>;
    userLogin: User;

    readonly unsignedLegalDocuments$: Observable<ExternalLegalDocument[]>;

    readonly installerMessages$: Observable<MessageMS[]>;
    hasMessages = false;
    numberOfUnreadMessages = 0;

    public somfyProUrl: string;

    constructor(
        private dialog: MatDialog,
        private uiSocketService: UISocketService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private store: Store<AppState>,
        public analyticService: AnalyticsService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {
        this.userEnvLoading$ = this.store.select(getUserEnvLoadingState);
        this.userLogin$ = this.store.select(getUserLoginState);
        this.unsignedLegalDocuments$ = this.store.select(getUnsignedLegalDocuments);
        this.installerMessages$ = this.store.select(getMessages);

        this.uiSocketService.initSocket();
    }

    ngOnInit(): void {
        this.userLogin$.pipe(takeUntil(this.unsubscribe$)).subscribe((user: User) => {
            this.userLogin = user;
            this.somfyProUrl = getSomfyProUrl(this.userLogin.country, this.appConfig.name);
        });
        this.userEnvLoading$.pipe(takeUntil(this.unsubscribe$)).subscribe((userEnvLoading) => {
            this.userEnvLoading = userEnvLoading;

            this.ref.detectChanges();
        });
        this.installerMessages$.pipe(takeUntil(this.unsubscribe$)).subscribe((msgs: MessageMS[]) => {
            this.numberOfUnreadMessages = msgs.filter((m) => !m.read).length;
            this.hasMessages = this.numberOfUnreadMessages > 0;
            this.ref.detectChanges();
        });
        this.unsignedLegalDocuments$
            .pipe(
                flatMap((unsignedDocs: ExternalLegalDocument[]) => from(unsignedDocs)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((docToSign: ExternalLegalDocument) => {
                if (docToSign) {
                    this.dialog.open(ModalSignDocumentComponent, {
                        disableClose: true,
                        data: { docToSign },
                    });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onServegoHeaderClick() {
        this.router.navigate(['/']);
    }
}
