import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MAT_LUXON_DATE_FORMATS,
} from '@angular/material-luxon-adapter';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { EmptyObject } from '../../../../../../shared/common.types';
import { DateData } from '../../StateData';
import { StateEditorComponent } from '../StateEditorComponent';

export type DateEditorConfig = EmptyObject;

@Component({
    selector: 'app-date-state-editor',
    standalone: true,
    imports: [MatCardModule, MatDatepickerModule],
    templateUrl: './date-editor.component.html',
    styleUrls: ['./date-editor.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: DateTime.local().locale },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    ],
})
export class DateEditorComponent implements AfterViewInit, OnInit, StateEditorComponent<DateData, DateEditorConfig> {
    @ViewChild('calendar') calendar: MatCalendar<DateTime>;
    @Input({ required: true }) value: DateData;
    @Input() config: DateEditorConfig;
    @Output() valueChange = new EventEmitter<DateData>();
    editedValue: DateTime;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.editedValue = this.value ? DateTime.fromMillis(this.value) : DateTime.now();
    }

    ngAfterViewInit() {
        this.calendar.activeDate = this.editedValue;
        this.changeDetectorRef.detectChanges();
    }

    onUserChange() {
        this.valueChange.emit(this.editedValue.toMillis());
    }

    isValid() {
        return true;
    }
}
