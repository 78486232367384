import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { flatMap, map, toArray } from 'rxjs/operators';
import { buList } from '../../shared/i18n/i18n.config';
import { implementsCrmUser } from '../../utils';
import { CreateUserAccess, UpdateUserAccess, UserAccess } from '../models/access';
import { BackOfficeUser, SalesForceSearchParameters, SearchType } from '../models/backOfficeUser';
import { CMSAssociationResult } from '../models/installer';
import {
    CreateTranslationRequest,
    LegalDocumentCreationRequest,
    LegalDocumentVersionCreationRequest,
} from '../models/subscription';
import { AdminAuthService } from './auth.service';
import { AdminHttpService } from './http.service';

@Injectable()
export class BackOfficeService {
    constructor(
        private adminHttpService: AdminHttpService,
        private authService: AdminAuthService,
    ) {}

    private computeBUList(partnerBuList: string[]): string[] {
        let buIds: string[];
        if (partnerBuList.length === 1) {
            if (partnerBuList[0] === 'all') {
                buIds = [...buList.map((bu) => bu.value)];
            } else {
                buIds = [...partnerBuList];
            }
        } else {
            buIds = [...partnerBuList];
        }
        return buIds;
    }

    private mapFilterUserWithoutBusinessOrCRMId(v: CMSAssociationResult[]): CMSAssociationResult[] {
        return v.map((assocRes: CMSAssociationResult): CMSAssociationResult => {
            const filteredInstaller = assocRes.usersBu.filter((u) => implementsCrmUser(u));
            return {
                totalUsers: filteredInstaller.length,
                usersBu: filteredInstaller,
            };
        });
    }

    getInstallerLastSignatureInfo(installerId: string, docsIds: Array<string>) {
        return this.adminHttpService.post(`/admin/signature/${installerId}`, docsIds);
    }

    getAdminUsers(): Observable<UserAccess[]> {
        return this.adminHttpService.get(`/admin/users`);
    }

    updateUserAccess(userAccess: CreateUserAccess | UpdateUserAccess): Observable<UserAccess> {
        return this.adminHttpService.post(`/admin/access`, userAccess);
    }

    setUserPassword(password: string, verificationHash: string): Observable<void> {
        return this.adminHttpService.post(`/admin/access/password?token=${verificationHash}`, { password });
    }

    resetUserAccessPassword(userEmail: string): Observable<void> {
        return this.adminHttpService.post(`/admin/access/reset/`, {
            userEmail,
        });
    }

    removeUserAccess(userId: string): Observable<string> {
        return this.adminHttpService.delete(`/admin/access/delete/${userId}`);
    }

    getStats(): Observable<string> {
        return this.adminHttpService.get(`/stats`);
    }

    getServices() {
        return this.adminHttpService.get('/admin/service');
    }

    getLegalDocuments(serviceId: string) {
        return this.adminHttpService.get('/admin/legal-document?serviceId=' + serviceId);
    }

    getLegalDocsVersions(idDocument: string) {
        return this.adminHttpService.get(`/admin/document-version/${idDocument}`);
    }

    getLegalDocVersionTranslations(documentVersionId: string) {
        return this.adminHttpService.get(`/admin/document-translation/${documentVersionId}`);
    }

    createNewLegalDoc(req: LegalDocumentCreationRequest) {
        return this.adminHttpService.post('/admin/legal-document', req);
    }

    createNewLegalDocVersion(req: LegalDocumentVersionCreationRequest): Observable<string> {
        return this.adminHttpService.post('/admin/document-version', req);
    }

    createNewLegalDocTranslation(req: CreateTranslationRequest): Observable<string> {
        return this.adminHttpService.post('/admin/document-translation', req);
    }

    uploadLegalDocTranslation(
        req: CreateTranslationRequest,
        documentVersionTranslationId: string,
        rawFile: File,
    ): Observable<string> {
        const formData = new FormData();
        formData.append('file', rawFile);
        formData.append('legaldocumentversion_id', req.legaldocumentversion_id);
        formData.append('markdown', req.markdown);
        formData.append('locale', req.local);
        return this.adminHttpService.patchUploadFile(
            `/admin/document-translation/${documentVersionTranslationId}`,
            formData,
        );
    }

    downloadTranslationFile(documentVersionTranslationId: string) {
        return this.adminHttpService.getDownload(
            `/admin/document-translation/${documentVersionTranslationId}/download`,
        );
    }

    getUserSubscriptions(serviceId: string): Observable<any> {
        return this.adminHttpService.get('/admin/subscription?serviceId=' + serviceId);
    }

    deleteLegalDoc(id: string) {
        return this.adminHttpService.delete(`/admin/legal-document/${id}`);
    }

    deleteLegalDocVersion(id: string) {
        return this.adminHttpService.delete(`/admin/document-version/${id}`);
    }

    getCurrentBackOfficeUserInfo(): Observable<BackOfficeUser> {
        return this.authService.getCurrentLoggedInAdminUser();
    }

    advancedInstallerSearch(partnerBuList: string[], fieldValues: any, strictMode = false) {
        const offset = 0;
        const limit = 100;

        const operator = strictMode ? ' AND ' : ' OR ';
        const logic = Object.keys(fieldValues).join(operator);

        const searchParams: SalesForceSearchParameters = {
            mode: SearchType.QUERY,
            fieldsValue: {
                ...fieldValues,
                logic,
            },
        };

        const buIds: string[] = this.computeBUList(partnerBuList);

        return from(buIds).pipe(
            flatMap((buId: string) =>
                this.adminHttpService.post(`/users/${buId}/associations?offset=${offset}&limit=${limit}`, searchParams),
            ),
            toArray(),
            map(this.mapFilterUserWithoutBusinessOrCRMId),
        );
    }

    closeAllPendingInterventions(ssoId: string) {
        return this.adminHttpService.post(`/intervention/${ssoId}`, {});
    }
}
