import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RemainingTimeComponent } from '../../../../interventions/components/remaining-time/remaining-time.component';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterAccessGrantedMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-access-granted-item',
    templateUrl: './access-granted-item.component.html',
    styleUrls: ['./access-granted-item.component.scss'],
    standalone: true,
    imports: [TranslateModule, RemainingTimeComponent],
})
export class AccessGrantedItemComponent implements OnInit {
    @Input()
    content: ToasterAccessGrantedMessageContent;

    @Input()
    id: string;

    interventionEndTime = '';
    translatedHeader = '';

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        // Format header
        this.translatedHeader = this.translateService.instant('TOASTER_ACCESS_GRANTED_HEADER', {
            userName: this.content.userSiteName,
        });
    }

    onClose() {
        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }

    onRedirectToSite() {
        // Redirect to good page
        this.router.navigate([`/site/${this.content.siteId}`]);

        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }
}
