import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmDeleteComponent } from '../components/modal/modal-confirm-delete/modal-confirm-delete.component';

@Injectable()
export class ModalService {
    constructor(private dialog: MatDialog) {}

    public showConfirmDeleteModal(warningMessage: string): MatDialogRef<ModalConfirmDeleteComponent, boolean> {
        return this.open(ModalConfirmDeleteComponent, {
            data: { warningMessage },
        });
    }

    open<T, D = any, R = any>(modal: ComponentType<T>, config: MatDialogConfig<D> = {}): MatDialogRef<T, R> {
        return this.dialog.open(modal, { autoFocus: false, ...config });
    }
}
