import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Alert, ALERT_STATUS, type AlertId } from '../../models/Alert';
import { AlertsActions } from '../actions/alerts';

export interface AlertsState {
    alertsLoading: boolean;
    alerts: Record<AlertId, Alert>;
}

export const ALERTS_INITIAL_STATE: AlertsState = {
    alertsLoading: true,
    alerts: {},
};

export default createReducer(
    ALERTS_INITIAL_STATE,

    on(AlertsActions.alertsLoaded, (state, { alerts }): AlertsState => {
        const alertsCopy = { ...state.alerts };

        alerts.forEach((alert: Alert) => {
            if (alert.user_name === 'NULL') {
                alert.user_name = '';
            }
            alertsCopy[alert.id] = alert;
        });

        return { ...state, alerts: alertsCopy, alertsLoading: false };
    }),

    on(AlertsActions.alertResolutionStarted, (state, { alertId }): AlertsState => {
        if (!state.alerts[alertId]) {
            return state;
        }
        const updatedAlert = { ...state.alerts[alertId], status: ALERT_STATUS.IN_PROGRESS };
        return { ...state, alerts: { ...state.alerts, [alertId]: updatedAlert } };
    }),

    on(AlertsActions.alertResolved, (state, { alertId }): AlertsState => {
        if (!state.alerts[alertId]) {
            return state;
        }
        const updatedAlert = { ...state.alerts[alertId], status: ALERT_STATUS.RESOLVED };
        return { ...state, alerts: { ...state.alerts, [alertId]: updatedAlert } };
    }),
);

export const getAlertsState = createFeatureSelector<AlertsState>('alerts');
