<div if="id" class="toaster-success" id="popupMail">
  <header>
    <div class="icon">
      <img class="checked" src="assets/img/checked.svg"/>
    </div>
    <div class="content">
      <div class="title">{{ content.title | translate }}</div>
    </div>
    <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
  </header>
  <div class="content">
    <div>{{ content.successMessage | translate }}</div>
  </div>
</div>
