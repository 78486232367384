import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';
import { Alert, AlertId } from '../models/Alert';

@Injectable()
export class AlertService {
    constructor(private httpService: HttpService) {}

    getAlerts(): Observable<Alert[]> {
        return this.httpService.get('/alert');
    }

    startResolution(alertId: AlertId): Observable<void> {
        return this.httpService.put(`/alert/${alertId}/start-resolution`, {});
    }

    resolveAlert(alertId: AlertId): Observable<void> {
        return this.httpService.put(`/alert/${alertId}/resolve`, {});
    }
}
