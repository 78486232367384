import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { NumberFormatter } from '../../../../../../shared/models/Formatter';
import { SharedModule } from '../../../../../../shared/shared.module';
import { NumberData } from '../../StateData';
import { StateEditorComponent } from '../StateEditorComponent';

export interface SliderEditorConfig {
    min: number;
    max: number;
    step?: number;
    labelMin?: string;
    labelMax?: string;
    formatter?: NumberFormatter;
}

@Component({
    selector: 'app-slider-state-editor',
    standalone: true,
    imports: [MatSliderModule, TranslateModule, MatDialogModule, SharedModule],
    templateUrl: './slider-editor.component.html',
    styleUrls: ['./slider-editor.component.scss'],
})
export class SliderEditorComponent implements OnInit, StateEditorComponent<NumberData, SliderEditorConfig> {
    @Input({ required: true }) value: NumberData;
    @Input({ required: true }) config: SliderEditorConfig;
    @Output() valueChange = new EventEmitter<number>();

    editedValue: number;
    scaleFactor = 1;
    min: number;
    max: number;
    step = 1;

    ngOnInit() {
        this.editedValue = Math.round(this.value);
        this.min = Math.ceil(this.config.min);
        this.max = Math.floor(this.config.max);
        this.step = this.config.step ? Math.round(this.config.step) : 1;
    }

    onUserInput(eventValue: number) {
        this.editedValue = eventValue;
        // FIXME : réappliquer à l'inverse le scaleFactor et gérer côté back la conversion ? (cf cas de l'intellitag (dimensions))
        this.valueChange.emit(Math.round(eventValue));
    }

    isValid(): boolean {
        return true;
    }
}
