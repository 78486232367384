import { createSelector } from '@ngrx/store';
import { getSelectedSiteAlerts } from '../../../alerts/store/selectors/alerts';
import { getSites } from '../../../site-list/store/selectors/site-list';
import { Owner, Site, SiteId } from '../../models/Site';
import { getOwnerPhone } from '../../utils/owner.util';
import { getUISiteId } from './ui';

export const getSiteById = (siteId: SiteId) => createSelector(getSites, (sites: Record<SiteId, Site>) => sites[siteId]);

export const getSelectedSite = createSelector(
    getSites,
    getUISiteId,
    (sites: Record<SiteId, Site>, siteId: SiteId) => sites[siteId],
);

export const getSiteContact = (siteId: SiteId) =>
    createSelector(getSiteOwner(siteId), (owner: Owner) => {
        if (!owner) {
            return null;
        }
        return {
            firstName: owner.firstName,
            lastName: owner.lastName,
            phoneNumber: getOwnerPhone(owner),
            email: owner.email,
        };
    });

export const getSiteOwner = (siteId: SiteId) => createSelector(getSiteById(siteId), (site) => site?.owner ?? null);

export const getSelectedSiteOwner = createSelector(getSelectedSite, (site) => site?.owner);

export const getNumberSiteAlerts = createSelector(getSelectedSiteAlerts, (alerts) => alerts.length);
