import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { AdminAuthService } from './auth.service';

@Injectable()
export class AdminHttpService {
    constructor(
        private http: HttpClient,
        private authService: AdminAuthService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    private computeApiUrl(url, options): string {
        if (options.prefix) {
            return this.appConfig.servegoAdminApi.rootUrl + url;
        }
        return this.appConfig.servegoAdminApi.rootUrl.replace('/bo', '') + url;
    }

    public get(url: string, options: any = { prefix: true }): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .get<any>(this.computeApiUrl(url, options), httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }

    public post(url: string, post: any, options: any = { prefix: true }): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .post<any>(this.computeApiUrl(url, options), post, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }

    public patch(url: string, post: any, options: any = { prefix: true }): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .patch<any>(this.computeApiUrl(url, options), post, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }

    public patchUploadFile(url: string, post: any, options: any = { prefix: true }): Observable<any> {
        const httpOptions = this.getHttpOptions();
        httpOptions.headers.set('Content-Type', 'multipart/form-data');
        return this.http
            .patch<any>(this.computeApiUrl(url, options), post, httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }

    public delete(url: string, options: any = { prefix: true }): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http
            .delete<any>(this.computeApiUrl(url, options), httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }

    /* public request(): Observable<any> {
        return this.http.request();
    }
*/
    private getHttpOptions(): { headers: HttpHeaders } {
        const token = localStorage.getItem('admin_access_token');
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
            }),
        };
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            switch (error.status) {
                case 401:
                    const redirectTo = (location.hash || '').replace(new RegExp('^#+'), '');
                    this.authService.logout(redirectTo);
                    break;

                default:
                    if (error.error && error.error.message) {
                        console.warn(error.status, error.error.message);
                    } else {
                        console.warn(error.message);
                    }
            }
            // Let the app keep running by returning an empty result.
            throw error;
        };
    }

    public getDownload(url: string, options: any = { prefix: true }): Observable<any> {
        const httpOptions: any = this.getHttpOptions();
        httpOptions.headers.set('Accept', 'application/pdf');
        httpOptions.responseType = 'arraybuffer';
        return this.http
            .get<any>(this.computeApiUrl(url, options), httpOptions)
            .pipe(catchError(this.handleError<any>(this.appConfig.servegoAdminApi.rootUrl + url)));
    }
}
