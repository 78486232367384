import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, timeout } from 'rxjs/operators';
import { ExternalDocumentTranslation, ExternalLegalDocument } from '../../../../admin/models/subscription';
import { LegalDocsApi } from '../../../../core/store/api/legalDocs';
import { getDocumentTranslationAccordingToCurrentUserLang } from '../../../../utils/documents';
import { LoaderComponent } from '../../../loader/loader.component';
import { ToasterMessageType, ToasterSuccessMessage } from '../../../models/Toaster';
import { ErrorService } from '../../../services/error.service';
import { addToasterMessage } from '../../../store/actions/addToasterMessage';
import { AppState } from '../../../store/app-state';

interface ModalSignDocumentData {
    docToSign: ExternalLegalDocument;
}

@Component({
    selector: 'app-modal-sign-document',
    templateUrl: './modal-sign-document.component.html',
    styleUrls: ['./modal-sign-document.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        LoaderComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ],
})
export class ModalSignDocumentComponent implements OnDestroy {
    translationFile: ExternalDocumentTranslation;
    fileURL: any;

    private unsubscribe$: Subject<void> = new Subject();
    submitButtonDisabled = true;

    constructor(
        private legalDocApi: LegalDocsApi,
        private translationService: TranslateService,
        private sanitizer: DomSanitizer,
        private errorService: ErrorService,
        private changeRef: ChangeDetectorRef,
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: ModalSignDocumentData,
    ) {
        this.downloadAndDisplayLegalDoc();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private downloadAndDisplayLegalDoc(): void {
        this.translationFile = getDocumentTranslationAccordingToCurrentUserLang(
            this.data.docToSign,
            this.translationService.currentLang.toLowerCase(),
        );

        this.legalDocApi
            .getTranslationFileForSubscription(this.data.docToSign.subscription_id, this.translationFile.id)
            .pipe(
                timeout(15 * 1000), // how long before stop waiting
                catchError((err) => {
                    console.error(err);
                    this.errorService.showToasterError('ADMIN_LEGAL_DOCS_TRANSLATION_DOWNLOAD_ERROR_TEXT');
                    this.submitButtonDisabled = true;
                    return of();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: (documentURL: string) => {
                    if (documentURL) {
                        try {
                            this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(documentURL);
                            this.submitButtonDisabled = false;
                        } catch (e) {
                            console.warn(e);
                            this.submitButtonDisabled = true;
                        }
                    } else {
                        this.submitButtonDisabled = true;
                        this.errorService.showToasterError(
                            'ADMIN_LEGAL_DOCS_TRANSLATION_DOWNLOAD_ERROR_TEXT_NOT_A_PDF',
                        );
                    }
                    this.changeRef.markForCheck();
                },
                error: () => {
                    this.submitButtonDisabled = true;
                },
            });
    }

    signDocument(): void {
        this.legalDocApi.signDocument(this.data.docToSign).subscribe(() => {
            this.submitButtonDisabled = true;
            const successMessage: ToasterSuccessMessage = {
                id: '',
                content: {
                    successMessage: 'USER_SUBSCRIPTION_SIGNED_SUCCESSFULLY',
                    title: 'USER_SUBSCRIPTION_SIGNED_SUCCESSFULLY',
                },
                type: ToasterMessageType.CONFIRM_SUCCESS,
            };
            this.store.dispatch(addToasterMessage({ message: successMessage }));
            setTimeout(() => {
                location.reload();
            }, 2 * 1000);
        });
    }
}
