import { Formatter, NumberFormatter } from '../../../../shared/models/Formatter';
import { toFullDate, toFullDateTime, toShortDate, toTime } from '../../../../utils/date';
import { formatDuration } from '../../../../utils/duration';

function formatNumber(value: number, formatter: NumberFormatter): string {
    const { scaleFactor = '1', precision, unit = 0 } = formatter;
    const displayedValue = eval(`${value} * ${scaleFactor}`).toFixed(precision);
    const suffix = unit ? `\u00a0${unit}` : '';
    return displayedValue + suffix;
}

export default function formatValue(value: number | string | boolean | Date, formatter: Formatter | undefined): string {
    const type = formatter?.type;

    if (type === 'duration') {
        return formatDuration(Number(value), formatter.inputUnit ?? 'seconds');
    }

    if (type === 'shortDate') {
        return toShortDate(value);
    }

    if (type === 'date') {
        return toFullDate(value);
    }

    if (type === 'time') {
        return toTime(value);
    }

    if (type === 'datetime') {
        return toFullDateTime(value);
    }

    if (type === 'number') {
        return formatNumber(value as number, formatter);
    }

    if (type === 'named-value') {
        return `${formatter.translationKey}_${value}`.toUpperCase().replace(/ /g, '_');
    }

    return `${value}`;
}
