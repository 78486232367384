<div if="id" class="access-granted-item" >
    <div class="access-granted-item-header">
        <div class="header-message">{{ translatedHeader }}</div>
        <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
    </div>
    <div class="access-granted-item-content">
        <div class="access-granted-item-content-message">
            <img class="clock-icon" src="assets/img/message.svg">
            <app-remaining-time [date]="content.intervention.date.start"></app-remaining-time>
        </div>
    </div>
    <div class="access-granted-item-footer">
        <button
        class="button-blue"
        id="start-intervention"
        (click)="onRedirectToSite()">
            <p>{{ 'TOASTER_ACCESS_GRANTED_START_INTERVENTION' | translate }}</p>
        </button>
    </div>
</div>
