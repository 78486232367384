import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../../shared/services/error.service';
import { AppState } from '../../shared/store/app-state';
import { ADMIN_ROLES } from '../const';
import { BackOfficeUser } from '../models/backOfficeUser';
import { AdminApi } from '../store/api/admin';
import { getCurrentUserState } from '../store/selectors/admin';

// https://stackoverflow.com/questions/37948068/angular-2-routing-canactivate-work-with-observable
// https://stackoverflow.com/questions/38425461/angular2-canactivate-calling-async-function
// https://angular.io/api/router/CanActivate

@Injectable()
export class RoleGuardService implements CanActivate {
    readonly currentUser$: Observable<BackOfficeUser>;
    currentUser: BackOfficeUser;

    constructor(
        private adminAPI: AdminApi,
        private router: Router,
        private errorService: ErrorService,
        private store: Store<AppState>,
    ) {
        this.currentUser$ = this.store.select(getCurrentUserState);
        this.currentUser$.subscribe((user: BackOfficeUser) => (this.currentUser = user));
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        if (this.currentUser) {
            return this.checkRoleForUserAndRoute(this.currentUser, route);
        }
        return this.adminAPI.getCurrentBackOfficeUserInfo().pipe(
            map((user: BackOfficeUser) => this.checkRoleForUserAndRoute(user, route)),
            catchError(() => {
                this.router.navigate(['/admin']);
                return of(false);
            }),
        );
    }

    private checkRoleForUserAndRoute(user: BackOfficeUser, route: ActivatedRouteSnapshot): boolean {
        const expectedRoles = route.data.expectedRoles;
        const currentUserRole = user.role;

        if (currentUserRole === ADMIN_ROLES.super_admin || !route.data || !route.data.expectedRoles) {
            return true;
        }

        // if current user role match one of the expected roles
        if (expectedRoles.indexOf(currentUserRole) > -1) {
            return true;
        }
        console.warn('cannot activate this route %s for the current user role %s', route.toString(), currentUserRole);
        this.errorService.showToasterError('ADMIN_ERROR_RESTRICTED_ROUTE');
        return false;
    }
}
