import { createActionGroup, props } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';

export const FavoriteSitesActions = createActionGroup({
    source: 'Favorite Sites',
    events: {
        favoritesLoaded: props<{ sites: Site[] }>(),
        favoriteAdded: props<{ siteId: SiteId }>(),
        favoriteRemoved: props<{ siteId: SiteId }>(),
    },
});
