import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { AlertsMailingConfigActions } from '../actions/alerts-mailing-config';
import { AnalyticsService } from '../../../shared/services/analytics.service';

@Injectable()
export class AlertNotificationAnalyticsEffects {
    constructor(
        private actions$: Actions,
        private analyticsService: AnalyticsService,
    ) {}

    logAlertNotificationStatusChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsMailingConfigActions.subscriptionEnabled, AlertsMailingConfigActions.subscriptionDisabled),
                map(({ type: eventType }) => eventType === AlertsMailingConfigActions.subscriptionEnabled.type),
                tap((enabled) => this.analyticsService.logAlertSubscriptionStatusChange(enabled)),
            ),
        { dispatch: false },
    );

    logAlertNotificationFrequencyChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsMailingConfigActions.frequencySelected),
                tap(({ frequency }) => this.analyticsService.logFrequencyChange(frequency)),
            ),
        { dispatch: false },
    );
}
