import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, first, of, startWith } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AlertId } from '../../../alerts/models/Alert';
import { getPendingAlerts } from '../../../alerts/store/selectors/alerts';
import { InterventionsApi } from '../../../interventions/store/api/interventions';
import { userLanguageChanged } from '../../../shared/store/actions/user-env';
import { AppState } from '../../../shared/store/app-state';
import { DeviceService } from '../../../site-detail/services/device.service';
import { SiteGateway } from '../../../site-detail/services/SiteGateway';
import { DeviceActions } from '../../../site-detail/store/actions/device-detail';
import { SiteDevicesActions } from '../../../site-detail/store/actions/devices';
import { loadSiteInfo } from '../../../site-detail/store/actions/site';
import { setSelectedSite } from '../../../site-detail/store/actions/ui';
import { AlertResolutionGateway } from '../../gateways/AlertResolutionGateway';
import { AlertResolutionActions } from '../actions/alert-resolution';

@Injectable()
export class AlertResolutionEffects {
    alertResolutionPageClosed$ = this.actions$.pipe(ofType(AlertResolutionActions.alertResolutionClosed));

    loadAlert$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertResolutionPageOpened),
            switchMap(({ alertId }) =>
                combineLatest([
                    of(alertId),
                    this.store.select(getPendingAlerts).pipe(first((alerts) => alerts?.length > 0)),
                ]),
            ),
            map(([alertId, alerts]) => ({ alertId, alert: alerts.find((alert) => alert.id === alertId) })),
            map(({ alertId, alert }) =>
                alert
                    ? AlertResolutionActions.alertLoaded(alert)
                    : AlertResolutionActions.alertLoadingFailed({
                          alertId,
                          errorMessage: 'ALERT_RESOLUTION_ALERT_NOT_FOUND',
                      }),
            ),
        ),
    );

    loadTranslations$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertResolutionActions.alertResolutionPageOpened),
                switchMap(() =>
                    this.actions$.pipe(
                        ofType(userLanguageChanged),
                        startWith({ language: this.translateService.currentLang }),
                        takeUntil(this.alertResolutionPageClosed$),
                    ),
                ),
                switchMap(({ language }) =>
                    combineLatest([of(language), this.alertResolutionGateway.getTranslations(language)]),
                ),
                tap(([language, translations]) => this.translateService.setTranslation(language, translations, true)),
            ),
        { dispatch: false },
    );

    loadAlertSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertLoaded),
            switchMap((alert) => combineLatest([of(alert.id), this.siteGateway.getSite(alert.id_site)])),
            switchMap(([alertId, site]) =>
                of(
                    loadSiteInfo({ site }),
                    setSelectedSite({ siteID: site.id }),
                    AlertResolutionActions.siteInfoLoaded({ siteId: site.id, alertId }),
                ),
            ),
        ),
    );

    loadAlertSiteInterventions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.siteInfoLoaded),
            switchMap(({ siteId, alertId }) =>
                this.interventionsApi.loadSiteInterventions(siteId).pipe(map(() => alertId)),
            ),
            map((alertId: AlertId) => AlertResolutionActions.interventionsLoaded({ alertId })),
        ),
    );

    loadAlertDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertLoaded),
            switchMap((alert) =>
                this.actions$.pipe(
                    ofType(AlertResolutionActions.siteInfoLoaded),
                    first(),
                    map(() => alert),
                ),
            ),
            switchMap((alert) =>
                this.deviceService.getDeviceById(alert.id_device).pipe(
                    switchMap((device) =>
                        of(
                            AlertResolutionActions.deviceSelected({ deviceId: device.id }),
                            SiteDevicesActions.deviceLoaded({ siteId: device.site_id, device }),
                        ),
                    ),
                    catchError(() =>
                        of(
                            AlertResolutionActions.deviceNotFound({ alertId: alert.id, deviceId: alert.id_device }),
                            AlertResolutionActions.alertLoadingFailed({
                                alertId: alert.id,
                                errorMessage: 'ALERT_RESOLUTION_DEVICE_NOT_FOUND',
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    selectDeviceGateway$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertResolutionPageOpened),
            switchMap(() =>
                this.actions$.pipe(
                    ofType(DeviceActions.deviceLoaded),
                    take(1),
                    takeUntil(this.alertResolutionPageClosed$),
                ),
            ),
            map(({ device }) => device?.deviceUrl.split('/')[2]),
            map((gatewayId) => AlertResolutionActions.deviceGatewaySelected({ gatewayId })),
        ),
    );

    loadResolutionPath$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertLoaded),
            switchMap(({ id, id_trigger }) =>
                combineLatest([of(id), of(id_trigger), this.alertResolutionGateway.getResolutionPath(id_trigger)]),
            ),
            map(([alertId, triggerId, resolutionPath]) =>
                AlertResolutionActions.alertResolutionPathApiSucceeded({
                    alertId,
                    triggerId,
                    resolutionPath,
                }),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private translateService: TranslateService,
        private interventionsApi: InterventionsApi,
        private deviceService: DeviceService,
        @Inject('AlertResolutionGateway') private alertResolutionGateway: AlertResolutionGateway,
        @Inject('SiteGateway') private siteGateway: SiteGateway,
    ) {}
}
