import { ListViewerSpec } from '../../../../models/ViewerSpec';

export interface ListViewerVm {
    type: 'list';
    value: string[];
}

export default function buildListViewer(viewer: ListViewerSpec, value: number[]): ListViewerVm {
    const translationKey = viewer.translationKey;
    const translationKeys = value.map((value) => `${translationKey}_${value}`);
    return {
        type: 'list',
        value: translationKeys,
    };
}
