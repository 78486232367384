import { DevicePropertyValue } from '../../../models/Device';
import { DeviceProperty } from '../../../models/DeviceDetail';
import { UiProfileProperty } from '../../../models/UiProfile';
import buildBoxStatusViewer, { BoxStatusViewerVm } from './builders/buildBoxStatusViewer';
import buildEmptyViewer, { isEmpty } from './builders/buildEmptyViewer';
import buildLevelsViewer, { LevelsViewerConfig, LevelsViewerVm } from './builders/buildLevelsViewer';
import buildLineChartViewer, { LineChartViewerConfig, LineChartViewerVm } from './builders/buildLineChartViewer';
import buildListViewer, { ListViewerVm } from './builders/buildListViewer';
import buildProtocolsViewer, { ProtocolsViewerVm } from './builders/buildProtocolsViewer';
import buildStaticViewer, { StaticViewerVm } from './builders/buildStaticViewer';
import buildTextViewer, { TextViewerVm } from './builders/buildTextViewer';
import formatValue from './formatValue';
import { valueConverters } from './valueConverters';

export type SectionItemViewer = { config?: LineChartViewerConfig | LevelsViewerConfig } & (
    | TextViewerVm
    | LevelsViewerVm
    | ListViewerVm
    | LineChartViewerVm
    | StaticViewerVm
    | ProtocolsViewerVm
    | BoxStatusViewerVm
);

function getValue(deviceProperty: DeviceProperty): DevicePropertyValue {
    if (deviceProperty == null) {
        return;
    }
    const convert = valueConverters[deviceProperty.id] || ((val) => val);

    return deviceProperty.value != null ? convert(deviceProperty.value) : deviceProperty.value;
}

export default function buildViewer(
    uiProperty: UiProfileProperty,
    deviceProperty: DeviceProperty,
    metadata?: { timestamp?: number },
): SectionItemViewer {
    const viewer = uiProperty.viewer;
    if (viewer?.type === 'static') {
        return buildStaticViewer(viewer);
    }

    const value = getValue(deviceProperty);
    if (isEmpty(value)) {
        return buildEmptyViewer(uiProperty.id, value);
    }

    if (!Array.isArray(value)) {
        try {
            // FIXME : ici on utilise pas la valeur transformée car box status travaille avec un booléen
            if (viewer?.type === 'boxStatus') {
                return buildBoxStatusViewer(deviceProperty.value as boolean);
            }
            return buildTextViewer(formatValue(value, uiProperty.formatter));
        } catch {
            return buildTextViewer(`${value}`);
        }
    }

    if (viewer?.type === 'list') {
        return buildListViewer(viewer, value as number[]);
    }

    if (viewer?.type === 'levels') {
        return buildLevelsViewer(viewer, value as number[]);
    }

    if (viewer?.type === 'lineChart') {
        return buildLineChartViewer(viewer, value as number[], metadata?.timestamp);
    }

    if (viewer?.type === 'protocols') {
        return buildProtocolsViewer(value as string[]);
    }

    console.error('unexpected viewer builder use case', {
        uiProperty,
        deviceProperty,
    });
    return buildEmptyViewer(uiProperty.id, undefined);
}
