<div class="seg-modal">
  <div class="seg-modal-header">
    <h4 mat-dialog-title class="seg-modal-title">
      {{ 'SIGNED_LEGAL_DOCUMENTS_MODAL_TITLE' | translate }}
    </h4>
  </div>
  <mat-dialog-content class="seg-modal-body">
    <ul>
      @for (doc of signedDocuments; track doc.id) {
        <li class="doc-item">
          <h3>{{ doc.display_name | translate }}</h3>
          <span>{{ 'ADMIN_LEGAL_DOCS_TRANSLATION_SIGNED_ON' | translate: { signedOn: computeDate(doc.signature.signature_date) } }}</span>
          @if (getDocument(doc); as transl) {
            <div>
              @if (!isDownloadInProgressForTranslId(transl.id)) {
                <button id="btn-validate"
                        class="seg-btn seg-bg-color-primary"
                        mat-button
                        (click)="downloadSignedFile(doc, transl.id)"
                >{{ 'ADMIN_LEGAL_DOCS_HEADER_TRANSLATION_DOWNLOAD' | translate }}
                </button>
              } @else {
                <span>{{ 'ADMIN_LEGAL_DOCS_TRANSLATION_DOWNLOAD_IN_PROGRESS_TEXT' | translate }}</span>
              }
            </div>
          }
        </li>
      }
    </ul>
  </mat-dialog-content>
</div>
