const deviceTypeToIconPathMapping = {
    AtlanticElectricalHeater: '04_Thermostat/ic_generic_heating_xlarge',
    AtlanticElectricalHeaterWithAdjustableTemperatureSetpoint: '04_Thermostat/ic_generic_heating_xlarge',
    AtlanticMultiMeterElectricSensor: '14_Sensors/ic_electric_cons_xlarge',
    AtlanticPassAPCDHW: '04_Thermostat/ic_water_heating_xlarge',
    AtlanticPassAPCHeatingAndCoolingZone: '04_Thermostat/ic_generic_heating_xlarge',
    AtlanticPassAPCHeatingZone: '04_Thermostat/ic_generic_heating_xlarge',
    AtlanticPassAPCHeatPump: '04_Thermostat/ic_generic_heating_xlarge',
    AwningValance: '08_Awning/ic_awning_mid_xlarge',
    BioclimaticPergola: '11_Pergola/ic_pergola_no_side_screen_xlarge',
    ContactSensor: '14_Sensors/Opening/ic_opening_sensor_open_xlarge',
    CumulativeElectricPowerConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    'CumulativeElectricPowerConsumptionSensor+I58+': '14_Sensors/ic_electric_cons_xlarge',
    CumulativeElectricPowerProductionSensor: '14_Sensors/ic_electric_cons_xlarge',
    CumulativeFossilEnergyConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    CumulativeGasConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    CumulativeThermalEnergyConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    CumulativeWaterConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    CurtainTrackUno: '13_Curtains/ic_curtains_2pans_mid_xlarge',
    CyclicGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_close_xlarge',
    CyclicSlidingGarageOpener: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    CyclicSlidingGateOpener: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    CyclicSwingingGateOpener: '05_Gate/Gate_opener/ic_go_close_xlarge',
    DimmerColorTemperatureLight: '02_Light/ic_light_off_xlarge',
    DimmerExteriorHeating: '12_Terrace_Heating/ic_terrace_heating_xlarge',
    DimmerHueSatOrCTLight: '02_Light/ic_light_off_xlarge',
    DimmerHueSaturationLight: '02_Light/ic_light_off_xlarge',
    DimmerLight: '02_Light/ic_light_off_xlarge',
    DimmerOnOffLight: '02_Light/ic_light_off_xlarge',
    DimmerRGBColouredLight: '02_Light/ic_light_off_xlarge',
    DiscreteExteriorHeating: '12_Terrace_Heating/ic_terrace_heating_xlarge',
    DiscreteGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_go_close_xlarge',
    DiscretePositionableGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    DomesticHotWaterTank: '04_Thermostat/ic_water_heating_xlarge',
    DoorLock: '15_Doorlock/ic_doorlock_close_xlarge',
    DropArmAwning: '08_Awning/ic_awning_mid_xlarge',
    DynamicDiscreteGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    DynamicExteriorVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    DynamicGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    DynamicHvacCentralUnit: '04_Thermostat/03_AC/01_Yellow',
    DynamicLight: '02_Light/ic_light_off_xlarge',
    DynamicPergola: '08_Awning/ic_awning_mid_xlarge',
    DynamicRollerShutter: '01_RS/ic_rs_mid_xlarge',
    EmptyRemoteController: '23_Remote/ic_mono_remote_xlarge',
    ExteriorVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    GasDHWConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    GasHeaterConsumptionSensor: '14_Sensors/ic_electric_cons_xlarge',
    IntrusionEventSensor: '14_Sensors/Opening/ic_opening_sensor_open_xlarge',
    IOSiren: '22_Siren/ic_siren_xlarge',
    HeatingTemperatureInterface: '04_Thermostat/ic_generic_heating_xlarge',
    LockUnlockDoorLockWithUnknownPosition: '15_Doorlock/ic_doorlock_close_xlarge',
    LuminanceSensor: '14_Sensors/ic_light_xlarge',
    MediaRenderer: '19_Sonos/ic_sonos_xlarge',
    MotionSensor: '14_Sensors/Movement/ic_movement_xlarge',
    MyFoxAlarmController: '03_Camera/ic_protect_xlarge',
    MyFoxSecurityCamera: '03_Camera/ic_somfyone_xlarge',
    OccupancySensor: '14_Sensors/Movement/ic_movement_xlarge',
    OnOffLight: '02_Light/ic_light_off_xlarge',
    OpenCloseGate: '05_Gate/Gate_opener/ic_go_close_xlarge',
    OpenCloseGate4T: '05_Gate/Gate_opener/ic_go_close_xlarge',
    OpenCloseGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_go_close_xlarge',
    OpenCloseSlidingGarageDoor: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    OpenCloseSlidingGarageDoor4T: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    OpenCloseSlidingGarageDoorWithPedestrianPosition: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    OpenCloseSlidingGate: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    OpenCloseSlidingGate4T: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    OpenCloseSlidingGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    OpeningSensor: '14_Sensors/Opening/ic_opening_sensor_open_xlarge',
    PergolaHorizontalAwning: '08_Awning/ic_awning_mid_xlarge',
    PergolaHorizontalAwningUno: '08_Awning/ic_awning_mid_xlarge',
    PergolaSideScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    PositionableAndLockableSlidingWindow: '07_Smart_Window/ic_smart_window_50%25_xlarge',
    PositionableAndStretchablePergolaScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    PositionableCurtain: '13_Curtains/ic_curtains_2pans_mid_xlarge',
    PositionableDualRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableExteriorVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    PositionableExteriorVenetianBlindUno: '01_RS/ic_rs_mid_xlarge',
    PositionableExteriorVenetianBlindWithWP: '01_RS/ic_rs_mid_xlarge',
    PositionableExteriorVenetianBlindWithWP2: '01_RS/ic_rs_mid_xlarge',
    PositionableGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    PositionableGarageDoorWithPartialPosition: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    PositionableGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_go_close_xlarge',
    PositionableHorizontalAwning: '08_Awning/ic_awning_mid_xlarge',
    PositionableHorizontalAwningUno: '08_Awning/ic_awning_mid_xlarge',
    PositionableOrOrientableRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableOrProgressiveOrientableRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableProjectionRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableRollerShutterUno: '01_RS/ic_rs_mid_xlarge',
    PositionableRollerShutterWithLowSpeedManagement: '01_RS/ic_rs_mid_xlarge',
    PositionableScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    PositionableScreenUno: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    PositionableSlidingGarageDoor: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    PositionableSlidingGarageDoorWithPartialPosition: '05_Gate/Garage_door_opener/ic_sliding_gdo_open_xlarge',
    PositionableSlidingGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    PositionableSlidingWindow: '07_Smart_Window/ic_smart_window_50%25_xlarge',
    PositionableTiltedRollerShutter: '01_RS/ic_rs_mid_xlarge',
    PositionableTiltedScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    PositionableTiltedWindow: '18_Velux/ic_velux_open_xlarge',
    PositionableVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    PositionableWindow: '27_tilting_window/01_Yellow_50%25',
    PositionableWindowUno: '27_tilting_window/01_Yellow_50%25',
    RainSensor: '14_Sensors/Rain/ic_rain_xlarge',
    RelativeHumiditySensor: '14_Sensors/ic_humidity_xlarge',
    RemoteControllerOneWay: '23_Remote/ic_mono_remote_xlarge',
    SlidingDiscreteGateWithPedestrianPosition: '05_Gate/Gate_opener/ic_sliding_go_close_xlarge',
    SmokeSensor: '14_Sensors/ic_no_smoke_xlarge',
    SomfyHeatingTemperatureInterface: '04_Thermostat/ic_generic_heating_xlarge',
    SomfyPilotWireHeatingInterface: '04_Thermostat/ic_generic_heating_xlarge',
    SomfyThermostat: '04_Thermostat/ic_connected_thermostat_xlarge',
    StatefulAlarmController: '03_Camera/ic_protect_xlarge',
    StatefulOnOff: '20_Plugs/ic_off_xlarge',
    StatefulOnOffLight: '02_Light/ic_light_off_xlarge',
    StatefulVenetianSlats: '06_EVB/ic_evb_orientation_45_xlarge',
    StatelessExteriorHeating: '12_Terrace_Heating/ic_terrace_heating_xlarge',
    StatelessOnOff: '20_Plugs/ic_off_xlarge',
    TemperatureSensor: '14_Sensors/ic_temp_xlarge',
    ThermostatDualModeAndFan: '04_Thermostat/ic_connected_thermostat_xlarge',
    ThermostatHeatingTemperatureInterface: '04_Thermostat/ic_connected_thermostat_xlarge',
    ThermostatSetPoint: '04_Thermostat/ic_heater_xlarge',
    TimedOnOff: '20_Plugs/ic_off_xlarge',
    TimedOnOffLight: '02_Light/ic_light_off_xlarge',
    TopDownBottomUpScreen: '28_Interior_Roller_Blinds/interior_roller_blind_tdbu_mid',
    UnlockDoorLockWithUnknownPosition: '15_Doorlock/ic_doorlock_close_xlarge',
    UpDownBioclimaticPergola: '11_Pergola/ic_pergola_no_side_screen_xlarge',
    UpDownCurtain: '13_Curtains/ic_curtains_2pans_mid_xlarge',
    UpDownDualCurtain: '13_Curtains/ic_curtains_2pans_mid_xlarge',
    UpDownExteriorScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    UpDownExteriorVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    UpDownGarageDoor: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    UpDownGarageDoor4T: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    UpDownGarageDoorWithVentilationPosition: '05_Gate/Garage_door_opener/ic_sectionnal_gdo_open_xlarge',
    UpDownHorizontalAwning: '08_Awning/ic_awning_mid_xlarge',
    UpDownRollerShutter: '01_RS/ic_rs_mid_xlarge',
    UpDownScreen: '09_Exterior_Blinds/ic_ext_blind_open_xlarge',
    UpDownSwingingShutter: '26_Swinging/ic_swinging_50%25_xlarge',
    UpDownVenetianBlind: '01_RS/ic_rs_mid_xlarge',
    ValveHeatingTemperatureInterface: '04_Thermostat/ic_heater_xlarge',
    WaterDetectionSensor: '14_Sensors/ic_no_water_leakage_xlarge',
    WindowLock: '15_Doorlock/ic_doorlock_close_xlarge',
};

export default deviceTypeToIconPathMapping;
