import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { startLoading } from '../../../shared/store/actions/user-env';
import { MaintenancePeriodService } from '../../services/maintenancePeriod.service';
import { setCurrentMaintenancePeriod } from '../actions/maintenancePeriods';

@Injectable()
export class MaintenancePeriodsEffects {
    loadCurrentMaintenancePeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startLoading),
            switchMap(() =>
                this.maintenancePeriodService
                    .getCurrent()
                    .pipe(map((maintenancePeriod) => setCurrentMaintenancePeriod({ maintenancePeriod }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private maintenancePeriodService: MaintenancePeriodService,
    ) {}
}
