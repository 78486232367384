import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
    AlertsMailingConfig,
    AlertsNotificationConfig,
    EMAIL_FREQUENCY,
    EMAIL_SUBSCRIPTION_STATUS,
} from '../../models/AlertsNotificationConfig';
import { AlertsMailingConfigActions } from '../actions/alerts-mailing-config';
import { AlertsNotificationConfigActions } from '../actions/alerts-notification-config';

export type AlertsNotificationState = AlertsNotificationConfig;

export const ALERTS_NOTIFICATION_INITIAL_STATE: AlertsNotificationState = {
    alertsConfig: [],
    mailConfig: {
        frequency: EMAIL_FREQUENCY.DAY,
        subscriptionEnabled: EMAIL_SUBSCRIPTION_STATUS.SUBSCRIBED,
    },
};

export default createReducer(
    ALERTS_NOTIFICATION_INITIAL_STATE,

    on(
        AlertsNotificationConfigActions.alertsNotificationConfigLoaded,
        (state, { alertsConfig, mailConfig }): AlertsNotificationState => {
            return {
                ...state,
                alertsConfig,
                mailConfig,
            };
        },
    ),

    on(
        AlertsNotificationConfigActions.alertNotificationConfigUpdateRequested,
        (state, { alertsConfig }): AlertsNotificationState => {
            return {
                ...state,
                alertsConfig: alertsConfig,
            };
        },
    ),

    on(AlertsMailingConfigActions.subscriptionEnabled, (state): AlertsNotificationState => {
        return buildStateWithNewMailingConfig(state, { subscriptionEnabled: EMAIL_SUBSCRIPTION_STATUS.SUBSCRIBED });
    }),

    on(AlertsMailingConfigActions.subscriptionDisabled, (state): AlertsNotificationState => {
        return buildStateWithNewMailingConfig(state, { subscriptionEnabled: EMAIL_SUBSCRIPTION_STATUS.UNSUBSCRIBED });
    }),

    on(AlertsMailingConfigActions.frequencySelected, (state, { frequency }): AlertsNotificationState => {
        return buildStateWithNewMailingConfig(state, { frequency });
    }),
);

function buildStateWithNewMailingConfig(state, mailingConfig: Partial<AlertsMailingConfig>) {
    return {
        ...state,
        mailConfig: {
            ...state.mailConfig,
            ...mailingConfig,
        },
    };
}

export const getAlertsNotificationState = createFeatureSelector<AlertsNotificationState>('alerts-notification');
