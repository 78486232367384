import ENV_NAMES from '../../environments/ENV_NAMES';
import constants, { storageTypeDoc } from '../shared/const';
import { availableAppLocale, countriesConfig, CountryConfig } from '../shared/i18n/i18n.config';

export const DEFAULT_LOCALE = 'en';

const DEFAULT_COUNTRY_CODE = 'US';

const getSomfyProUrl = (userCountry: string, appConfigName: ENV_NAMES): string => {
    const urlConfigKey: keyof CountryConfig =
        appConfigName === ENV_NAMES.PROD ? 'returnToSomfyProUrl' : 'returnToSomfyProPreprodUrl';

    return (
        getI18nConfigPropertyByCountry(userCountry, urlConfigKey) ??
        getI18nConfigPropertyByCountry(DEFAULT_COUNTRY_CODE, urlConfigKey)
    );
};

const getContactPageUrl = (country: string): string => {
    if (!country) {
        throw new Error('missing_country_error');
    }

    const urlConfigKey = 'returnToContactPage';

    const pageUrl =
        getI18nConfigPropertyByCountry(country, urlConfigKey) ??
        getI18nConfigPropertyByCountry(DEFAULT_COUNTRY_CODE, urlConfigKey);
    if (!pageUrl) {
        throw new Error('country_configuration_error');
    }

    return pageUrl;
};

const getStorageDocUrl = (typeDocument: storageTypeDoc, language: string): string => {
    if (!language) {
        language = DEFAULT_LOCALE;
        console.error('missing_parameter_language');
    }

    language = language.toLowerCase();

    const storageUrlConfig = constants.STORAGE_URL;

    if (typeDocument === storageTypeDoc.USER_MANUAL) {
        const dedicatedUserManual = ['fr', DEFAULT_LOCALE, 'de'];
        // Check dedicated user manual. English by default.
        const finalLocale = dedicatedUserManual.includes(language) ? language : DEFAULT_LOCALE;
        return `${storageUrlConfig.BASE_PATH}${storageUrlConfig.USER_MANUAL_PATH}${finalLocale.toUpperCase()}.pdf`;
    }

    if (typeDocument === storageTypeDoc.LEGAL_NOTICE) {
        const languageAvailable = availableAppLocale.find((lang) => lang.shortName === language);
        // Language not found => english by default.
        if (!languageAvailable) {
            language = DEFAULT_LOCALE;
        }
        const legalNoticeMapping = [{ language: 'ee', mapping: 'et' }];
        const mappedLocale = legalNoticeMapping.find((el) => el.language === language);
        const finalLocale = mappedLocale ? mappedLocale.mapping : language;
        return `${storageUrlConfig.BASE_PATH}${storageUrlConfig.LEGAL_NOTICE_PATH}${finalLocale.toUpperCase()}.pdf`;
    }

    console.warn(`missing_language_configuration_${typeDocument}`, language);
};

const getI18nConfigPropertyByCountry = (countryCode: string, property: string) => {
    const countryLowerCase = countryCode?.toLowerCase();
    const countryConf = countriesConfig.find((country) => country.code.toLowerCase() === countryLowerCase);
    return countryConf?.[property];
};

export { getSomfyProUrl, getContactPageUrl, getStorageDocUrl };
