@if (isVisible) {
  <div class="screen-size-orientation-layer">
    <img src="assets/img/servego_yellow.svg"/>
    @if (deviceIsPhoneOrTablet) {
      @if ((orientation === ORIENTATION.Portrait && heightLowerThan768) || (orientation === ORIENTATION.Landscape && widthLowerThan768)) {
        <img src="assets/img/devices.svg"/>
        <p>
          {{ 'THIS_APP_IS_DESIGNED_FOR_DESKTOP_OR_TABLET' | translate }}
        </p>
      } @else if (orientation === ORIENTATION.Portrait && !heightLowerThan768) {
        <img src="assets/img/tablette-rotation.svg"/>
        <p>
          {{ 'PLEASE_TURN_DEVICE_LANDSCAPE' | translate }}
        </p>
      }
    } @else {
      <img src="assets/img/devices.svg"/>
      <p>{{ 'THIS_APP_IS_DESIGNED_FOR_DESKTOP_OR_TABLET' | translate }}</p>
    }
  </div>
}
