import { AsyncPipe } from '@angular/common';
import { Component, type OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MaintenancePeriod } from '../../../admin/components/maintenance-period/model/maintenance-period';
import { AppState } from '../../../shared/store/app-state';
import { toHugeDate, toTime } from '../../../utils/date';
import { getCurrentMaintenancePeriod } from '../../store/selectors/maintenancePeriods';

@Component({
    selector: 'app-maintenance-period-card',
    standalone: true,
    imports: [TranslateModule, AsyncPipe],
    templateUrl: './maintenance-period-card.component.html',
    styleUrl: './maintenance-period-card.component.scss',
})
export class MaintenancePeriodCardComponent implements OnInit {
    protected maintenancePeriod$: Observable<MaintenancePeriod>;
    protected content = '';

    constructor(
        private store: Store<AppState>,
        private translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.maintenancePeriod$ = this.store.select(getCurrentMaintenancePeriod).pipe(
            filter(Boolean),
            tap(({ startDate, endDate }) => {
                this.content = this.translateService.instant('MAINTENANCE_PERIOD_MODAL_CONTENT', {
                    date: toHugeDate(startDate),
                    startTime: toTime(startDate * 1000),
                    endTime: toTime(endDate * 1000),
                });
            }),
        );
    }
}
