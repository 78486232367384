<input
    name="stateValue"
    [(ngModel)]="editedValue"
    (input)="onUserInput()"
    matInput
    type="text"
    [attr.maxLength]="config?.max"
    placeholder="{{'INSTALLATION_NOTE_MODAL_TEXTAREA_COMMENT' | translate}}"
    required
/>

