<div class="seg-modal edit-modal">
    <div mat-dialog-title class="seg-modal-header seg-modal-title">
        {{ editedProperty?.label | translate }}
    </div>

    <mat-dialog-content class="seg-modal-body" [ngClass]="{ 'seg-flex-column seg-flex-align-center flex-gap-1em': inputHelper?.key }">
        <div class="warning-panel">
            <div
                class="seg-margin-bottom seg-text-color-primary"
                [ngClass]="{ 'seg-important-instruction': isValueValid(), 'seg-violated-instruction': !isValueValid() }">{{ inputHelper?.key | translate: inputHelper?.arguments }}
            </div>
            <div
                    class="seg-margin-bottom seg-text-color-primary seg-important-instruction">{{ warningMessage | translate }}
            </div>
        </div>
        <ng-template #editor></ng-template>
    </mat-dialog-content>

    <mat-dialog-actions class="seg-flex-justify-space-around">
        <button
            id="btn-validate"
            [disabled]="!newValueDetected"
            class="seg-btn seg-bg-color-primary"
            mat-button
            mat-dialog-close
            (click)="saveState()"
        >
            {{ 'SAVE' | translate }}
        </button>
        <button
            id="btn-cancel"
            class="seg-btn seg-bg-color-secondary"
            mat-button
            mat-dialog-close
            (click)="cancelState()"
        >
            {{ 'CANCEL' | translate }}
        </button>
    </mat-dialog-actions>
</div>
