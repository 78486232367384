import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Alert, AlertId } from '../../models/Alert';

export const AlertsActions = createActionGroup({
    source: 'Alerts',
    events: {
        alertsLoaded: props<{ alerts: Alert[] }>(),
        alertDetailRequested: props<{ alertId: AlertId }>(),
        alertResolutionStarted: props<{ alertId: AlertId }>(),
        alertResolved: props<{ alertId: AlertId }>(),
    },
});

export const AlertsPageActions = createActionGroup({
    source: 'Alerts Page',
    events: {
        opened: emptyProps(),
        closed: emptyProps(),
        searchUpdated: props<{ keyword: string }>(),
        alertResolutionRequested: props<{ alertId: AlertId }>(),
        alertResolutionPageRequested: props<{ alertId: AlertId }>(),
    },
});
