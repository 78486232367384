import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const MIN_WIDTH_REQUIRED = 1024;
const WIDTH_PHONE_DETECTION = 768;

enum DeviceOrientation {
    Portrait = 'portrait',
    Landscape = 'landscape',
    Unknown = 'unknown',
}

@Component({
    selector: 'app-screen-size-orientation-layer',
    templateUrl: './screen-size-orientation-layer.component.html',
    styleUrls: ['./screen-size-orientation-layer.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class ScreenSizeOrientationLayerComponent implements OnInit {
    public isVisible = false;
    public orientation: DeviceOrientation = DeviceOrientation.Unknown;
    public ORIENTATION = DeviceOrientation;
    public minWidthCompliant: boolean;
    public deviceIsPhoneOrTablet: boolean;
    public width: number;
    public height: number;
    public widthLowerThan768: boolean;
    public heightLowerThan768: boolean;
    private currentURL: string;

    private initialWidth: number;
    private initialHeight: number;
    private zoom = false;

    private lastOrientationChange: any = undefined;
    // following URLS will shortcut the "checkSizeAndOrientation" method.
    // If you want an url from the app to be accessible even if device's width < 1024 px fpr example,
    // add the url to this page
    private excludedURLS = ['query/intervention/', 'servego/'];

    constructor(
        private location: Location,
        private router: Router,
    ) {
        this.initialHeight = window.innerHeight;
        this.initialWidth = window.innerWidth;
    }

    show() {
        this.isVisible = true;
    }

    hide() {
        this.isVisible = false;
    }

    ngOnInit() {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.currentURL = this.location.path().toLowerCase();
                if (!this.zoom) {
                    this.checkSizeAndOrientation();
                }
            }
        });
        //  window.addEventListener('orientationchange', this.onOrientationChange);
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:orientationchange', ['$event'])
    checkSizeAndOrientation($event?: Event) {
        if (
            this.currentURL &&
            this.excludedURLS.find((exclURL) => this.currentURL.indexOf(exclURL.toLowerCase()) !== -1)
        ) {
            return;
        }
        if ($event && $event.type === 'orientationchange') {
            this.lastOrientationChange = Date.now();
            setTimeout(() => {
                this.lastOrientationChange = undefined;
            }, 500);
        }

        this.orientation = this.getDeviceOrientation();
        this.deviceIsPhoneOrTablet = this.getDeviceIsPhoneOrTablet();
        if (this.deviceIsPhoneOrTablet) {
            // https://stackoverflow.com/questions/6850164/get-the-device-width-in-javascript
            this.width = Math.max(window.innerWidth, screen.width);
            this.height = Math.max(window.innerHeight, screen.height);
        } else {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }

        if (
            this.deviceIsPhoneOrTablet &&
            $event &&
            $event.type === 'resize' &&
            this.lastOrientationChange === undefined
        ) {
            if (this.width < this.initialWidth && this.height < this.initialHeight) {
                this.zoom = true;
            } else {
                this.zoom = true;
            }
            return;
        }

        this.minWidthCompliant = this.width >= MIN_WIDTH_REQUIRED;
        this.widthLowerThan768 = this.width < WIDTH_PHONE_DETECTION;
        this.heightLowerThan768 = this.height < WIDTH_PHONE_DETECTION;

        // in this case, no matter the "portrait" or "landscape" mode, device will never be able to display app
        if (this.width < MIN_WIDTH_REQUIRED && this.height < MIN_WIDTH_REQUIRED) {
            this.minWidthCompliant = false;
            this.widthLowerThan768 = true;
            this.heightLowerThan768 = true;
        }

        if (!this.minWidthCompliant) {
            this.show();
        } else {
            this.hide();
        }
    }

    /**
     * Guess if current device is phone or tablet
     * see https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
     * @returns {boolean}
     */
    getDeviceIsPhoneOrTablet(): boolean {
        let phoneOrTablet = false;
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                userAgent.substr(0, 4),
            )
        ) {
            phoneOrTablet = true;
        }
        return phoneOrTablet;
    }

    /**
     *  Try to guess the current screen orientation
     *  see https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
     *
     *  If device is phone or tablet, we try to read the information inside the screen object if available
     *  If device is a computer, orientation will always be 'landscape', even if user resize window as
     *  portrait (height > width).
     *
     *
     * @returns {DeviceOrientation}
     */
    getDeviceOrientation(): DeviceOrientation {
        const browserOrientation =
            (screen as any).msOrientation || ((screen as any).orientation || (screen as any).mozOrientation || {}).type;
        if (
            browserOrientation &&
            this.deviceIsPhoneOrTablet &&
            browserOrientation.toLowerCase().indexOf('landscape') !== -1
        ) {
            return DeviceOrientation.Landscape;
        } else if (
            browserOrientation &&
            this.deviceIsPhoneOrTablet &&
            browserOrientation.toLowerCase().indexOf('portrait') !== -1
        ) {
            return DeviceOrientation.Portrait;
        } else if (browserOrientation === undefined || !this.deviceIsPhoneOrTablet) {
            // Compute orientation ourselves according width / height
            const width = window.innerWidth;
            const height = window.innerHeight;

            if (width > height) {
                // assuming screen orientation is landscape
                return DeviceOrientation.Landscape;
            } else {
                // assuming screen orientation is portrait
                return DeviceOrientation.Portrait;
            }
        }
    }
}
