import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import type { SiteAssignation } from '../../models/SiteAssignation';
import { loadPendingSiteAssignations } from '../actions/site-assignation';
import * as SiteAssignationActions from '../actions/site-assignation';

export interface SiteAssignationState {
    siteAssignations: SiteAssignation[];
}

export const SITE_ASSIGNATION_INITIAL_STATE: SiteAssignationState = {
    siteAssignations: [],
};

export default createReducer(
    SITE_ASSIGNATION_INITIAL_STATE,

    on(
        loadPendingSiteAssignations,
        (state, { siteAssignations }): SiteAssignationState => ({
            ...state,
            siteAssignations,
        }),
    ),

    on(
        SiteAssignationActions.addPendingSiteAssignation,
        (state, { siteAssignation }): SiteAssignationState => ({
            ...state,
            siteAssignations: [...state.siteAssignations, siteAssignation],
        }),
    ),

    on(SiteAssignationActions.deletePendingSiteAssignation, (state, { gatewayId }): SiteAssignationState => {
        const existingAssignationRequest = state.siteAssignations.find(
            (d: SiteAssignation) => d.gatewayId === gatewayId,
        );
        if (!existingAssignationRequest) {
            return state;
        }

        const siteAssignations = state.siteAssignations.filter((d) => d.gatewayId !== gatewayId);

        return { ...state, siteAssignations };
    }),
);

export const getSiteAssignationState = createFeatureSelector<SiteAssignationState>('site-assignation');
