import { createSelector, Selector } from '@ngrx/store';
import { AppState } from '../../../shared/store/app-state';

export default function emitOnChangeOnly<T>(selector: Selector<AppState, T>) {
    let currentValue: T;
    return createSelector(selector, (newValue) => {
        if (currentValue !== newValue && JSON.stringify(currentValue) != JSON.stringify(newValue)) {
            currentValue = newValue;
        }
        return currentValue;
    });
}
