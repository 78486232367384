import {
    ToasterDeviceUpdateMessage,
    ToasterMessageType,
    ToasterSiteDeviceErrorMessage,
} from '../../../shared/models/Toaster';
import { UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';
import { DeviceMS } from '../../models/Device';
import type { Owner } from '../../models/Site';
import { getUserSiteName } from '../../utils/owner.util';
import { RefreshDeviceSectionCommand } from '../actions/device-detail';

function buildToasterMessageBase(messageType: ToasterMessageType, device: DeviceMS, owner: Owner) {
    return {
        type: messageType,
        id: crypto.randomUUID(),
        content: {
            siteId: device.site_id,
            deviceId: device.id,
            deviceName: device.name,
            userSiteName: getUserSiteName(owner),
        },
    };
}

export function buildDeviceUpdateFailedToasterMessage(
    updateCommand: UpdateDeviceCommand,
    owner: Owner,
): ToasterDeviceUpdateMessage {
    const baseMessage = buildToasterMessageBase(ToasterMessageType.DEVICE_UPDATE_STATE, updateCommand.device, owner);
    return {
        ...baseMessage,
        content: {
            ...baseMessage.content,
            isUpdateValid: false,
            stateLabel: updateCommand.property.label,
            newValue: updateCommand.newValue,
        },
    };
}

export function buildDeviceSectionSyncFailedToasterMessage(
    refreshCommand: RefreshDeviceSectionCommand,
    owner: Owner,
): ToasterSiteDeviceErrorMessage {
    const baseMessage = buildToasterMessageBase(
        ToasterMessageType.SITE_DEVICE_REQUEST_ERROR,
        refreshCommand.device,
        owner,
    );
    return {
        ...baseMessage,
        content: {
            ...baseMessage.content,
            sectionLabel: refreshCommand.sectionId,
            errorLabel: 'EQUIPMENT_REFRESH_TOAST_ERROR',
        },
    };
}
