import { NgClass } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../../AppConfig';
import { ModalSignedDocumentComponent } from '../../../shared/components/modal/modal-signed-document/modal-signed-document.component';
import { storageTypeDoc } from '../../../shared/const';
import { APP_CONFIG } from '../../../shared/injectionTokens';
import { User } from '../../../shared/models/User';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { getStorageDocUrl } from '../../../utils/url';
import { OauthService } from '../../services/oauth.service';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@Component({
    selector: 'app-side-nav',
    standalone: true,
    imports: [TranslateModule, NgClass, LanguageSelectorComponent],
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
})
export class SideNavComponent {
    rightMenuOpen = false;
    version: string;

    @Input() userLogin: User;

    constructor(
        public analyticService: AnalyticsService,
        private authService: OauthService,
        private dialog: MatDialog,
        private router: Router,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {
        this.version = this.appConfig.gitReleaseVersion;
    }

    toggleRightMenu() {
        this.rightMenuOpen = !this.rightMenuOpen;
        if (this.rightMenuOpen) {
            this.analyticService.clickGlobalMenu();
        }
    }

    closeMenu() {
        this.rightMenuOpen = false;
    }

    logout() {
        this.authService.logout();
    }

    onConfigAlertClick() {
        this.analyticService.clickAlertConfig();
        this.router.navigate(['/alerts/config']);
        this.closeMenu();
    }

    showSignedLegalDocModal() {
        this.dialog.open(ModalSignedDocumentComponent);
    }

    redirectToUserGuide(): void {
        this.analyticService.accessGlobalMenuDocument('user guide');
        window.open(`${getStorageDocUrl(storageTypeDoc.USER_MANUAL, this.translateService.currentLang)}`, '_blank');
        this.closeMenu();
    }

    redirectToLegalNotice(): void {
        this.analyticService.accessGlobalMenuDocument('legal notice');
        window.open(`${getStorageDocUrl(storageTypeDoc.LEGAL_NOTICE, this.translateService.currentLang)}`, '_blank');
        this.closeMenu();
    }
}
