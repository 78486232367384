import { createReducer, on } from '@ngrx/store';
import { AlertId, TriggerId } from '../../../alerts/models/Alert';
import { AlertResolutionStep } from '../../models/AlertResolutionSteps';
import { AlertResolutionActions } from '../actions/alert-resolution';

export interface AlertResolutionState {
    resolutionPaths: Record<TriggerId, AlertResolutionStep[]>;
    errors: Record<AlertId, string>;
}

export const ALERT_RESOLUTION_INITIAL_STATE: AlertResolutionState = {
    resolutionPaths: {},
    errors: {},
};

export default createReducer(
    ALERT_RESOLUTION_INITIAL_STATE,

    on(
        AlertResolutionActions.alertResolutionPathApiSucceeded,
        (state: AlertResolutionState, { resolutionPath, triggerId }): AlertResolutionState => {
            return { ...state, resolutionPaths: { ...state.resolutionPaths, [triggerId]: resolutionPath } };
        },
    ),

    on(
        AlertResolutionActions.alertLoadingFailed,
        (state: AlertResolutionState, { alertId, errorMessage }): AlertResolutionState => {
            return { ...state, errors: { ...state.errors, [alertId]: errorMessage } };
        },
    ),
);
