import { createActionGroup, props } from '@ngrx/store';
import { DeviceId, DeviceMS } from '../../models/Device';
import { SiteId } from '../../models/Site';

export const SiteDevicesActions = createActionGroup({
    source: 'Site Devices',
    events: {
        devicesLoaded: props<{ siteId: SiteId; devices: DeviceMS[] }>(),
        deviceLoaded: props<{ siteId: SiteId; device: DeviceMS }>(),
        deviceCreated: props<{ siteId: SiteId; device: DeviceMS }>(),
        deviceUpdated: props<{ siteId: SiteId; device: DeviceMS }>(),
        deviceLabelUpdated: props<{ siteId: SiteId; deviceId: DeviceId; deviceLabel: string }>(),
        deviceRemoved: props<{ siteId: SiteId; deviceId: DeviceId }>(),
    },
});
