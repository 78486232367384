import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, flatMap, share, switchMap, tap, timeout } from 'rxjs/operators';
import { AdminApi } from '../../../admin/store/api/admin';
import { UserService } from '../../../core/services/user.service';
import { LegalDocsApi } from '../../../core/store/api/legalDocs';
import { MessagesApi } from '../../../messages/store/api/messages';
import { User } from '../../models/User';
import { ErrorService } from '../../services/error.service';
import { setLoginUser } from '../actions/login';
import { envLoaded, startLoading } from '../actions/user-env';
import { AppState } from '../app-state';

@Injectable()
export class UserEnvApi {
    constructor(
        private userService: UserService,
        private adminApi: AdminApi,
        private errorService: ErrorService,
        private messageAPI: MessagesApi,
        private legalDocApi: LegalDocsApi,
        private store: Store<AppState>,
    ) {}

    loadUserEnvironment(): Observable<any> {
        this.store.dispatch(startLoading());

        const getInstaller: Observable<User> = this.userService.getInstaller().pipe(share());

        const forkedObservables = forkJoin([
            getInstaller.pipe(
                flatMap((user: User) => {
                    const sharedSubscription = this.legalDocApi.getShareableSubscriptionForCurrentUser(user);
                    return forkJoin(
                        sharedSubscription.pipe(
                            flatMap((subId) => this.legalDocApi.getAllSubscriptionLegalDocuments(subId)),
                        ),
                    );
                }),
            ),
            getInstaller.pipe(
                switchMap(
                    () => this.messageAPI.getMessageForInstaller(),
                    (user: User) => user,
                ),
            ),
            getInstaller.pipe(
                tap((user: User) => {
                    user.id &&
                        Sentry.setUser({
                            id: user.id,
                        });
                    this.store.dispatch(setLoginUser({ loginuser: user }));
                }),
            ),
        ]);

        return forkedObservables.pipe(
            tap(() => this.store.dispatch(envLoaded())),
            timeout(30 * 1000), // how long before stop waiting
            catchError((err) => {
                console.error(err);
                this.errorService.showToasterError('ERROR_SITES_TAKES_TO_LONG_TO_LOAD_OR_UNAVAILABLE', {
                    duration: 30 * 1000, // how long do we want to display the error toaster
                });
                return of([]);
            }),
        );
    }

    loadAdminUserEnvironment(): Observable<any> {
        this.store.dispatch(startLoading());

        const currentBackOfficeUser$ = this.adminApi.getCurrentBackOfficeUserInfo().pipe(share());

        const all = forkJoin([this.messageAPI.getAdminMessages(), currentBackOfficeUser$]);
        return all.pipe(
            // #SEG-794
            // When observable return empty response (like GET bo/users/associations), the success callback of subscribe()
            // or the piped actions like forkjoin, map, tap etc... are not called.
            // see link below
            // https://blog.novanet.no/rxjs-getting-fooled-by-empty-observables/
            finalize(() => this.store.dispatch(envLoaded())),
        );
    }
}
