import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { Execution } from '../../models/Execution';
import { BoxGateway, RebootBoxCommand } from './BoxGateway';

@Injectable()
export class HttpBoxGateway implements BoxGateway {
    constructor(private httpService: HttpService) {}

    reboot(rebootBoxCommand: RebootBoxCommand): Observable<Execution> {
        const { device, sessionId } = rebootBoxCommand;

        return this.httpService.patch(`/device`, {
            device: {
                id: device.id,
                type_id: device.type_id,
                deviceurl: device.deviceurl,
                protocol_profile: device.protocol_profile,
                // FIXME Following fields are not required for a device (maybe for gateway ?). To remove lately when Servego API will be refactored
                categories: device.categories,
                definition: device.definition,
                site_id: device.site_id,
                type: device.type,
            },
            command: {
                speAction: 'reboot',
            },
            sessionId,
        });
    }
}
