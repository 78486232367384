import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OauthService } from '../../services/oauth.service';

@Component({
    selector: 'app-admin-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class UnAuthorizedComponent {
    constructor(private authService: OauthService) {}

    onLeave() {
        this.authService.logout();
    }
}
