import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

interface ConfirmModalData {
    title: string;
    message: string;
}

@Component({
    standalone: true,
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    imports: [TranslateModule, MatDialogModule, MatButtonModule],
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {}

    onConfirm() {
        this.dialogRef.close(true);
    }
}
