import { DevicePropertyValue } from '../../site-detail/models/Device';

export function isCurrentValueExpected(
    expectedValues: string | number | number[] | undefined,
    value: DevicePropertyValue,
) {
    if (expectedValues == null || Array.isArray(value)) {
        return true;
    }
    if (Array.isArray(expectedValues)) {
        return expectedValues.map((v) => `${v}`).includes(`${value}`);
    }
    return expectedValues == value;
}
