<div if="id" class="access-granted-item" >
    <div class="access-granted-item-header">
        <div class="header-message">{{ translatedHeader }}</div>
        <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
    </div>
    <div class="access-granted-item-footer">
        <button
        class="button-blue"
        id="start-intervention"
        (click)="onRedirectToSite()">
            <p>{{ 'TOASTER_ASERVEGO_GRANTED_SHOW_SETUP' | translate }}</p>
        </button>
    </div>
</div>
