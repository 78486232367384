<div class="number-editor">
  <div class="text-center text-bigger seg-flex-justify-center">
    {{ editedValue | format: config.formatter }}
  </div>

  <div class="slider-container seg-flex-align-center flex-gap-05em">
    <span>
      {{ min | format: config.formatter }}
    </span>

    <mat-slider [min]="min"
      [max]="max"
      [step]="step"
      ><input matSliderThumb
      [value]="editedValue"
      (input)="onUserInput(ngSliderThumb.value)"
      #ngSliderThumb="matSliderThumb"
    /></mat-slider>

    <span>
      {{ max | format: config.formatter }}
    </span>
  </div>

  @if (config.labelMin && config.labelMax) {
    <div class="minmax-labels">
      <span>{{ config.labelMin | translate }}</span>
      <span>{{ config.labelMax | translate }}</span>
    </div>
  }
</div>
