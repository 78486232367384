import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { CreateUserAccess, UpdateUserAccess, UserAccess } from '../../models/access';
import { BackOfficeUser } from '../../models/backOfficeUser';
import { AutomaticClosureStats, CMSAssociationResult, InstallerSignatureInfo } from '../../models/installer';
import {
    CreateTranslationRequest,
    ExternalDocumentTranslation,
    LegalDocument,
    LegalDocumentCreationRequest,
    LegalDocumentVersion,
    LegalDocumentVersionCreationRequest,
    UserSubscription,
} from '../../models/subscription';
import { BackOfficeService } from '../../services/back.office.service';
import {
    addCreatedLegalDoc,
    loadInstallersCmsAssociation,
    loadUserAccess,
    removeLegalDocument,
    removeLegalDocVersion,
    removeUserAccess,
    setCurrentBackOfficeUserInfo,
    setLegalDocsTranslations,
    setLegalDocsVersions,
    setLegalDocuments,
    setSelectedInstallerSignatures,
    setServices,
    setUserSubscriptions,
} from '../actions/admin';

@Injectable()
export class AdminApi {
    constructor(
        private store: Store<AppState>,
        private backOfficeService: BackOfficeService,
    ) {}

    loadInstallerSignatureInfo(installerId: string, docs: Array<LegalDocument>): Observable<InstallerSignatureInfo[]> {
        const docsIds = docs.map((doc) => doc.id);
        const docsNames = docs.map((doc) => {
            return {
                id: doc.id,
                name: doc.display_name,
            };
        });
        return this.backOfficeService.getInstallerLastSignatureInfo(installerId, docsIds).pipe(
            map((signatures: InstallerSignatureInfo[]) => {
                const namedSignatures = signatures.map((signature) => {
                    return {
                        ...signature,
                        name: docsNames.filter((doc) => doc.id === signature.id)[0].name,
                    };
                });
                this.store.dispatch(
                    setSelectedInstallerSignatures({
                        signatureInfo: namedSignatures,
                    }),
                );
                return namedSignatures;
            }),
        );
    }

    loadUserAccess(): Observable<UserAccess[]> {
        return this.backOfficeService.getAdminUsers().pipe(
            map((installers: UserAccess[]) => {
                this.store.dispatch(loadUserAccess({ users: installers }));
                return installers;
            }),
        );
    }

    updateUserAccess(user: CreateUserAccess | UpdateUserAccess): Observable<UserAccess> {
        return this.backOfficeService.updateUserAccess(user);
    }

    setUserPassword(password: string, verificationHash: string): Observable<void> {
        return this.backOfficeService.setUserPassword(password, verificationHash);
    }

    removeUserAccess(userId: string): Observable<string> {
        return this.backOfficeService.removeUserAccess(userId).pipe(
            map(() => {
                this.store.dispatch(removeUserAccess({ userAccess: userId }));
                return userId;
            }),
        );
    }

    resetPasswordForUserAccess(userEmail: string): Observable<void> {
        return this.backOfficeService.resetUserAccessPassword(userEmail);
    }

    getStats(): Observable<string> {
        return this.backOfficeService.getStats();
    }

    getServices(): Observable<any> {
        return this.backOfficeService
            .getServices()
            .pipe(tap((serviceId: string) => this.store.dispatch(setServices({ serviceId }))));
    }

    getLegalDocuments(serviceId: string) {
        return this.backOfficeService
            .getLegalDocuments(serviceId)
            .pipe(tap((documents: LegalDocument[]) => this.store.dispatch(setLegalDocuments({ documents }))));
    }

    deleteLegalDoc(id: string) {
        return this.backOfficeService
            .deleteLegalDoc(id)
            .pipe(tap((deleteDocId: string) => this.store.dispatch(removeLegalDocument({ deleteDocId }))));
    }

    getLegalDocsVersions(idDocument: string) {
        return this.backOfficeService.getLegalDocsVersions(idDocument).pipe(
            tap((versions: LegalDocumentVersion[]) => this.store.dispatch(setLegalDocsVersions({ versions }))),
            tap((versions: LegalDocumentVersion[]) => this.store.dispatch(setLegalDocsTranslations({ versions }))),
        );
    }

    createNewLegalDoc(req: LegalDocumentCreationRequest): Observable<string> {
        return this.backOfficeService.createNewLegalDoc(req).pipe(
            tap((docId: string) => {
                const createdDoc: LegalDocument = {
                    display_name: req.display_name,
                    service_id: req.service_id,
                    created_at: Date.now(), // use as placeholder. When we will download all docs from server, the date will correctly be set
                    id: docId,
                };
                return this.store.dispatch(addCreatedLegalDoc({ doc: createdDoc }));
            }),
        );
    }

    createNewLegalDocVersion(req: LegalDocumentVersionCreationRequest): Observable<string> {
        return this.backOfficeService.createNewLegalDocVersion(req);
    }

    createNewLegalDocTranslation(req: CreateTranslationRequest): Observable<string> {
        return this.backOfficeService.createNewLegalDocTranslation(req).pipe(
            tap((versionLegalDocId: string) => {
                const createdTranslation: ExternalDocumentTranslation = {
                    id: versionLegalDocId,
                    file: req.file,
                    versionId: req.legaldocumentversion_id,
                    markdown: req.markdown,
                    local: req.local,
                };
            }),
        );
    }

    uploadNewLegalDocTranslation(
        req: CreateTranslationRequest,
        translationId: string,
        rawFile: File,
    ): Observable<string> {
        return this.backOfficeService.uploadLegalDocTranslation(req, translationId, rawFile);
    }

    getLegalDocTranslations(id: string) {
        return this.backOfficeService.getLegalDocVersionTranslations(id);
    }

    downloadFile(id: string) {
        return this.backOfficeService.downloadTranslationFile(id);
    }

    getUserSubscriptions(serviceId: string) {
        return this.backOfficeService.getUserSubscriptions(serviceId).pipe(
            tap((subscriptions: UserSubscription[]) => {
                this.store.dispatch(setUserSubscriptions({ subscriptions }));
            }),
        );
    }

    deleteLegalDocVersion(id: string) {
        return this.backOfficeService
            .deleteLegalDocVersion(id)
            .pipe(
                tap((deleteDocVersionId: string) => this.store.dispatch(removeLegalDocVersion({ deleteDocVersionId }))),
            );
    }

    getCurrentBackOfficeUserInfo(): Observable<BackOfficeUser> {
        return this.backOfficeService
            .getCurrentBackOfficeUserInfo()
            .pipe(tap((user: BackOfficeUser) => this.store.dispatch(setCurrentBackOfficeUserInfo({ user }))));
    }

    advancedInstallerSearch(
        buList: string[],
        fieldValues: any,
        strictMode = false,
    ): Observable<CMSAssociationResult[]> {
        // Get user installer info
        return this.backOfficeService.advancedInstallerSearch(buList, fieldValues, strictMode).pipe(
            tap((cmsAssociationsResult: CMSAssociationResult[]) =>
                this.store.dispatch(
                    loadInstallersCmsAssociation({
                        cmsAssociationResults: cmsAssociationsResult,
                    }),
                ),
            ),
        );
    }

    closeInstallerInterventions(installerId: string): Observable<AutomaticClosureStats> {
        return this.backOfficeService.closeAllPendingInterventions(installerId);
    }
}
