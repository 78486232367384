import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ALERT_SEVERITY, AlertSeverity } from '../../models/Alert';

@Component({
    selector: 'app-alert-severity',
    standalone: true,
    imports: [TranslateModule, MatTooltip],
    templateUrl: './alert-severity.component.html',
    styleUrl: './alert-severity.component.scss',
})
export class AlertSeverityComponent {
    @Input() severity: AlertSeverity = ALERT_SEVERITY.NORMAL;
}
