import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Site } from '../../../site-detail/models/Site';
import { SitesListFilters } from '../../models/SitesListFilters';

export const SitesActions = createActionGroup({
    source: 'Sites',
    events: {
        loadSitesRequested: props<{ filters: SitesListFilters }>(),
        sitesLoaded: props<{ keywords: string; sites: Site[]; count: number }>(),
        sitesSearched: props<{ keywords: string }>(),
    },
});

export const SitesPageActions = createActionGroup({
    source: 'Sites Page',
    events: {
        opened: emptyProps(),
    },
});
