import { PRODUCT_TYPE, ProductAlertsGroup } from './models/AlertsNotificationConfig';

const productAlertsNotificationGroups: ProductAlertsGroup[] = [
    {
        name: 'ALERT_FILTER_OPTION_ROLLER_SHUTTER',
        productType: PRODUCT_TYPE.SHUTTER,
        triggerIdList: [1, 4, 5, 6, 26],
    },
    {
        name: 'ALERT_FILTER_OPTION_SUNSCREEN',
        productType: PRODUCT_TYPE.AWNING,
        triggerIdList: [2, 3, 7, 9, 10, 11, 13, 14, 27],
    },
    {
        name: 'ALERT_FILTER_OPTION_ACCESS',
        productType: PRODUCT_TYPE.ACCESS,
        triggerIdList: [15, 16, 17, 18, 19, 20, 21, 22, 24, 25],
    },
    {
        name: 'ALERT_FILTER_OPTION_WINDOW',
        productType: PRODUCT_TYPE.WINDOW,
        triggerIdList: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43],
    },
    {
        name: 'ALERT_FILTER_OPTION_INTERIOR',
        productType: PRODUCT_TYPE.INTERIOR,
        triggerIdList: [44, 45],
    },
];

export default productAlertsNotificationGroups;
