import { Directive, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../site-detail/store/actions/ui';
import { AppState } from '../../store/app-state';

@Directive()
export class AbstractToaster implements OnInit {
    @Input()
    content: any;
    @Input()
    id: string;
    @Input()
    duration?: number;
    timeoutRef: any;

    constructor(
        protected translateService: TranslateService,
        protected store: Store<AppState>,
    ) {}

    ngOnInit() {
        const delay = this.duration || 4000;
        this.timeoutRef = setTimeout(() => {
            this.removeMessage();
        }, delay);
    }

    removeMessage() {
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }

    onClose() {
        this.store.dispatch(removeToasterMessage({ id: this.id }));
        clearTimeout(this.timeoutRef);
    }
}
