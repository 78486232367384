<input class="number-input"
       name="stateValue"
       [(ngModel)]="editedValue"
       (input)="onInputChange()"
       matInput
       type="number"
       [min]="min"
       [max]="max"
       [step]="step"
       dir="rtl"
       autofocus
/>
<span>{{ config.formatter.unit | translate }}</span>
