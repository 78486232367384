import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { FavoriteSitesActions } from '../actions/favorite-site';

export type FavoriteSitesState = SiteId[];

export const FAVORITE_SITES_INITIAL_STATE: FavoriteSitesState = [];

export default createReducer(
    FAVORITE_SITES_INITIAL_STATE,

    on(FavoriteSitesActions.favoritesLoaded, (state, { sites }): FavoriteSitesState => {
        if (sites == null) {
            return state;
        }
        return [...sites?.map((site) => site.id)];
    }),

    on(FavoriteSitesActions.favoriteAdded, (state, { siteId }): FavoriteSitesState => {
        if (state.includes(siteId)) {
            return state;
        }

        return [...state, siteId];
    }),

    on(FavoriteSitesActions.favoriteRemoved, (state, { siteId }): FavoriteSitesState => {
        return state.filter((id) => id !== siteId);
    }),
);

export const getFavoriteSitesState = createFeatureSelector<FavoriteSitesState>('site-favorite');
