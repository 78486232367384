import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap, share, tap } from 'rxjs/operators';
import { ExternalLegalDocument, UserSubscription } from '../../../admin/models/subscription';
import { User } from '../../../shared/models/User';
import { AppState } from '../../../shared/store/app-state';
import { UserLegalDocService } from '../../services/userLegalDoc.service';
import { setSignedDocument, setUnsignedDocument } from '../actions/legalDocument';

@Injectable()
export class LegalDocsApi {
    constructor(
        private userLegalDocService: UserLegalDocService,
        private store: Store<AppState>,
    ) {}

    private getSubscriptionForCurrentUser(user: User): Observable<string> {
        return this.userLegalDocService.getSubscriptionFoCurrentUser().pipe(
            mergeMap((subs: UserSubscription[]) => {
                if (subs.length > 0) {
                    // current installer has subscribed at least one service
                    return of(subs[0].id);
                } else {
                    // we need to create the subscription
                    return this.userLegalDocService
                        .createSubscription(user)
                        .pipe(mergeMap((subId: string) => of(subId)));
                }
            }),
        );
    }

    getTranslationFileForSubscription(subscriptionId: string, translationFileId: string): Observable<any> {
        return this.userLegalDocService.downloadTranslationFile(subscriptionId, translationFileId);
    }

    signDocument(docToSign: ExternalLegalDocument) {
        return this.userLegalDocService.signDocument(docToSign.subscription_id, docToSign.id);
    }

    getShareableSubscriptionForCurrentUser(user: User): Observable<any> {
        return this.getSubscriptionForCurrentUser(user).pipe(share());
    }

    getAllSubscriptionLegalDocuments(subId: string): Observable<any> {
        return this.userLegalDocService.getAllSubscriptionLegalDocuments(subId).pipe(
            tap((documents: ExternalLegalDocument[]) => {
                const unsignedLegalDocuments = documents.filter((doc) => !doc.signature);
                const signedLegalDocuments = documents.filter((doc) => doc.signature);
                if (unsignedLegalDocuments?.length > 0) {
                    this.store.dispatch(setUnsignedDocument({ docs: unsignedLegalDocuments }));
                }
                if (signedLegalDocuments?.length > 0) {
                    this.store.dispatch(setSignedDocument({ docs: signedLegalDocuments }));
                }
            }),
        );
    }
}
