import { TextEditorSpec } from '../../../../models/Editor';
import { UiProfileProperty } from '../../../../models/UiProfile';
import { SectionItemEditor } from '../buildEditor';

export function buildTextEditor(uiProperty: UiProfileProperty, value: string): SectionItemEditor {
    const editor = uiProperty.editor as TextEditorSpec;

    return {
        type: 'text',
        value,
        inputHelper: { key: 'ENGLISH_LETTERS_ONLY' },
        ...(editor.max ? { config: { max: editor.max } } : {}),
    };
}
