import { createReducer, on } from '@ngrx/store';
import { AppActions } from '../../../core/store/actions/app';
import { SiteId } from '../../../site-detail/models/Site';
import { removeSite } from '../../../site-detail/store/actions/site';
import { SitesListFilters } from '../../models/SitesListFilters';
import { SitesActions, SitesPageActions } from '../actions/sites';

export interface UISiteListState {
    filters: SitesListFilters;
    loading: boolean;
    searchCount: number;
    maxCount: number;
    list: SiteId[];
}

export const UI_SITE_LIST_INITIAL_STATE: UISiteListState = {
    filters: {
        offset: 1,
        keywords: '',
    },
    loading: false,
    searchCount: 0,
    maxCount: 0,
    list: [],
};

export default createReducer(
    UI_SITE_LIST_INITIAL_STATE,

    on(
        SitesPageActions.opened,
        AppActions.initialized,
        (state): UISiteListState => ({
            filters: {
                offset: 1,
                // keep keywords when re-opening the page
                keywords: state.filters.keywords,
            },
            loading: true,
            searchCount: 0,
            maxCount: state.maxCount,
            list: [],
        }),
    ),

    on(
        SitesActions.sitesSearched,
        (state, { keywords }): UISiteListState => ({
            filters: {
                offset: 1,
                keywords,
            },
            loading: true,
            searchCount: 0,
            maxCount: state.maxCount,
            list: [],
        }),
    ),

    on(
        SitesActions.loadSitesRequested,
        (state, { filters }): UISiteListState => ({
            filters,
            loading: true,
            searchCount: state.searchCount,
            maxCount: state.maxCount,
            list: state.list,
        }),
    ),

    on(
        SitesActions.sitesLoaded,
        (state, { keywords, sites, count }): UISiteListState =>
            state.filters.keywords === keywords
                ? {
                      filters: {
                          offset: state.filters.offset,
                          keywords,
                      },
                      loading: false,
                      searchCount: count,
                      maxCount: keywords.trim() === '' ? count : state.maxCount,
                      list: [...state.list, ...sites.map((site) => site.id)],
                  }
                : state,
    ),

    on(removeSite, (state, { siteId }): UISiteListState => {
        if (!state.list.includes(siteId)) {
            return state;
        }

        return { ...state, list: state.list.filter((id) => id !== siteId) };
    }),
);
