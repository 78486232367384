<div if="id"
     [className]="content?.isQueued ? 'device-refresh-state-failed-item valid' : 'device-refresh-state-failed-item invalid'"
     (click)="onClick()">
  <div class="device-upate-item-header">
    <div class="device-refresh-state-failed-item-header-icon">
      @if (content?.isQueued) {
        <img src="assets/img/update-parameter.svg"/>
      } @else {
        <img src="assets/img/alerts/alert.svg"/>
      }
    </div>
    <div class="device-refresh-state-failed-item-header-content">
      <div class="device-name">{{ content.deviceName }}</div>
      <div class="user-name">{{ content.userSiteName }}</div>
    </div>
    <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
  </div>
  <div class="device-upate-item-content">
    <div class="device-upate-item-content-message" [innerHTML]="translatedMessage"></div>
  </div>
</div>
