import { buList, countriesConfig } from '../shared/i18n/i18n.config';

export interface BUOption {
    value: string;
    viewValue: string;
}

export interface CountryOption {
    value: string;
    code: string;
}

export function getBUValuesByPartners(partners: string[]) {
    const caseInsensitivePartners = partners.map((p) => p.toLowerCase());
    return buList.filter((bu) => caseInsensitivePartners.includes(bu.value.toLowerCase()));
}

export function getCountryValuesByCode(codes: string[]) {
    const caseInsensitiveCodes = codes.map((p) => p.toLowerCase());
    return countriesConfig.filter((country) => caseInsensitiveCodes.includes(country.code.toLowerCase()));
}

export function getCountryValuesByPartners(partners: string[]) {
    const caseInsensitiveCodes = partners.map((p) => p.toLowerCase());
    return countriesConfig.filter((partner) => caseInsensitiveCodes.includes(partner.bu.toLowerCase()));
}

export function getBUValuesFromCountriesCode(codes: string[]) {
    if (codes.includes('all')) {
        return ['all'];
    }
    const caseInsensitiveCountries = codes.map((p) => p.toLowerCase());
    const bu = countriesConfig
        .map((country) => {
            if (caseInsensitiveCountries.includes(country.code.toLowerCase())) {
                return country.bu;
            }
            return null;
        })
        .filter(Boolean);
    return [...new Set(bu)];
}

export function getBUValuesWithALLOption(): BUOption[] {
    return [
        ...buList,
        {
            value: 'all',
            viewValue: 'ALL',
        },
    ];
}

export function getCountryValuesWithALLOption(): CountryOption[] {
    return [
        ...countriesConfig,
        {
            value: 'All',
            code: 'all',
        },
    ];
}
