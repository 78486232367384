import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { AlertConfig, AlertsNotificationConfig, AlertsNotificationConfigId } from '../models/AlertsNotificationConfig';
import productAlertsNotificationGroups from '../productAlertsNotificationGroups';
import { AlertConfigChangeRequest, AlertNotificationConfigGateway } from './AlertNotificationConfigGateway';

@Injectable()
export class HttpAlertNotificationConfigGateway implements AlertNotificationConfigGateway {
    constructor(private httpService: HttpService) {}

    getAlertsNotificationConfig(): Observable<AlertsNotificationConfig> {
        return this.httpService.get(`/alert-configuration`).pipe(
            map(({ config, frequency, subscribed }) => {
                return {
                    alertsConfig: this.updateAlertsNotificationConfigWithProductType(config),
                    mailConfig: {
                        frequency,
                        subscriptionEnabled: subscribed,
                    },
                };
            }),
        );
    }

    saveAlertsNotificationConfig(
        alertConfigChangeRequest: AlertConfigChangeRequest,
    ): Observable<AlertsNotificationConfigId> {
        return this.httpService.put(`/alert-configuration`, alertConfigChangeRequest);
    }

    private updateAlertsNotificationConfigWithProductType(config: AlertConfig[]): AlertConfig[] {
        return config.map((alertConfig: AlertConfig) => {
            const updatedAlertConfig = { ...alertConfig };
            const matchingProduct = productAlertsNotificationGroups.find((product) =>
                updatedAlertConfig.external_uid.some((uid) => product.triggerIdList.includes(uid)),
            );

            if (matchingProduct) {
                updatedAlertConfig.productType = matchingProduct.productType;
            }

            return updatedAlertConfig;
        });
    }
}
