import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { availableAppLocale } from '../../../shared/i18n/i18n.config';
import { WINDOW_OBJECT } from '../../../shared/injectionTokens';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { setDefaultLocale } from '../../../utils/date';
import { DEFAULT_LOCALE } from '../../../utils/url';

@Component({
    selector: 'app-language-selector',
    standalone: true,
    imports: [MatSelect, MatOption, FormsModule],
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent {
    availableLanguages = availableAppLocale;
    selectedLanguage = 'fr';

    constructor(
        private analyticService: AnalyticsService,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        @Inject(WINDOW_OBJECT) private _window: Window,
    ) {
        this.selectedLanguage = this.translateService.currentLang || DEFAULT_LOCALE;
    }

    selectedLanguageChanged(lg: string): void {
        this.changeCurrentLang(lg);
        this._window.location.reload();
    }

    changeCurrentLang(locale: string) {
        this.analyticService.selectLanguage(locale);
        this.translateService.use(locale);
        setDefaultLocale(locale);
        this.localStorageService.setItem('locale', locale);
    }
}
