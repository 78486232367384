import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, first, mergeMap, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AlertResolutionActions } from '../../../alert-resolution/store/actions/alert-resolution';
import { AppState } from '../../../shared/store/app-state';
import { SetupGateway } from '../../components/gateways-selector/SetupGateway';
import { GatewaysActions } from '../actions/gateways';
import {
    SiteDevicesPageActions,
    SiteInstallationPageActions,
    SitePageActions,
    SitePageLifeCycleActions,
} from '../actions/site';
import { getDeviceGatewayId, getSelectedGatewayDevicesListItems } from '../selectors/gateways';
import { getSiteById } from '../selectors/site';

@Injectable()
export class GatewaysEffects {
    getGatewaysListItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitePageActions.installationTabOpened, SitePageActions.devicesTabOpened),
            switchMap(({ siteId }) => this.store.select(getSiteById(siteId)).pipe(first(Boolean))),
            switchMap(({ id, setupId }) => combineLatest([of(id), this.setupGateway.fetchGateways(setupId)])),
            map(([siteId, gateways]) => GatewaysActions.fetchGatewaysListItemsSucceeded({ siteId, gateways })),
        ),
    );

    loadGatewaysListItemsInAlertResolution$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertLoaded),
            switchMap(({ id_site }) => this.store.select(getSiteById(id_site)).pipe(first(Boolean))),
            switchMap(({ id, setupId }) => combineLatest([of(id), this.setupGateway.fetchGateways(setupId)])),
            map(([siteId, gateways]) => GatewaysActions.fetchGatewaysListItemsSucceeded({ siteId, gateways })),
        ),
    );

    selectGatewayWhenOpeningPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitePageActions.installationTabOpened, SitePageActions.devicesTabOpened),
            map(({ deviceId }) => deviceId),
            switchMap((deviceId) => this.store.select(getDeviceGatewayId(deviceId)).pipe(first(Boolean))),
            map((gatewayId) => SitePageLifeCycleActions.deviceGatewaySelected({ gatewayId })),
        ),
    );

    selectFirstGatewayDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SiteInstallationPageActions.gatewaySelected,
                SiteDevicesPageActions.gatewaySelected,
                SitePageLifeCycleActions.defaultGatewaySelected,
            ),
            mergeMap(() => this.store.select(getSelectedGatewayDevicesListItems).pipe(first())),
            map((gatewayDevices) => gatewayDevices?.[0]),
            map((firstDevice) => SitePageLifeCycleActions.gatewayDefaultDeviceSelected({ deviceId: firstDevice?.id })),
        ),
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        @Inject('SetupGateway') private setupGateway: SetupGateway,
    ) {}
}
