export type DeviceId = string;

export type DevicePropertyValue = string | string[] | number | number[] | boolean;

export interface DeviceProtocol {
    id: number;
    label: string;
    name: string;
    prefix: string;
    support?: string;
}

export interface StateMetadata {
    unit?: string;
    min?: number;
    max?: number;
    scaleFactor?: string;
    possibleValues?: string[] | number[];
    editable?: boolean;
    defaultValue?: DevicePropertyValue;
}

export interface DeviceState {
    name: string;
    value: DevicePropertyValue;
    type?: string;
    metadata?: StateMetadata;
}

export type DeviceCapability = 'remoteControllerManagement';

export interface DeviceMS {
    // -----only on Gateway Device -----------
    protocolsactivated?: DeviceProtocol[];
    protocolssupported?: DeviceProtocol[];
    protocolsavailable?: DeviceProtocol[];
    metadata?: any;
    // ---------------------------------------
    availability?: string;
    available?: boolean;
    capabilities?: DeviceCapability[];
    details?: { gatewayId: string };
    definition: string;
    categories: string[];
    commercial_name: string; // computed from "profile_io"
    id: DeviceId;
    site_id: string;
    states?: DeviceState[];
    subtype: any;
    type: any;
    uiType: any;
    attributes?: any;
    boxurl?: string;
    icon?: string;
    deviceurl?: string;
    name: string; // name given by enduser,
    product_profile?: string;
    protocol_profile?: string;
    activation_status?: string;
    activation_date?: string;
    vertical_definition?: string;
    type_id?: number;
    last_update_time?: number;
}

export enum ServegoDefect {
    UNAVAILABLE = 'UNAVAILABLE', // product is unavailable (core:statusstate ==== unavailable)
    PRIORITY_LOCK__WIND = 'PRIORITY_LOCK__WIND',
    PRIORITY_LOCK__LCS = 'PRIORITY_LOCK__LCS',
    PRIORITY_LOCK__SAAC = 'PRIORITY_LOCK__SAAC',
    PRIORITY_LOCK__SFC = 'PRIORITY_LOCK__SFC',
    PRIORITY_LOCK__UPS = 'PRIORITY_LOCK__UPS',
    PRIORITY_LOCK__MYSELF = 'PRIORITY_LOCK__MYSELF',
    PRIORITY_LOCK__RAIN = 'PRIORITY_LOCK__RAIN',
    PRIORITY_LOCK__SECURITY = 'PRIORITY_LOCK__SECURITY',
    PRIORITY_LOCK__TEMPERATURE = 'PRIORITY_LOCK__TEMPERATURE',
    PRIORITY_LOCK__TIMER = 'PRIORITY_LOCK__TIMER',
    PRIORITY_LOCK__USER = 'PRIORITY_LOCK__USER',
    PRIORITY_LOCK__EXTERNAL_ACCESS = 'PRIORITY_LOCK__EXTERNAL_ACCESS',
    PRIORITY_LOCK__EMERGENCY = 'PRIORITY_LOCK__EMERGENCY',
    PRIORITY_LOCK__LOCAL_USER = 'PRIORITY_LOCK__LOCAL_USER',
    PRIORITY_LOCK__DEAD_SENSOR = 'PRIORITY_LOCK__DEAD_SENSOR',
}

export enum PAIR_UNPAIR_CONTROLLER {
    PAIR = 'pair_controller',
    UNPAIR = 'unpair_controller',
}

export interface DeviceExecutionCommand {
    // intervention.sessionId
    sessionId: string;
    name: PAIR_UNPAIR_CONTROLLER;
    parameters: { name: string; value: any }[];
}

export enum FailureType {
    NO_FAILURE = 'NO_FAILURE',
    ACTUATORNOANSWER = 'ACTUATORNOANSWER',
    ACTUATOR_BUSY = 'ACTUATOR_BUSY',
    ERRORREADWRITEACCESS = 'ERRORREADWRITEACCESS',
    CMDDEPRECATED = 'CMDDEPRECATED',
    INCONSISTENT_ANSWER = 'INCONSISTENT_ANSWER',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    TIME_OUT_ON_QUEUED_COMMAND = 'TIME_OUT_ON_QUEUED_COMMAND',
}

export interface DevicePairingRequest {
    sessionId: string;
    devicesUrls: string[];
}
