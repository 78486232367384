import { Injectable } from '@angular/core';
import { AlertConfig, ProductType } from '../models/AlertsNotificationConfig';

@Injectable()
export class AlertsNotificationConfigService {
    updateAlertsConfigByGroup(alertsConfig: AlertConfig[], productType: ProductType, isActive: boolean): AlertConfig[] {
        return alertsConfig.map((alertConfig) =>
            alertConfig.productType === productType ? { ...alertConfig, isActive: isActive } : alertConfig,
        );
    }

    updateAlertConfig(alertsConfig: AlertConfig[], alertConfigName: string, isActive: boolean): AlertConfig[] {
        return alertsConfig.map((alertConfig) =>
            alertConfig.name === alertConfigName ? { ...alertConfig, isActive: isActive } : alertConfig,
        );
    }
}
