import { createActionGroup, props } from '@ngrx/store';
import { GatewayListItem } from '../../models/Gateway';
import { SiteId } from '../../models/Site';

export interface FetchGatewaysListSucceededEvent {
    siteId: SiteId;
    gateways: GatewayListItem[];
}

export const GatewaysActions = createActionGroup({
    source: 'Gateways',
    events: {
        fetchGatewaysListItemsSucceeded: props<FetchGatewaysListSucceededEvent>(),
    },
});
