import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ExternalLegalDocument } from '../../../admin/models/subscription';
import * as LegalDocumentActions from '../actions/legalDocument';

export interface LegalDocState {
    unsignedLegalDocument: ExternalLegalDocument[];
    signedLegalDocument: ExternalLegalDocument[];
}

export const LEGAL_DOCUMENT_INITIAL_STATE: LegalDocState = {
    unsignedLegalDocument: [],
    signedLegalDocument: [],
};

export default createReducer(
    LEGAL_DOCUMENT_INITIAL_STATE,

    on(
        LegalDocumentActions.setUnsignedDocument,
        (state, { docs }): LegalDocState => ({
            ...state,
            unsignedLegalDocument: docs,
        }),
    ),

    on(
        LegalDocumentActions.setSignedDocument,
        (state, { docs }): LegalDocState => ({
            ...state,
            signedLegalDocument: docs,
        }),
    ),
);

export const getLegalDocState = createFeatureSelector<LegalDocState>('legaldoc');
