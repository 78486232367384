import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EmailFrequency } from '../../models/AlertsNotificationConfig';

interface MailConfigApiEvent {
    operation: 'subscribe' | 'unsubscribe' | 'change frequency';
}

export const AlertsMailingConfigActions = createActionGroup({
    source: 'Alerts Mailing Config',
    events: {
        subscriptionEnabled: emptyProps(),
        subscriptionDisabled: emptyProps(),
        frequencySelected: props<{ frequency: EmailFrequency }>(),
        apiFailed: props<MailConfigApiEvent>(),
        apiSucceed: props<MailConfigApiEvent>(),
    },
});
