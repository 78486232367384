@if (data$ | async; as vm) {
  <div class="favorite-sites-header">
    <h2>{{ 'FAVORITES' | translate }}</h2>
    <span class="total-favorites">{{ vm.favoriteSites.length }} {{ 'TOTAL_FAVORITES' | translate }}</span>
  </div>

  @if (vm.favoriteSites.length > 0) {
    <div class="favorite-sites-list">
      @for (favoriteSite of vm.favoriteSites; track favoriteSite.siteId) {
        <app-favorite-item [favoriteSite]="favoriteSite" />
      }
    </div>
  } @else if (!vm.loading) {
    <div class="empty-favorites">
      <p>{{ 'FAVORITES_DESCRIPTION_1' | translate }}</p>
      <p>{{ 'FAVORITES_DESCRIPTION_2' | translate }}</p>
    </div>
  }
}
