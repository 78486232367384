import { Pipe, PipeTransform } from '@angular/core';
import formatValue from '../../site-detail/components/device-detail/viewer-builder/formatValue';
import { Formatter } from '../models/Formatter';

@Pipe({
    name: 'format',
    standalone: true,
})
export class FormatPipe implements PipeTransform {
    transform(value: number | string | boolean | Date, formatter: Formatter): string {
        return formatValue(value, formatter);
    }
}
