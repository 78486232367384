<div if="id" class="device-refresh-state-failed-item invalid" (click)="onClick()">
    <div class="device-upate-item-header">
        <div class="site-device-failed-item-header-icon">
            <img src="assets/img/alerts/alert.svg"/>
        </div>
        <div class="device-refresh-state-failed-item-header-content">
            <div class="device-name">{{ content.errorType | translate }}</div>
            <div class="device-name">{{ content.deviceName }}</div>
            <div class="user-name">{{ content.userSiteName }}</div>
        </div>
        <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
    </div>
    <div class="site-device-error-item-content">
        <div class="site-device-error-item-content-message" [innerHTML]="translatedMessage"></div>
    </div>
</div>
