import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getRemainingTime } from '../../../utils/date';

@Component({
    selector: 'app-remaining-time',
    template: '{{ remainingTime }}',
    standalone: true,
    imports: [TranslateModule],
})
export class RemainingTimeComponent implements OnInit {
    @Input() date: number;

    remainingTime = '';

    constructor(
        private translateService: TranslateService,
        private ref: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.formatRemainingTime();

        setInterval(() => this.formatRemainingTime(), 1000);
    }

    private formatRemainingTime() {
        const computedRemainingTime = getRemainingTime(this.date);

        this.remainingTime = computedRemainingTime
            ? this.translateService.instant('TOASTER_ACCESS_GRANTED_REMAINING_TIME', computedRemainingTime)
            : '';
        this.ref.markForCheck();
    }
}
