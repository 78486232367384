import type { Owner } from '../models/Site';

export const USER_FIELD_NOT_SET = 'not set';

export const getOwnerAddress = (owner: Owner) => {
    if (
        !owner ||
        !owner.adresse_1 ||
        !owner.city ||
        !owner.zipcode ||
        owner.adresse_1 === USER_FIELD_NOT_SET ||
        owner.city === USER_FIELD_NOT_SET ||
        owner.zipcode === USER_FIELD_NOT_SET
    ) {
        return null;
    }

    const address = [owner.adresse_1];
    if (owner.adresse_2 && owner.adresse_2 !== USER_FIELD_NOT_SET) {
        address.push(owner.adresse_2);
    }
    address.push(owner.zipcode + ' ' + owner.city);
    return address.join('\n');
};

export const getOwnerPhone = (owner: Owner) => {
    if (!owner || owner.tel === USER_FIELD_NOT_SET) {
        return null;
    }

    return owner.tel;
};

export function getUserSiteName(owner?: Owner) {
    return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown';
}
