import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { StateEditionModalComponent } from '../../../../site-detail/components/dummies-components/device/modals/state-edition-modal/state-edition-modal.component';

interface ModalConfirmDeleteData {
    warningMessage: string;
    confirmId: string;
}

@Component({
    selector: 'app-modal-confirm-delete',
    templateUrl: './modal-confirm-delete.component.html',
    styleUrls: ['./modal-confirm-delete.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule],
})
export class ModalConfirmDeleteComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalConfirmDeleteData,
        private dialogRef: MatDialogRef<StateEditionModalComponent>,
    ) {}

    confirmDelete() {
        this.sendChoice(true);
    }

    cancelDelete() {
        this.sendChoice(false);
    }

    sendChoice(confirm: boolean) {
        this.dialogRef.close(confirm);
    }
}
