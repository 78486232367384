import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { CheckedIntervention } from './CheckedIntervention';
import { HttpService } from './http.service';

export interface RestrictedCrmUser {
    crmName?: string;
}

@Injectable()
export class QueryService {
    constructor(private httpService: HttpService) {}

    public checkInterventionHash(hash: string): Observable<CheckedIntervention> {
        return this.httpService.get(`/intervention/hash/${hash}`);
    }

    public getInstallerInfo(installerId: string): Observable<RestrictedCrmUser> {
        return this.httpService.get(`/user/installer/${installerId}`);
    }
}
