import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[appPadZero][formControlName],[appPadZero][formControl],[appPadZero][ngModel]',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PadZeroDirective),
            multi: true,
        },
    ],
})
export class PadZeroDirective implements ControlValueAccessor {
    @Input() padSize = 2;
    private _onChange: (_: string) => void;
    private _onTouched: (_: string) => void;

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
    ) {}

    writeValue(value: number): void {
        const inputValue = this.transform(value);
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', inputValue);
    }

    registerOnChange(fn: (value: number) => void): void {
        this._onChange = (value) => {
            const cleanInputValue = this.transform(this.parse(value)).slice(0, this.padSize);
            const parsedValue = this.parse(cleanInputValue);
            fn(parsedValue);
            this._renderer.setProperty(this._elementRef.nativeElement, 'value', cleanInputValue);
        };
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string): void {
        this._onChange(value);
    }

    private transform(value: number): string {
        if (value < 0) {
            return '00';
        }
        return String(value).padStart(this.padSize, '0');
    }

    private parse(value: string): number {
        return parseInt(value, 10);
    }
}
