import { createActionGroup, props } from '@ngrx/store';
import { AlertId } from '../../../alerts/models/Alert';
import { EditedProperty, UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';
import { DeviceId, DeviceMS, DevicePropertyValue } from '../../models/Device';
import { Device } from '../../models/DeviceDetail';
import { Execution } from '../../models/Execution';
import { GatewayId } from '../../models/Gateway';
import { SiteId } from '../../models/Site';
import { UiProfile } from '../../models/UiProfile';

export interface RefreshDeviceSectionCommand {
    device: DeviceMS;
    sectionId: string;
}

export type DeviceLoadedEvent = { device: Device; uiProfile?: UiProfile };

export const DeviceActions = createActionGroup({
    source: 'Device',
    events: {
        allDevicesSynchronized: props<{ siteId: SiteId }>(),
        deviceSelected: props<{ deviceId: DeviceId }>(),
        gatewaySelected: props<{ gatewayId: GatewayId }>(),
        deviceApiRequestSuccess: props<{ device: Device }>(),
        deviceNotFound: props<{ deviceId: DeviceId }>(),
        deviceLoaded: props<DeviceLoadedEvent>(),
        alertResolutionRequested: props<{
            alertId: AlertId;
        }>(),
        alertResolutionPageRequested: props<{
            alertId: AlertId;
        }>(),
        deviceUpdateRequested: props<{
            deviceId: DeviceId;
            property: EditedProperty;
            initialValue: DevicePropertyValue;
            newValue: DevicePropertyValue;
        }>(),
        deviceUpdateStarted: props<{
            command: UpdateDeviceCommand;
            pendingTask: Execution;
        }>(),
        deviceUpdateFailed: props<UpdateDeviceCommand>(),
        deviceUpdateCompleted: props<{
            deviceId;
            stateId: string;
            value: any;
        }>(),
        deviceSectionRefreshRequested: props<{
            deviceId: DeviceId;
            sectionId: string;
        }>(),
        deviceSectionRefreshStarted: props<{
            command: RefreshDeviceSectionCommand;
            pendingTasks: Execution[];
        }>(),
        deviceSectionRefreshFailed: props<RefreshDeviceSectionCommand>(),
    },
});
