import { createSelector } from '@ngrx/store';
import { AdminState, getAdminState } from '../reducers/admin';

export const getUserAccessListState = createSelector(getAdminState, (state: AdminState) => state?.usersAccess?.list);

export const getInstallerCMSAssociationsState = createSelector(
    getAdminState,
    (state: AdminState) => state?.installers?.cmsAssociations,
);

export const getCurrentUserState = createSelector(getAdminState, (state: AdminState) => state?.currentUser);

export const getCurrentUserPartner = createSelector(getAdminState, (state: AdminState) => state?.currentUser?.partner);

export const getLegalDocServiceId = createSelector(getAdminState, (state: AdminState) => state?.legalDocsServiceId);

export const getLegalDocuments = createSelector(getAdminState, (state: AdminState) => state?.legalDocsDocuments);

export const getLegalDocumentVerions = createSelector(getAdminState, (state: AdminState) => state?.legalDocsVersions);

export const getSelectedInstaller = createSelector(getAdminState, (state: AdminState) => state?.selectedInstaller);

export const getSelectedInstallerSignature = createSelector(
    getAdminState,
    (state: AdminState) => state?.selectedInstallerSignature,
);
