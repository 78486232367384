import { NgClass } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Inject,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, first } from 'rxjs';
import { ConstructorOf } from '../../../../../../shared/common.types';
import { AppState } from '../../../../../../shared/store/app-state';
import { DeviceId } from '../../../../../models/Device';
import { PropertyId } from '../../../../../models/DeviceDetail';
import { getDeviceEditor, getPropertyInfos } from '../../../../../store/selectors/device-detail';
import { EditedProperty } from '../../../../device-detail/DeviceGateway';
import { InputHelper, SectionItemEditor } from '../../../../device-detail/editor-builder/buildEditor';
import { DateEditorComponent } from '../../state-editors/date-editor/date-editor.component';
import { DurationEditorComponent } from '../../state-editors/duration-editor/duration-editor.component';
import { NamedValueEditorComponent } from '../../state-editors/named-value-editor/named-value-editor.component';
import { NumberEditorComponent } from '../../state-editors/number-editor/number-editor.component';
import { SliderEditorComponent } from '../../state-editors/slider-editor/slider-editor.component';
import { StateEditorComponent } from '../../state-editors/StateEditorComponent';
import { TextEditorComponent } from '../../state-editors/text-state-editor/text-editor.component';
import { EditableStateData } from '../../StateData';

export interface StateEditionModalData {
    deviceId: DeviceId;
    propertyId: PropertyId;
}

export interface InputHelperArguments {
    max?: string;
}

@Component({
    standalone: true,
    selector: 'app-modal-date',
    templateUrl: './state-edition-modal.component.html',
    styleUrls: ['./state-edition-modal.component.scss'],
    imports: [MatDialogModule, TranslateModule, MatButtonModule, TextEditorComponent, NgClass],
})
export class StateEditionModalComponent implements AfterViewInit {
    @ViewChild('editor', { read: ViewContainerRef })
    editorContainer: ViewContainerRef;
    deviceId: DeviceId;
    propertyId: PropertyId;
    componentRef: ComponentRef<StateEditorComponent<unknown, unknown>>;
    initialValue: EditableStateData;
    editedValue: EditableStateData;
    editedProperty: EditedProperty;
    editorType: string;
    newValueDetected = false;
    inputHelper: InputHelper;
    warningMessage: string;

    private componentMap: {
        [key: string]: ConstructorOf<StateEditorComponent<unknown, unknown>>;
    } = {
        text: TextEditorComponent,
        slider: SliderEditorComponent,
        number: NumberEditorComponent,
        duration: DurationEditorComponent,
        date: DateEditorComponent,
        namedValue: NamedValueEditorComponent,
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: StateEditionModalData,
        private dialogRef: MatDialogRef<StateEditionModalComponent>,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef,
    ) {
        this.deviceId = this.data.deviceId;
        this.propertyId = this.data.propertyId;
    }

    ngAfterViewInit() {
        combineLatest([
            this.store.select(getPropertyInfos(this.deviceId, this.propertyId)),
            this.store.select(getDeviceEditor(this.deviceId, this.propertyId)),
        ])
            .pipe(first())
            .subscribe(([property, editorVm]) => {
                this.editedProperty = property;

                this.initialValue = editorVm.value;
                this.editorType = editorVm.type;
                this.inputHelper = editorVm.inputHelper;
                this.warningMessage = editorVm.warningMessage;

                this.componentRef = this.buildEditorElt(editorVm);
                this.ref.detectChanges();
            });
    }

    private buildEditorElt(editorVm: SectionItemEditor) {
        const editorRef = this.editorContainer.createComponent(this.componentMap[editorVm.type]);
        editorRef.instance.value = editorVm.value;
        editorRef.instance.config = editorVm.config;

        editorRef.instance.valueChange.subscribe((value: EditableStateData) => {
            this.newValueDetected = this.isNewValueDetected(value, editorRef);
            if (this.newValueDetected) {
                this.editedValue = value;
            }
        });
        return editorRef;
    }

    private isNewValueDetected(
        value: number | string,
        editorRef: ComponentRef<StateEditorComponent<unknown, unknown>>,
    ) {
        return value !== this.initialValue && value !== '' && editorRef.instance.isValid();
    }

    saveState() {
        this.dialogRef.close({
            deviceId: this.deviceId,
            property: this.editedProperty,
            newValue: this.editedValue,
            initialValue: this.initialValue,
        });
    }

    cancelState() {
        this.dialogRef.close(null);
    }

    isValueValid(): boolean {
        return this.componentRef?.instance.isValid() ?? true;
    }
}
