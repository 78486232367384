import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { TextData } from '../../StateData';
import { StateEditorComponent } from '../StateEditorComponent';

export interface TextEditorConfig {
    max?: number;
}

@Component({
    selector: 'app-text-state-editor',
    standalone: true,
    imports: [MatInputModule, ReactiveFormsModule, TranslateModule, FormsModule, MatDialogModule],
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit, StateEditorComponent<TextData, TextEditorConfig> {
    @Input({ required: true }) value: TextData;
    @Input() config: TextEditorConfig;
    @Output() valueChange = new EventEmitter<TextData>();
    @HostBinding('class.invalid') isInvalid = false;

    editedValue: string;

    ngOnInit() {
        this.editedValue = this.value;
    }

    onUserInput() {
        this.isInvalid = !this.isValid();
        this.valueChange.emit(this.editedValue);
    }

    hasOnlyAsciiCharacters(): boolean {
        const asciiPattern = /^[\x00-\x7F]*$/;
        return asciiPattern.test(this.editedValue);
    }

    isValid() {
        return this.hasOnlyAsciiCharacters();
    }
}
