@if (id && content) {
  <div class="toaster-mail-received" id="popupMail" (click)="onToasterClick()">
    <header>
      <div class="icon">
        <img src="assets/img/message.svg"/>
      </div>
      <div class="content">
        <div class="title">{{ content.title | translate }}</div>
      </div>
      <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
    </header>
    <div class="content">
      <div class="message">{{ messageToDisplay | translate }}</div>
    </div>
  </div>
}
