import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminHttpService } from '../../admin/services/http.service';
import { HttpService } from '../../shared/services/http.service';
import { MessageMS, MessagesCreateRequest, MessagesInstallerUpdateRequest } from '../models/Message';

@Injectable()
export class MessageService {
    constructor(
        private httpService: HttpService,
        private adminHttpService: AdminHttpService,
    ) {}

    getMessageForInstaller(): Observable<MessageMS[]> {
        return this.httpService.get('/message_installer');
    }

    updateInstallerMessage(messageUpdateRequest: MessagesInstallerUpdateRequest): Observable<any> {
        return this.httpService.patch('/message_installer', messageUpdateRequest);
    }

    deleteInstallerMessage(messageId: string): Observable<any> {
        return this.httpService.delete(`/message_installer/messages/${messageId}`);
    }

    getAdminMessages(): Observable<any> {
        return this.adminHttpService.get('/message', { prefix: false });
    }

    sendAdminMessage(message: MessagesCreateRequest): Observable<string> {
        return this.adminHttpService.post('/message', message, {
            prefix: false,
        });
    }

    deleteAdminMessage(id_message: string) {
        return this.adminHttpService.delete(`/message/${id_message}`, {
            prefix: false,
        });
    }
}
