import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DEFAULT_LANGUAGE } from './i18n.config';

export class TranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        public prefix: string = '/assets/i18n/',
        public suffix: string = '.json',
    ) {}

    public getTranslation(lang: string): Observable<object> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
            catchError((): Observable<object> => {
                return this.http.get(`${this.prefix}${DEFAULT_LANGUAGE}${this.suffix}`);
            }),
        );
    }
}
