import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OnBoardingReponse } from '../../site-detail/models/Site';
import { SiteService } from '../../site-detail/services/site.service';
import constants from '../const';
import { LocalStorageService } from '../services/localStorage.service';

@Injectable({
    providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
    constructor(
        private router: Router,
        private siteService: SiteService,
        private localStorageService: LocalStorageService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // here we check if user has set some attribut to tell "hey, i dont want to see onboarding again"
        return new Promise((resolve, reject) => {
            this.siteService.getSiteConnection().subscribe((res: OnBoardingReponse) => {
                const bypassOnboarding = this.localStorageService.getItem(constants.LOCAL_STORAGE_BYPASS_ONBOARDING);

                // "do not show this message again" was not check, but user was allowed until logout or app reboot
                if (res.onboarding === false && bypassOnboarding !== true) {
                    this.router.navigate(['/onboarding']);
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        });
    }
}
