import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { MaintenancePeriod } from '../../../admin/components/maintenance-period/model/maintenance-period';
import { setCurrentMaintenancePeriod } from '../actions/maintenancePeriods';

export const MAINTENANCE_PERIOD_INITIAL_STATE: MaintenancePeriod = null;

export default createReducer(
    MAINTENANCE_PERIOD_INITIAL_STATE,

    on(setCurrentMaintenancePeriod, (state, { maintenancePeriod }): MaintenancePeriod | null => {
        return maintenancePeriod;
    }),
);

export const getMaintenancePeriodState = createFeatureSelector<MaintenancePeriod>('maintenance-period');
