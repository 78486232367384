import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, Observable, switchMap } from 'rxjs';
import { delay, filter, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { UISocketService } from '../../../shared/services/ui.socket.service';
import { AppState } from '../../../shared/store/app-state';
import { UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';

import { ExecutionStatus } from '../../models/Execution';
import { BoxActions } from '../actions/box-detail';
import { DeviceActions } from '../actions/device-detail';
import {
    addDeviceSectionToSync,
    addDeviceStateToSync,
    removeDeviceStateToSync,
    updateDeviceStateToSyncExecutionStatus,
} from '../actions/ui';
import { getRawDevice } from '../selectors/device-detail';
import { getDeviceUpdateTask } from '../selectors/ui';

@Injectable()
export class TasksEffects {
    initTaskWhenRefreshSectionHasStarted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceSectionRefreshStarted),
            map((sectionRefreshStartedEvent) => {
                const device = sectionRefreshStartedEvent.command.device;
                const tasksIds = sectionRefreshStartedEvent.pendingTasks.map((task) => task.job_id);

                this.uISocketService.setExecutions(tasksIds);
                return addDeviceSectionToSync({
                    siteId: device.site_id,
                    deviceId: device.id,
                    sectionTitle: sectionRefreshStartedEvent.command.sectionId,
                    executions: sectionRefreshStartedEvent.pendingTasks,
                });
            }),
        ),
    );

    initTrackingForDeviceUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateRequested),
            mergeMap((updateRequest) =>
                this.storeService.select(getRawDevice(updateRequest.deviceId)).pipe(
                    map((device) =>
                        addDeviceStateToSync({
                            siteId: device.site_id,
                            deviceId: device.id,
                            state: {
                                stateLabel: updateRequest.property.label,
                                oldValue: updateRequest.initialValue,
                                newValue: updateRequest.newValue,
                                stateName: updateRequest.property.id,
                            },
                        }),
                    ),
                ),
            ),
        );
    });

    updateTaskWhenUpdateStateHasStarted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateStarted),
            map(({ pendingTask: execution, command: { device } }) => {
                this.uISocketService.setExecutions([execution.job_id]);
                return updateDeviceStateToSyncExecutionStatus({ siteId: device.site_id, execution });
            }),
        ),
    );

    updateDeviceStateUpdateTaskWhenFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateFailed),
            this.waitForDeviceUpdateTask(),
            map((updateCommand) =>
                updateDeviceStateToSyncExecutionStatus({
                    siteId: updateCommand.device.site_id,
                    execution: {
                        deviceId: updateCommand.device.id,
                        state: updateCommand.property.id,
                        status: ExecutionStatus.FAILED,
                        job_id: '',
                    },
                }),
            ),
        ),
    );

    removeUpdateStateTaskWhenUpdateCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateCompleted),
            switchMap((updateData) =>
                this.store.select(getDeviceUpdateTask(updateData.deviceId, updateData.stateId)).pipe(first(Boolean)),
            ),
            delay(60 * 1000),
            map((updateTask) => removeDeviceStateToSync({ jobId: updateTask.job_id })),
        ),
    );

    initTrackingForRebootBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootRequested),
            switchMap(({ deviceId }) => this.storeService.select(getRawDevice(deviceId))),
            map(({ id: deviceId, site_id: siteId }) =>
                addDeviceStateToSync({
                    siteId,
                    deviceId,
                    state: { stateLabel: 'REBOOT_BOX', stateName: 'box_status' },
                }),
            ),
        ),
    );

    updateTaskWhenRebootBoxHasStarted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootStarted),
            tap(({ pendingTask }) => this.uISocketService.setExecutions([pendingTask.job_id])),
            map(({ command, pendingTask }) =>
                updateDeviceStateToSyncExecutionStatus({
                    siteId: command.device.site_id,
                    execution: {
                        state: 'box_status',
                        job_id: pendingTask.job_id,
                        deviceId: command.device.id,
                        status: ExecutionStatus.INITIALIZED,
                    },
                }),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private storeService: StoreService<AppState>,
        private uISocketService: UISocketService,
    ) {}

    private waitForDeviceUpdateTask() {
        return (source: Observable<UpdateDeviceCommand>) =>
            source.pipe(
                mergeMap((updateCommand) =>
                    this.store.select(getDeviceUpdateTask(updateCommand.device.id, updateCommand.property.id)).pipe(
                        map((task) => (task ? updateCommand : null)),
                        filter((updateCommand) => updateCommand !== null),
                        first(),
                        timeout(1000),
                    ),
                ),
            );
    }
}
