import { createSelector } from '@ngrx/store';
import { getContactPageUrl } from '../../../utils/url';
import { getUserEnvState, UserEnvState } from '../reducers/user-env';
import { getUserCountry } from './login';

export const getUserEnvLoadingState = createSelector(getUserEnvState, (state: UserEnvState) => state?.loading);
export const getUserLanguage = createSelector(getUserEnvState, (state: UserEnvState) => state?.language);

export const getUserContactPage = createSelector(getUserCountry, (country) => {
    if (!country) {
        return null;
    }

    return getContactPageUrl(country);
});
