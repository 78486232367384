import { createAction, props } from '@ngrx/store';
import { MaintenancePeriod } from '../../../admin/components/maintenance-period/model/maintenance-period';

export class MaintenancePeriodsActions {
    static readonly SET_CURRENT_MAINTENANCE_PERIOD = 'SET_CURRENT_MAINTENANCE_PERIOD';
}

export const setCurrentMaintenancePeriod = createAction(
    MaintenancePeriodsActions.SET_CURRENT_MAINTENANCE_PERIOD,
    props<{ maintenancePeriod: MaintenancePeriod }>(),
);
