import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';
import { SiteId } from '../../site-detail/models/Site';
import { FavoriteSitesGateway } from './FavoriteSitesGateway';

@Injectable()
export class HttpFavoriteSitesGateway implements FavoriteSitesGateway {
    constructor(private httpService: HttpService) {}

    addFavorite(siteId: SiteId): Observable<any> {
        return this.httpService.post(`/site/${siteId}/favorite`, {});
    }

    removeFavorite(siteId: SiteId): Observable<any> {
        return this.httpService.delete(`/site/${siteId}/favorite`);
    }
}
