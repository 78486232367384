<div if="id" class="mail-sent-item" id="popupMail">
    <div class="mail-sent-item-header">
        <div class="mail-sent-item-header-icon">
            <img src="assets/img/message.svg"/>
        </div>
        <div class="mail-sent-item-header-content">
            <div class="title">{{ 'TOASTER_MAIL_SENT_TITLE' | translate }}</div>
            <div class="user-name">{{ translatedUsername }}</div>
        </div>
    </div>
    <div class="mail-sent-item-content">
        <div class="mail-sent-item-content-message">{{ content.message | translate }}</div>
    </div>
</div>
