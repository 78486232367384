import { createAction, props } from '@ngrx/store';
import { UIActions } from '../../../site-detail/store/actions/ui';
import { ToasterMessage } from '../../models/Toaster';

export const addToasterMessage = createAction(
    UIActions.ADD_TOASTER_MESSAGE,
    props<{
        message: ToasterMessage;
        duration?: number;
    }>(),
);
