<div if="id" [className]="content.isUpdateValid ? 'device-update-item valid' : 'device-update-item invalid'"
     (click)="onClick()">
  <div class="device-upate-item-header">
    <div class="device-update-item-header-icon">
      @if (content.isUpdateValid) {
        <img src="assets/img/update-parameter.svg"/>
      } @else {
        <img src="assets/img/alerts/alert.svg"/>
      }
    </div>
    <div class="device-update-item-header-content">
      <div class="device-name">{{ content.deviceName }}</div>
      <div class="user-name">{{ content.userSiteName }}</div>
    </div>
  </div>
  <div class="device-upate-item-content">
    <div class="device-upate-item-content-message" [innerHTML]="translatedMessage"></div>
  </div>
</div>
