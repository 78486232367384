import { Component } from '@angular/core';
import { LoaderComponent } from '../../../shared/loader/loader.component';

@Component({
    selector: 'app-authentication-component',
    template: '<app-loader [visible]="true"></app-loader>',
    standalone: true,
    imports: [LoaderComponent],
})
export class AuthenticationComponent {}
