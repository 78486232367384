<div class="onboarding-wrapper">
    <app-loader [visible]="loading"></app-loader>
    <header>
        <app-somfypro-logo [url]="somfyProUrl" [variant]="SomfyproLogoComponentVariant.SIZE_BIG"></app-somfypro-logo>
        <div class="text">
            <h1>{{ 'ONBOARDING_HEADER_TITLE' | translate}}</h1>
            <p>{{ 'ONBOARDING_HEADER_TEXT' | translate}}</p>
        </div>
        <img src="assets/img/onboarding_header.png" />
    </header>
    <main>
        <p>{{ 'ONBOARDING_MAIN_TEXT_1' | translate}}</p>
        <img class="serv-e-go-logo" src="assets/img/servego_yellow.svg">
        <p>{{ 'ONBOARDING_MAIN_TEXT_2' | translate}}</p>
        <img class="screenshot" src="assets/img/onboarding_sites_list.jpg"/>
        <div class="form">
            <input type="checkbox"
                   [(ngModel)]="noOnboardingNextTime"
                   name="onboarding"
                   id="onboarding"
            /> <label for="onboarding">{{ 'DO_NOT_SHOW_ONBOARDING_NEXT_TIME' | translate }}</label>
        </div>
        <button id="btn-validate" (click)="onButtonClick()">{{ 'ONBOARDING_MAIN_BUTTON' | translate}}</button>
        <p class="copyright">&copy; 2019 - {{ currentYear() }} {{ 'ONBOARDING_LEGAL_NOTICE_BOTTOM' | translate }}</p>
    </main>
</div>
