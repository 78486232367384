export enum SectionExecutionStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export interface SectionExecutionInfo {
    section: string;
    pendingRefresh: number;
    status: SectionExecutionStatus;
}
