import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';
import {
    loadSiteInfo,
    loadSiteOwner,
    removeSite,
    waitForServegoApproval,
} from '../../../site-detail/store/actions/site';
import { FavoriteSitesActions } from '../../../site-favorite/store/actions/favorite-site';
import { SitesActions } from '../actions/sites';

export interface SitesState {
    sites: Record<SiteId, Site>;
}

export const SITES_INITIAL_STATE: SitesState = {
    sites: {},
};

export default createReducer(
    SITES_INITIAL_STATE,

    on(SitesActions.sitesLoaded, FavoriteSitesActions.favoritesLoaded, (state, { sites }): SitesState => {
        if (!(sites instanceof Array)) {
            return state;
        }

        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        const sitesRecord = sites.reduce((updatedSitesRecord, siteItem: Site) => {
            const newSite = { ...siteItem };

            updatedSitesRecord[newSite.id] = newSite;

            return updatedSitesRecord;
        }, sitesRecordCopy);

        return { ...state, sites: sitesRecord };
    }),

    on(loadSiteInfo, (state, { site }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };
        const existingSite = sitesRecordCopy[site.id];
        const newSite = existingSite ? { ...existingSite, ...site } : site;

        sitesRecordCopy[newSite.id] = newSite;

        return { ...state, sites: sitesRecordCopy };
    }),

    on(FavoriteSitesActions.favoriteAdded, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], favorite: true };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(FavoriteSitesActions.favoriteRemoved, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], favorite: false };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(waitForServegoApproval, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], isServegoAsking: true };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(loadSiteOwner, (state, { siteId, owner }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId] || !owner) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], owner };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(removeSite, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        delete sitesRecordCopy[siteId];

        return { ...state, sites: sitesRecordCopy };
    }),
);

export const getSitesState = createFeatureSelector<SitesState>('sites');
