import { DurationEditorSpec } from '../../../../models/Editor';
import { UiProfileProperty } from '../../../../models/UiProfile';
import formatValue from '../../viewer-builder/formatValue';
import { SectionItemEditor } from '../buildEditor';

export function buildDurationEditor(uiProperty: UiProfileProperty, value: number): SectionItemEditor {
    const editor = uiProperty.editor as DurationEditorSpec;

    return {
        type: 'duration',
        value,
        inputHelper: {
            key: 'WARNING_TIMER',
            arguments: {
                max: formatValue(editor.max, uiProperty.formatter),
            },
        },
        config: {
            precision: editor.precision,
            max: editor.max,
        },
    };
}
