<div class="burger" id="toggle-burger-menu" (click)="toggleRightMenu()" [ngClass]="{'open': rightMenuOpen}">
  <img src="assets/img/cog_grey.svg" width="20" alt="menu">
</div>
<aside class="right-menu-wrapper">
  <div class="right-menu-backdrop" [ngClass]="{'open': rightMenuOpen}" (click)="closeMenu()"></div>
  <div class="right-menu" [ngClass]="{'open': rightMenuOpen}">
    @if (userLogin) {
      <h4>
        {{ userLogin.crmName }}
      </h4>
    }
    <ul class="right-menu-list">
      @if (userLogin?.accountType === 'account') {
        <li class="right-menu-entry">
            <span class="right-menu-link"
                  (click)="onConfigAlertClick()">{{ 'ALERT_CONFIGURATION' |translate }}</span>
        </li>
      }
      <li class="right-menu-entry">
          <span class="right-menu-link" id="showSignedLegalDocModal"
                (click)="showSignedLegalDocModal()">{{ 'SIGNED_LEGAL_DOCUMENTS_MODAL_TITLE' | translate }}</span>
      </li>
      <li class="right-menu-entry">
          <span class="right-menu-link" id="goToDocumentationCenter"
                (click)="redirectToUserGuide()"> {{ 'DOCUMENTATION_CENTER_MENU_LINK' | translate }}</span>
      </li>
      <li class="right-menu-entry">
          <span class="right-menu-link" id="goToLegalNotice"
                (click)="redirectToLegalNotice()"> {{ 'LEGAL_NOTICE_MENU_LINK' | translate }}</span>
      </li>
      <li class="right-menu-entry">
          <span class="right-menu-link" id="logout-btn"
                (click)="logout()">{{ 'LOGOUT' | translate }}</span>
      </li>
      <li class="right-menu-entry separator">
        <div></div>
      </li>
      <li class="right-menu-entry">
        {{ 'COUNTRY' | translate }} > {{ 'LANGUAGE_LABEL' | translate }}
        <div class="languages">
          <app-language-selector/>
        </div>
      </li>
      <li class="right-menu-entry version-number">version: {{ version }}</li>
    </ul>
  </div>
</aside>
