import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, pairwise, switchMap, take, tap } from 'rxjs/operators';
import { getAlertById } from '../../../alerts/store/selectors/alerts';
import { AppState } from '../../../shared/store/app-state';
import { AlertResolutionActions } from '../actions/alert-resolution';
import { getAlertResolutionAlertId, getAlertResolutionCurrentStepNumber } from '../selectors/alert-resolution';

const resolutionAlertUrlPattern = /\/resolve-alert\/([0-9a-fA-F-]+)\/(\d+)$/;

function extractAlertResolutionRouteParams(url: string) {
    const match = url.match(resolutionAlertUrlPattern);
    return match ? { alertId: match[1], stepNumber: match[2] } : null;
}

@Injectable()
export class AlertResolutionNavigationEffects {
    redirectIfBadStep$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertResolutionActions.alertResolutionPathApiSucceeded),
                switchMap(({ resolutionPath }) =>
                    combineLatest([
                        of(resolutionPath.length),
                        this.store.select(getAlertResolutionAlertId),
                        this.store.select(getAlertResolutionCurrentStepNumber),
                    ]),
                ),
                debounceTime(100),
                tap(([numberOfSteps, alertId, stepNumber]) => {
                    const validStepNumber = Math.min(Math.max(stepNumber, 1), numberOfSteps);
                    if (alertId && stepNumber != null && validStepNumber !== stepNumber) {
                        this.router.navigate(['/resolve-alert', alertId, validStepNumber]);
                    }
                }),
            ),
        { dispatch: false },
    );

    emitAlertResolutionOpenedOnUrlEnter$ = createEffect(() =>
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event: NavigationEnd) => extractAlertResolutionRouteParams(event.urlAfterRedirects)),
            distinctUntilChanged((prevParams, curParams) => curParams?.alertId === prevParams?.alertId),
            filter(Boolean),
            map(({ alertId }) => AlertResolutionActions.alertResolutionPageOpened({ alertId })),
        ),
    );

    redirectToAlertPageIfDeviceNotFound$ = createEffect(
        () =>
            this.router.events.pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd && resolutionAlertUrlPattern.test(event.urlAfterRedirects),
                ),
                switchMap(() => this.actions$.pipe(ofType(AlertResolutionActions.deviceNotFound), take(1))),
                delay(5000),
                tap(() => this.router.navigate(['/alerts'])),
            ),
        { dispatch: false },
    );

    emitAlertResolutionClosedOnUrlExit$ = createEffect(() =>
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            pairwise(),
            filter(
                ([prev, curr]: [NavigationEnd, NavigationEnd]) =>
                    resolutionAlertUrlPattern.test(prev.urlAfterRedirects) &&
                    !resolutionAlertUrlPattern.test(curr.urlAfterRedirects),
            ),
            map(([prev]) => extractAlertResolutionRouteParams(prev.urlAfterRedirects)),
            map(({ alertId }) => AlertResolutionActions.alertResolutionClosed({ alertId })),
        ),
    );

    redirectToDeviceAfterResolution$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertResolutionActions.alertResolutionRequested),
                switchMap(({ alertId }) => this.store.select(getAlertById(alertId))),
                tap(({ id_site, id_device }) =>
                    this.router.navigate(['/site', id_site], { queryParams: { idDevice: id_device, tabIndex: 1 } }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
    ) {}
}
