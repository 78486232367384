<div class="seg-modal">
    <div class="seg-modal-header">
        <h4 mat-dialog-title class="seg-modal-title">
            {{ data.title | translate }}
        </h4>
    </div>
    <div>
        <mat-dialog-content class="seg-modal-body">
            <span class="seg-text">{{
                data.message | translate
            }}</span>
        </mat-dialog-content>
        <mat-dialog-actions class="seg-flex-justify-space-around">
            <button
                id="btn-validate"
                class="seg-btn seg-bg-color-primary"
                mat-button
                mat-dialog-close
                (click)="onConfirm()"
            >
                {{ 'SAVE' | translate }}
            </button>
            <button
                id="btn-cancel"
                class="seg-btn seg-bg-color-secondary"
                mat-button
                mat-dialog-close
            >
                {{ 'CANCEL' | translate }}
            </button>
        </mat-dialog-actions>
    </div>
</div>
