import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UISocketService } from '../../../shared/services/ui.socket.service';
import { AppState } from '../../../shared/store/app-state';
import { DeviceExecutionCommand, DeviceMS } from '../../models/Device';
import { Execution } from '../../models/Execution';
import { DeviceService } from '../../services/device.service';
import { addManualExecution } from '../actions/ui';

@Injectable()
export class DevicesAPI {
    constructor(
        private deviceService: DeviceService,
        private uiSocketService: UISocketService,
        private store: Store<AppState>,
    ) {}

    public executeCommand(device: DeviceMS, bodyReq: DeviceExecutionCommand): Observable<Execution> {
        return this.deviceService.executeCommand(device, bodyReq).pipe(
            tap((exec: Execution) => {
                this.store.dispatch(addManualExecution({ exec }));
            }),
            tap((exec: Execution) => {
                // Tell the backend to track these job_id then he can tell about any change through websocket
                this.uiSocketService.setExecutions([exec.job_id]);
            }),
        );
    }
}
