import { createSelector } from '@ngrx/store';
import { getCurrentIntervention, interventionInProgress } from '../../../interventions/store/selectors/interventions';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { DeviceId } from '../../models/Device';
import { getUIState } from '../reducers/ui';
import { getRawDevice } from './device-detail';
import { getSelectedSite } from './site';

const ALLOWED_GATEWAYS_TO_ACCESS_TAHOMA = ['hub_tahoma_1', 'hub_tahoma_2', 'hub_tahoma_raildin'];

export const getTahomaAppCanBeOpened = createSelector(
    getSelectedSite,
    interventionInProgress,
    (site, isInIntervention) => site.activation_status !== 'DONE' || isInIntervention,
);

export const getBoxTahomaCompatibility = (deviceId: DeviceId) =>
    createSelector(
        getSelectedSite,
        getRawDevice(deviceId),
        (site, device) =>
            site.activation_status !== 'DONE_NEEDACTIVATION' &&
            device?.metadata?.migrationStatus !== 'FINISHED' &&
            ALLOWED_GATEWAYS_TO_ACCESS_TAHOMA.includes(device?.vertical_definition),
    );

export const getWaitingForTahomaAppOpening = createSelector(
    getUIState,
    (site) => site.siteDetail.waitingForTahomaAppOpening,
);

export const getRebootBoxCommandData = (deviceId: DeviceId) =>
    createSelector(
        getRawDevice(deviceId),
        getCurrentIntervention,
        (device, { sessionId }): RebootBoxCommand => ({ device, sessionId }),
    );
