import { Inject, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import ENV_NAMES from '../../../environments/ENV_NAMES';
import { environment } from '../../../environments/environment';
import {
    EMAIL_FREQUENCY,
    EmailFrequency,
    ProductType,
} from '../../alerts-notification-config/models/AlertsNotificationConfig';
import { TriggerId } from '../../alerts/models/Alert';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../injectionTokens';
import { AppState } from '../store/app-state';

declare global {
    interface Window {
        tC: any;
        tc_vars: any;
        tc_events_global: any;
        opr: any;
        opera: any;
        StyleMedia: any;
        HTMLElement: any;
        chrome: any;
        CSS: any;
        safari: any;
    }
}

interface tcVars {
    env_brand: string;
    env_channel: string;
    env_section: string;
    env_work: string;
    env_currency: string;
    env_language?: string;
    user_id?: string;
    env_country?: string;
    env_bu?: string;
}

export interface AlertLogEvent {
    trigger_id: TriggerId;
    alert_title: string;
    evt_product_name: string;
}

export interface AlertsNotificationConfigLogEvent {
    productType: ProductType;
    name: string;
    isEnable: 'disabled' | 'enabled';
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private readonly DEFAULT_TC_VARS: tcVars = {
        env_brand: 'SOMFY',
        env_channel: 'B2B',
        env_section: 'servego',
        env_work: undefined,
        env_currency: 'EUR',
    };

    constructor(
        private translationService: TranslateService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private store: Store<AppState>,
    ) {
        window.tc_vars = { ...this.DEFAULT_TC_VARS };
        window.tc_vars.env_work = this.appConfig.name === ENV_NAMES.DEV ? 'test' : this.appConfig.name;

        this.translationService.onLangChange.subscribe((evt: LangChangeEvent) => {
            const previousLg = window.tc_vars.env_language;

            window.tc_vars = {
                ...window.tc_vars,
                env_language: `${evt.lang.toUpperCase()}-${evt.lang.toUpperCase()}`,
            };

            if (environment.production && !previousLg) {
                this.initAnalytics();
            }
        });

        const unsubscribeFromStore = this.store.subscribe((storeState) => {
            const user = storeState?.login?.user;
            if (user?.id) {
                window.tc_vars = {
                    ...window.tc_vars,
                    user_id: user.id,
                    env_country: user.country,
                    env_bu: user.buisnessUnit,
                };
                unsubscribeFromStore.unsubscribe();
            }
        });
    }

    private initAnalytics() {
        const path =
            this.appConfig.name === ENV_NAMES.PROD
                ? 'https://cdn.tagcommander.com/3887/'
                : 'https://cdn.tagcommander.com/3887/uat/';
        const files = ['tc_Somfy_21.js', 'tc_Somfy_34.js'];

        files.forEach((file) => {
            const script = document.createElement('script');
            script.setAttribute('src', `${path}${file}`);
            document.body.appendChild(script);
        });
    }

    notifyNavigation(navigationEndEvent: NavigationEnd): Promise<void> {
        this.onPageChanged(navigationEndEvent);
        return this.reloadContainer();
    }

    private reloadContainer(): Promise<void> {
        return new Promise((resolve) => {
            if (window.tC) {
                window.tC.container.reload({ events: { all: [{}, {}] } });
                resolve();
            } else {
                // tc container not loaded yet. Wait a while
                setTimeout(() => {
                    if (window.tC) {
                        window.tC.container.reload({
                            events: { all: [{}, {}] },
                        });
                        resolve();
                    }
                }, 800);
            }
        });
    }

    private onPageChanged(navigationEndEvent: NavigationEnd) {
        const updatedVars = {};
        if (navigationEndEvent.url.startsWith('/sites')) {
            updatedVars['env_template'] = 'Sites';
            updatedVars['page_name'] = 'Sites - Servego';
        } else if (navigationEndEvent.url.startsWith('/alerts')) {
            updatedVars['env_template'] = 'Alerts';
            updatedVars['page_name'] = 'Alerts - Servego';
        } else if (navigationEndEvent.url.startsWith('/site/')) {
            updatedVars['env_template'] = 'Installation';
            updatedVars['page_name'] = 'Installation - Servego';
            const regexp = /site\/(\S+)/g;
            const res = regexp.exec(navigationEndEvent.url);
            updatedVars['installation_id'] = res[1];
        } else if (navigationEndEvent.url.startsWith('/messages')) {
            updatedVars['env_template'] = 'Messages';
            updatedVars['page_name'] = 'Messages - Servego';
        }
        updatedVars['page_url'] = window.location.href.replace(/^http[s]?:\/\//, '');
        window.tc_vars = { ...window.tc_vars, ...updatedVars };
    }

    private triggerEvent(eventCategory: string, eventName: string, vars: any = {}) {
        if (!('evt_button_action' in vars)) {
            vars.evt_button_action = eventName;
        }

        if (window.tc_events_global) {
            this.setGlobalEvent(eventCategory, eventName, vars);
        } else {
            // tc container not loaded yet. Wait a while
            setTimeout(() => {
                if (window.tc_events_global) {
                    this.setGlobalEvent(eventCategory, eventName, vars);
                }
            }, 800);
        }
    }

    private setGlobalEvent(evt_category: string, id: string, vars: any) {
        try {
            window.tc_events_global(this, id, { evt_category, ...vars });
            console.log(`[Amplitude]`, { id, evt_category, ...vars });
        } catch (e) {
            console.error('Failed to emit analytic event', e);
        }
    }

    searchBoxFromInstallationScreen() {
        this.triggerEvent('installation', 'box_searched');
    }

    clickBoxThroughInstallationList() {
        this.triggerEvent('installation', 'box_list_click');
    }

    clickAlertsMenu() {
        this.triggerEvent('alerts', 'alerts_menu_click');
    }

    clickAlertConfig() {
        this.triggerEvent('alerts', 'alert_configuration_click');
    }

    logAlertsNotificationConfigChanges({ productType, name, isEnable }: AlertsNotificationConfigLogEvent) {
        const vars = { application_type: productType, alert_status: isEnable, name };
        this.triggerEvent('alerts', 'alert_managed', vars);
    }

    logAlertEvent(eventId: string, alertEvent: AlertLogEvent) {
        this.triggerEvent('alerts', eventId, alertEvent);
    }

    logAlertSearched() {
        this.triggerEvent('alerts', 'alert_searched');
    }

    clickGlobalMenu() {
        this.triggerEvent('global_menu', 'global_menu_click');
    }

    accessGlobalMenuDocument(document_type: 'data privacy' | 'terms and conditions' | 'user guide' | 'legal notice') {
        this.triggerEvent('global_menu', 'document_click', { document_type });
    }

    selectLanguage(language_selected: string) {
        const vars = { language_selected };
        this.triggerEvent('global_menu', 'language_modified_click', vars);
    }

    clickMessagesMenu() {
        this.triggerEvent('messages', 'messages_menu_click');
    }

    clickMessageDetail() {
        this.triggerEvent('messages', 'message_detail_click');
    }

    messageDeleted() {
        this.triggerEvent('messages', 'message_deleted');
    }

    addRemoteControl(control_mode: 'QR code' | 'prog') {
        const evt_button_action = 'add_remote_control_step1';
        const vars = { evt_button_action, control_mode };
        this.triggerEvent('controls_management', 'add_remote_control', vars);
    }

    selectedDeviceForRemoteControl(product_names: string[]) {
        const evt_button_action = 'add_remote_control_step2';
        const vars = {
            evt_button_action,
            evt_product_name: product_names.join(','),
        };
        this.triggerEvent('controls_management', 'add_remote_control', vars);
    }

    selectedRemoteControlType(control_type: string) {
        const evt_button_action = 'add_remote_control_step3';
        const vars = { evt_button_action, control_type };
        this.triggerEvent('controls_management', 'add_remote_control', vars);
    }

    selectInstallationTab(tab_selected: 'installation' | 'device' | 'service log') {
        const vars = { tab_selected };
        this.triggerEvent('installation', 'installation_tab_click', vars);
    }

    selectInstallationDevice(evt_product_type: string, evt_product_name: string) {
        const vars = { evt_product_type, evt_product_name };
        this.triggerEvent('installation', 'device_list_click', vars);
    }

    refreshDeviceSection(evt_product_name: string) {
        this.triggerEvent('installation', 'manual_refresh_click', {
            evt_product_name,
        });
    }

    boxLinkingRequested() {
        this.triggerEvent('box_linking', 'box_linking_requested');
    }

    boxServiceRequested() {
        this.triggerEvent('box_linking', 'box_service_requested');
    }

    boxDeleted() {
        this.triggerEvent('box_linking', 'box_deleted');
    }

    remoteServiceRequested(source: string) {
        this.triggerEvent('intervention', 'remote_service_requested', { source });
    }

    editDeviceParameter(evt_product_type: string, parameter_type: string, source: string) {
        const vars = { evt_product_type, parameter_type, source };
        this.triggerEvent('intervention', 'parameter_modified', vars);
    }

    interventionRequestClosed(comment: 'with comment' | 'without comment') {
        const vars = { comment };
        this.triggerEvent('intervention', 'intervention_request_ended', vars);
    }

    rebootBox() {
        this.triggerEvent('intervention', 'remote_box_reboot');
    }

    openTaHomaWebApp() {
        this.triggerEvent('intervention', 'open_tahoma_webapp');
    }

    viewInterventionReport() {
        this.triggerEvent('intervention_history', 'view_report_click');
    }

    reportSentByEmail() {
        this.triggerEvent('intervention_history', 'email_report_sent');
    }

    logFrequencyChange(frequency: EmailFrequency) {
        const frequencyLabels = {
            [EMAIL_FREQUENCY.DAY]: 'Daily',
            [EMAIL_FREQUENCY.WEEK]: 'Weekly',
        };
        this.triggerEvent('alert_emailing_frequency', frequencyLabels[frequency]);
    }

    logAlertSubscriptionStatusChange(enabled: boolean) {
        this.triggerEvent('alert_emailing_status', enabled ? 'Enabled' : 'Disabled');
    }
}
