import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaintenancePeriod } from '../../admin/components/maintenance-period/model/maintenance-period';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class MaintenancePeriodService {
    constructor(private httpService: HttpService) {}

    getCurrent(): Observable<MaintenancePeriod | null> {
        return this.httpService.get('/maintenance-period/current');
    }
}
