import { createSelector } from '@ngrx/store';
import { getLegalDocState, LegalDocState } from '../reducers/legalDocument';

export const getUnsignedLegalDocuments = createSelector(
    getLegalDocState,
    (state: LegalDocState) => state?.unsignedLegalDocument,
);

export const getSignedLegalDocuments = createSelector(
    getLegalDocState,
    (state: LegalDocState) => state?.signedLegalDocument,
);
