import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../../../shared/store/app-state';
import { CreateMaintenancePeriodCommand, MaintenancePeriod } from '../model/maintenance-period';
import { addCreatedMaintenancePeriod, loadMaintenancePeriods, removeDeletedMaintenancePeriod } from '../store/actions';
import { AdminMaintenancePeriodService } from './admin-maintenance-period.service';

@Injectable()
export class AdminMaintenancePeriodApi {
    constructor(
        private store: Store<AppState>,
        private service: AdminMaintenancePeriodService,
    ) {}

    loadMaintenancePeriods(): Observable<MaintenancePeriod[]> {
        return this.service.list().pipe(
            map((maintenancePeriods: MaintenancePeriod[]) => {
                this.store.dispatch(loadMaintenancePeriods({ maintenancePeriods }));
                return maintenancePeriods;
            }),
        );
    }

    createMaintenancePeriod(command: CreateMaintenancePeriodCommand): Observable<MaintenancePeriod> {
        return this.service.create(command).pipe(
            tap((maintenancePeriod: MaintenancePeriod) => {
                return this.store.dispatch(addCreatedMaintenancePeriod({ maintenancePeriod }));
            }),
        );
    }

    deleteMaintenancePeriod(id: string): Observable<void> {
        return this.service.delete(id).pipe(
            map(() => {
                this.store.dispatch(removeDeletedMaintenancePeriod({ id }));
            }),
        );
    }
}
