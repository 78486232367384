import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { MessageContent, MessageMS, MessagesCreateRequest, MessagesInstallerUpdateRequest } from '../../models/Message';
import { MessageService } from '../../services/message.service';
import {
    deleteAdminMessage,
    deleteMessage,
    loadAdminMessages,
    loadMessages,
    markMessageAsRead,
} from '../actions/messages';

@Injectable()
export class MessagesApi {
    constructor(
        private store: Store<AppState>,
        private messageService: MessageService,
    ) {}

    // TODO Remove me once every api env is migrated to use booleans
    static ensureReadPropertyIsBoolean(message: MessageMS): MessageMS {
        return { ...message, read: Boolean(message.read) };
    }

    getMessageForInstaller(): Observable<MessageMS[]> {
        const messages$ = this.messageService.getMessageForInstaller();
        const loadMessagesIntoStore = (messages: MessageMS[]) =>
            this.store.dispatch(
                loadMessages({
                    messages: messages.map(MessagesApi.ensureReadPropertyIsBoolean),
                }),
            );
        return messages$.pipe(tap(loadMessagesIntoStore));
    }

    /**
     *
     * @param installerId
     * @param messageId
     * @return Observable<string> where string = messageid
     */
    markMessageAsRead(installerId: string, messageId: string): Observable<string> {
        const updateReqObj: MessagesInstallerUpdateRequest = {
            id_installer: installerId,
            id_message: messageId,
            read: true, // 1 = read, 0 = unread
            deleted: false, // 0 = not deleted, 1 = deleted
        };
        const updateXHR$ = this.messageService.updateInstallerMessage(updateReqObj);
        return updateXHR$.pipe(tap((id) => this.store.dispatch(markMessageAsRead({ messageId: id }))));
    }

    deleteMessage(messageId: string): Observable<string> {
        return this.messageService
            .deleteInstallerMessage(messageId)
            .pipe(tap((id) => this.store.dispatch(deleteMessage({ messageId: id }))));
    }

    getAdminMessages(): Observable<any> {
        const adminMessage$ = this.messageService.getAdminMessages();
        const loadMessagesIntoStore = (messages: MessageContent[]) =>
            this.store.dispatch(loadAdminMessages({ messages }));
        return adminMessage$.pipe(tap(loadMessagesIntoStore));
    }

    sendAdminMessage(message: MessagesCreateRequest): Observable<string> {
        return this.messageService.sendAdminMessage(message);
    }

    deleteAdminMessage(id_message: string) {
        return this.messageService.deleteAdminMessage(id_message).pipe(
            tap((id) => {
                return this.store.dispatch(deleteAdminMessage({ messageId: id }));
            }),
        );
    }
}
