import { createSelector } from '@ngrx/store';
import { DeviceId } from '../../models/Device';
import { PropertyId } from '../../models/DeviceDetail';
import { getUIState, UIState } from '../reducers/ui';

export const getUiToasterMessages = createSelector(getUIState, (state: UIState) => state?.toaster?.messages);

export const getUIManualExecutions = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.manualExecutions,
);

export const getUiDeviceDefinitions = createSelector(getUIState, (state: UIState) => state?.uiDeviceDefinitions);

export const getUIDevicesStatesSync = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.devicesStatesSync,
);

export const getUISiteSectionExecutionInfo = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.siteSectionExecutionInfo,
);

export const getUISiteId = createSelector(getUIState, (state: UIState) => state?.siteDetail?.id);

export const getUIDevicesSectionsSync = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.devicesSectionsSync,
);

export const getUISiteContentMessage = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.loadingSiteContentMessage,
);

export const getUIPendingInterventionActions = createSelector(
    getUIState,
    (state: UIState) => state?.siteDetail?.pendingInterventionActions,
);

export const getDeviceUpdateTask = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(getUIDevicesStatesSync, (devicesStatesSync) =>
        devicesStatesSync.find((state) => state.deviceId === deviceId && state.state === propertyId),
    );
