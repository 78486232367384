import { createReducer, on } from '@ngrx/store';
import { ToasterMessage, ToasterMessageType } from '../../../shared/models/Toaster';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { removeToasterMessage } from '../actions/ui';

export interface UIToasterState {
    messages: ToasterMessage[];
}

export const UI_TOASTER_INITIAL_STATE: UIToasterState = {
    messages: [],
};

function messageAlreadyInState(message: ToasterMessage, state: UIToasterState) {
    if (message.type === ToasterMessageType.DEVICE_UPDATE_STATE) {
        return state.messages.some(
            (m) =>
                m.type === ToasterMessageType.DEVICE_UPDATE_STATE &&
                m.content.deviceId === message.content.deviceId &&
                m.content.stateLabel === message.content.stateLabel &&
                m.content.newValue === message.content.newValue,
        );
    }
    return state.messages.some(
        (existingMessage) =>
            existingMessage.type === message.type &&
            JSON.stringify(existingMessage.content) === JSON.stringify(message.content),
    );
}

export default createReducer(
    UI_TOASTER_INITIAL_STATE,

    on(addToasterMessage, (state, { message, duration }): UIToasterState => {
        if (messageAlreadyInState(message, state)) {
            return state;
        }

        return {
            ...state,
            messages: [...state.messages, { ...message, duration }],
        };
    }),

    on(removeToasterMessage, (state, { id }): UIToasterState => {
        const messages = [...state.messages].filter((m) => m.id !== id);

        if (messages.length === state.messages.length) {
            return state;
        }

        return { ...state, messages };
    }),
);
