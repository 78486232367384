import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../shared/services/error.service';
import { HttpService } from '../../shared/services/http.service';
import type { SiteId } from '../../site-detail/models/Site';
import type { CloseInterventionRequest } from '../models/CloseInterventionRequest';
import type { Intervention, SessionId } from '../models/Intervention';
import type { InterventionCreateRequest } from '../models/InterventionCreateRequest';

@Injectable()
export class InterventionService {
    constructor(
        private httpService: HttpService,
        private errorService: ErrorService,
    ) {}

    getInterventions(siteId: SiteId): Observable<Intervention[]> {
        return this.httpService.get(`/site/${siteId}/interventions`);
    }

    createInterventionEvent(intervention: InterventionCreateRequest): Observable<Intervention> {
        return this.httpService.post(`/intervention`, intervention).pipe(
            catchError((err) => {
                this.errorService.showToasterError('ERROR_WHILE_OPENING_NEW_INTERVENTION', {
                    duration: 5 * 1000,
                });
                return throwError(() => err);
            }),
        );
    }

    closeSession(intervention: CloseInterventionRequest): Observable<void> {
        return this.httpService.put(`/intervention`, intervention).pipe(
            catchError((err) => {
                this.errorService.showToasterError('ERROR_WHILE_OPENING_NEW_INTERVENTION', {
                    duration: 5 * 1000,
                });
                return throwError(() => err);
            }),
        );
    }

    openSession(siteId: SiteId): Observable<void> {
        return this.httpService.post(`/intervention/new`, { id_site: siteId }).pipe(
            catchError((err) => {
                this.errorService.showToasterError('ERROR_WHILE_OPENING_NEW_INTERVENTION', {
                    duration: 5 * 1000,
                });
                return throwError(() => err);
            }),
        );
    }

    downloadPDFReport(sessionId: SessionId): Observable<Blob> {
        return this.httpService.getDownload(`/intervention/${sessionId}/report`);
    }

    sendInterventionViaEmail(sessionId: SessionId, receiverEmail: string): Observable<void> {
        return this.httpService.post(`/intervention/${sessionId}/mail`, {
            email: receiverEmail,
        });
    }
}
