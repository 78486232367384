import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../shared/models/User';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class UserService {
    constructor(private httpService: HttpService) {}

    getInstaller(): Observable<User> {
        return this.httpService.get('/user');
    }
}
