import { Component, Input, OnChanges } from '@angular/core';
import deviceTypeToIconPathMapping from './deviceTypeToIconPathMapping';

export enum DEVICE_ICON_COLOR {
    BLACK = 'black',
    YELLOW = 'yellow',
    WHITE = 'white',
}

@Component({
    standalone: true,
    selector: 'app-device-icon',
    templateUrl: './device-icon.component.html',
    styleUrls: ['./device-icon.component.scss'],
})
export class DeviceIconComponent implements OnChanges {
    @Input() device: { icon?: string };
    @Input() variant = DEVICE_ICON_COLOR.YELLOW;

    src: string;

    ngOnChanges() {
        this.src = `assets/img/devices/${this.getPath()}.svg`;
    }

    private getPath() {
        if (!(this.device?.icon in deviceTypeToIconPathMapping)) {
            console.warn(`Device icon "${this.device?.icon}" not mapped`);
            return 'generic_black';
        }
        return 'ic_Applicatives/' + deviceTypeToIconPathMapping[this.device.icon];
    }
}
