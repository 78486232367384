function getDefaultIfUnsupported(supportedValues: number[], value: number) {
    return supportedValues.includes(value) ? value : 512;
}

export const valueConverters = {
    available: (value: boolean): string => (value ? 'AVAILABLE' : 'UNAVAILABLE'),

    back_impulse_angle: (value: number): string => (value > 0 ? 'YES' : 'NO'),

    defect: (value: string): string => {
        switch (value) {
            case 'maintenanceRequired':
                return 'DEFECT_MAINTENANCE_REQUIRED';
            case 'lowBattery':
                return 'DEFECT_BATTERY_LOW';
            case 'dead':
                return 'DEFECT_BATTERY_DEAD';
            case 'nodefect':
                return 'DEFECT_NONE';
            default:
                return 'DEFECT_VALUE_UNKNOWN';
        }
    },

    feedback_name: (value: number): number => getDefaultIfUnsupported([4, 8, 16, 17, 19, 20], value),

    id_defaut_list: (values: number[]): number[] => {
        const supportedValues = [8, 9, 10, 11, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44];
        return values
            .filter((value) => value !== 225 && value !== 0)
            .map((value) => getDefaultIfUnsupported(supportedValues, value));
    },

    id_safety_edge_technology: (value: number): number => (value === 255 ? null : value),

    last_events_type_of_last_stop: (rawValue: string): number => {
        const value = Number(rawValue);
        const supportedValues = [1, 49152, 49153, 49154, 49155, 49156, 36864, 36867, 36868];
        return getDefaultIfUnsupported(supportedValues, value);
    },

    window_exposure: (value: number): number => {
        const maxDegrees = 360;
        const directionDegrees = maxDegrees / 8;
        return (directionDegrees * Math.round(value / directionDegrees)) % maxDegrees;
    },

    window_sensed_panel: (value: number): number => (value < 2 ? value : 255),
};
