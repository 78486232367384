import { ADMIN_ROLES } from '../const';

export interface BackOfficeUser {
    id: string;
    username: string;
    role: ADMIN_ROLES;
    rights: string[];
    partner: string[];
}

export interface AdvancedSearchValues {
    nameStartWith?: string;
    postalCode?: string;
    city?: string;
    logic: string;
}

export enum SearchType {
    KEYWORD = 'find',
    QUERY = 'query',
}

export interface SalesForceSearchParameters {
    mode: SearchType;
    keyword?: string;
    fieldsValue?: AdvancedSearchValues;
}
