import { createReducer, on } from '@ngrx/store';
import type { Intervention } from '../../models/Intervention';
import { InterventionActions } from '../actions/interventions';

// FIXME when remove site: all sub info has to be removed too.

export type InterventionsState = Record<string, Intervention>;

export const INTERVENTIONS_INITIAL_STATE: InterventionsState = {};

export default createReducer(
    INTERVENTIONS_INITIAL_STATE,

    on(InterventionActions.loadSiteInterventions, (state, { interventions }): InterventionsState => {
        const siteInterventions = { ...state };

        interventions.forEach((intervention) => {
            siteInterventions[intervention.id] = { ...intervention };
        });

        return siteInterventions;
    }),

    on(InterventionActions.updateSiteIntervention, (state, { intervention: updatedIntervention }): InterventionsState => {
        const siteInterventions = { ...state };

        siteInterventions[updatedIntervention.id] = { ...updatedIntervention };

        return siteInterventions;
    }),

    on(InterventionActions.accessGrantedIntervention, (state, { sessionId }): InterventionsState => {
        const siteInterventions = { ...state };
        const interventions = Object.values(siteInterventions).filter((i) => i.sessionId === sessionId);
        if (interventions.length === 0) {
            return state;
        }

        interventions.forEach((intervention) => {
            siteInterventions[intervention.id] = { ...intervention, accessGranted: true };
        });

        return siteInterventions;
    }),
);
