import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { LoginActions } from '../../../shared/store/actions/login';
import { AppState } from '../../../shared/store/app-state';
import { isMainAccount } from '../../../shared/store/selectors/login';
import { AlertNotificationConfigGateway } from '../../gateways/AlertNotificationConfigGateway';
import { AlertsNotificationConfigService } from '../../services/alerts-notification-config.service';
import { AlertsNotificationConfigActions } from '../actions/alerts-notification-config';
import { getAlertsConfig } from '../selectors/alerts-notification-config';

@Injectable()
export class AlertsNotificationConfigEffects {
    loadAlertNotificationConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginActions.SET_LOGIN_USER),
            switchMap(() => this.store.select(isMainAccount)),
            switchMap((mainAccount) =>
                mainAccount
                    ? this.alertNotificationConfigGateway.getAlertsNotificationConfig()
                    : of({ alertsConfig: [], mailConfig: null }),
            ),
            map((configuration) => AlertsNotificationConfigActions.alertsNotificationConfigLoaded(configuration)),
        ),
    );

    requestAlertConfigsGroupUpdateAfterToggling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsNotificationConfigActions.alertNotificationGroupToggled),
            switchMap((action) => combineLatest([of(action), this.store.select(getAlertsConfig)])),
            map(([{ productType, isActive }, alertsConfig]) =>
                this.alertsNotificationConfigService.updateAlertsConfigByGroup(alertsConfig, productType, isActive),
            ),
            map((alertsConfig) =>
                AlertsNotificationConfigActions.alertNotificationConfigUpdateRequested({ alertsConfig }),
            ),
        ),
    );

    requestAlertConfigUpdateAfterToggling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsNotificationConfigActions.alertNotificationToggled),
            switchMap((action) => combineLatest([of(action), this.store.select(getAlertsConfig)])),
            map(([{ name, isActive }, alertsConfig]) => {
                return this.alertsNotificationConfigService.updateAlertConfig(alertsConfig, name, isActive);
            }),
            map((alertsConfig) =>
                AlertsNotificationConfigActions.alertNotificationConfigUpdateRequested({ alertsConfig }),
            ),
        ),
    );

    saveAlertsNotificationConfigApi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsNotificationConfigActions.alertNotificationConfigUpdateRequested),
            debounceTime(500),
            switchMap(({ alertsConfig }) =>
                this.alertNotificationConfigGateway.saveAlertsNotificationConfig({ config: alertsConfig }).pipe(
                    map(() => AlertsNotificationConfigActions.alertNotificationConfigUpdateSucceed({ alertsConfig })),
                    catchError(() =>
                        of(AlertsNotificationConfigActions.alertNotificationConfigUpdateFailed({ alertsConfig })),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('AlertNotificationConfigGateway')
        private alertNotificationConfigGateway: AlertNotificationConfigGateway,
        private alertsNotificationConfigService: AlertsNotificationConfigService,
        private store: StoreService<AppState>,
    ) {}
}
