import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { SiteDevicesPageActions, SitePageLifeCycleActions } from '../actions/site';
import { getSiteRouteParams } from '../selectors/router';

@Injectable()
export class RouterEffects {
    alignQueryParamToSelectedDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SitePageLifeCycleActions.gatewayDefaultDeviceSelected, SiteDevicesPageActions.deviceSelected),
                switchMap(({ deviceId }) =>
                    combineLatest([of(deviceId), this.storeService.select(getSiteRouteParams)]),
                ),
                filter(([deviceId, params]) => deviceId !== params?.deviceId),
                tap(([deviceId]) => {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { idDevice: deviceId },
                        queryParamsHandling: 'merge',
                    });
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private route: ActivatedRoute,
        private router: Router,
        private storeService: StoreService<AppState>,
    ) {}
}
