import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageContent } from '../../messages/models/Message';

@Injectable()
export class AdminSharedService {
    @Output() public changeTabEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() public messageSentEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() public messageDeletedEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() public messageCopyEvent: EventEmitter<MessageContent> = new EventEmitter<MessageContent>();
    @Output() public legalDocsSearchEvent: EventEmitter<{
        section: string;
        searchedValue: string;
    }> = new EventEmitter();

    onChangeTab(tabName: string): Observable<void> {
        this.changeTabEvent.emit(tabName);
        return of();
    }

    onMessageSentSuccess(): Observable<void> {
        this.messageSentEvent.emit();
        return of();
    }

    onMessageDeletedSuccess(): Observable<void> {
        this.messageDeletedEvent.emit();
        return of();
    }

    onMessageCopy(message: MessageContent) {
        this.messageCopyEvent.emit(message);
        return of();
    }

    onLegalDocumentSearch(searchedValue: string, section: string) {
        this.legalDocsSearchEvent.emit({
            searchedValue,
            section,
        });
        return of();
    }
}
