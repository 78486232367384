import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { BoxGateway } from '../../components/device-detail/BoxGateway';
import { TahomaService } from '../../services/tahoma.service';
import { BoxActions } from '../actions/box-detail';
import { getRebootBoxCommandData } from '../selectors/box-detail';

@Injectable()
export class BoxDetailEffects {
    rebootBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootRequested),
            switchMap(({ deviceId }) => this.storeService.select(getRebootBoxCommandData(deviceId))),
            switchMap((command) =>
                this.boxGateway
                    .reboot(command)
                    .pipe(map((pendingTask) => BoxActions.boxRebootStarted({ command, pendingTask }))),
            ),
        ),
    );

    getTahomaAppAccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.openTahomaAppRequested),
            switchMap(({ deviceId, boxPin, userEmail }) =>
                this.tahomaService.getAccessToken(boxPin, userEmail).pipe(
                    map((token) => BoxActions.tahomaAppAccessAllowed({ deviceId, token, userEmail, boxPin })),
                    timeout(30 * 1000),
                    catchError((error) => of(BoxActions.openTahomaAppFailed({ error }))),
                ),
            ),
        ),
    );

    openTahomaApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.tahomaAppAccessAllowed),
            tap(({ userEmail, token }) => {
                this.tahomaService.openTahomaApp(userEmail, token);
            }),
            map(({ boxPin }) => BoxActions.openTahomaAppSucceeded({ boxPin })),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('BoxGateway') private boxGateway: BoxGateway,
        private tahomaService: TahomaService,
        private storeService: StoreService<AppState>,
    ) {}
}
