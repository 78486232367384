import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { HttpService } from '../../shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class TahomaService {
    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private httpService: HttpService,
    ) {}

    openTahomaApp(userEmail: string, token: string) {
        window.open(`${this.appConfig.tahomaAccess}userId=${userEmail}&ssoToken=${token}`);
    }

    getAccessToken(gatewayId: string, userEmail: string): Observable<any> {
        return this.httpService.patch(`/site/tahomatoken/${gatewayId}/user/${userEmail}`, {});
    }
}
