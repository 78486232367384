<div if="id" class="error-item" id="popupMail">
    <header>
        <div class="icon">
            <img src="assets/img/alerts/alert.svg"/>
        </div>
        <div class="content">
            <div class="title">{{ 'TOASTER_SITE_DEVICE_ERROR' | translate }}</div>
        </div>
        <img class="close-button" src="assets/img/close.svg" (click)="onClose()">
    </header>
    <div class="content">
        <div>{{ content.errorMessage | translate }}</div>
    </div>
</div>
