import { createActionGroup, props } from '@ngrx/store';
import type { Intervention, SessionId } from '../../models/Intervention';

export const InterventionActions = createActionGroup({
    source: 'Intervention',
    events: {
        loadSiteInterventions: props<{ interventions: Intervention[] }>(),
        updateSiteIntervention: props<{ intervention: Intervention }>(),
        accessGrantedIntervention: props<{ sessionId: SessionId }>(),
        interventionClosed: props<{ sessionId: SessionId }>(),
    },
});
