import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

export const { selectQueryParams, selectRouteParams } = getRouterSelectors();

export const selectAllRouteParams = createSelector(
    selectQueryParams,
    selectRouteParams,
    (routeParams, queryParams) => ({ ...routeParams, ...queryParams }),
);
