import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, first, of, withLatestFrom } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { DeviceActions } from '../actions/device-detail';
import { SiteDevicesActions } from '../actions/devices';
import { GatewaysActions } from '../actions/gateways';
import { SitePageActions, SitePageLifeCycleActions } from '../actions/site';
import { getSelectedSiteDevices } from '../selectors/devices';
import { getDefaultSiteGateway, getGatewaySelection } from '../selectors/gateways';
import { getSelectedSite } from '../selectors/site';

@Injectable()
export class SiteEffects {
    selectDefaultGatewayWhenSitePageIsOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitePageActions.installationTabOpened, SitePageActions.devicesTabOpened),
            withLatestFrom(this.store.select(getGatewaySelection)),
            filter(([pageOpened, gateway]) => pageOpened.deviceId == null && gateway == null),
            switchMap(([pageOpened]) =>
                combineLatest([
                    of(pageOpened.siteId),
                    this.actions$.pipe(ofType(SiteDevicesActions.devicesLoaded)).pipe(take(1)),
                    this.actions$.pipe(ofType(GatewaysActions.fetchGatewaysListItemsSucceeded)).pipe(take(1)),
                ]),
            ),
            switchMap(([siteId]) => this.store.select(getDefaultSiteGateway(siteId)).pipe(first(Boolean))),
            map((gatewayId) => SitePageLifeCycleActions.defaultGatewaySelected({ gatewayId })),
        ),
    );

    selectDefaultGatewayWhenDeviceNotFoundOnInstallationTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.allDevicesSynchronized),
            switchMap(() =>
                combineLatest([
                    this.store.select(getSelectedSite),
                    this.store.select(getSelectedSiteDevices).pipe(first(Boolean)),
                ]),
            ),
            withLatestFrom(this.actions$.pipe(ofType(SitePageActions.installationTabOpened))),
            filter(
                ([[site, siteDevices], pageOpened]) =>
                    pageOpened.deviceId != null &&
                    pageOpened.siteId === site.id &&
                    !siteDevices.some((device) => device.id === pageOpened.deviceId),
            ),
            switchMap(([[site]]) => this.store.select(getDefaultSiteGateway(site.id)).pipe(first(Boolean))),
            map((gatewayId) => SitePageLifeCycleActions.defaultGatewaySelected({ gatewayId })),
        ),
    );

    selectDefaultGatewayWhenDeviceNotFoundOnDevicesTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.allDevicesSynchronized),
            switchMap(() =>
                combineLatest([
                    this.store.select(getSelectedSite),
                    this.store.select(getSelectedSiteDevices).pipe(first(Boolean)),
                ]),
            ),
            withLatestFrom(this.actions$.pipe(ofType(SitePageActions.devicesTabOpened))),
            filter(
                ([[site, siteDevices], pageOpened]) =>
                    pageOpened.deviceId != null &&
                    pageOpened.siteId === site.id &&
                    !siteDevices.some((device) => device.id === pageOpened.deviceId),
            ),
            switchMap(([[site]]) => this.store.select(getDefaultSiteGateway(site.id)).pipe(first(Boolean))),
            map((gatewayId) => SitePageLifeCycleActions.defaultGatewaySelected({ gatewayId })),
        ),
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
    ) {}
}
