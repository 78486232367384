import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import constants from '../../shared/const';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { NavigationService } from '../../shared/services/NavigationService';
import { AuthenticationSession } from '../model/AuthenticationSession';
import { SecurityService } from './security.service';

@Injectable({
    providedIn: 'root',
})
export class OauthService {
    private authenticated$ = new Subject<boolean>();

    constructor(
        private localStorageService: LocalStorageService,
        private securityService: SecurityService,
        private navigationService: NavigationService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    authenticate(jwt: string): Observable<AuthenticationSession> {
        return this.securityService.getOAuthToken(jwt).pipe(
            tap((authSession: AuthenticationSession) => this.saveAuthSession(authSession)),
            tap(() => this.authenticated$.next(true)),
        );
    }

    waitForAuthentication(): Observable<boolean> {
        return this.authenticated$.pipe(filter(Boolean));
    }

    logout() {
        this.removeAllTokens();
        this.navigationService.gotoUrl(this.appConfig.sso.logoutUrl);
    }

    refreshToken(): Observable<AuthenticationSession> {
        return this.securityService
            .refreshOauthToken(this.getRefreshToken())
            .pipe(tap((authSession: AuthenticationSession) => this.saveAuthSession(authSession)));
    }

    isAuthenticated(): boolean {
        return this.getAccessToken() != null && !this.isTokenExpired();
    }

    isTokenExpired(): boolean {
        const tokenExpirationDate = this.localStorageService.getItem(constants.OAUTH.EXPIRE);
        return Date.now() >= tokenExpirationDate;
    }

    getAccessToken(): string {
        return this.localStorageService.getItem(constants.OAUTH.ACCESS_TOKEN_NAME);
    }

    private getRefreshToken(): string {
        return this.localStorageService.getItem(constants.OAUTH.REFRESH_TOKEN_NAME);
    }

    private saveAuthSession(authSession: AuthenticationSession): void {
        this.localStorageService.setItem(constants.OAUTH.ACCESS_TOKEN_NAME, authSession.access_token);
        this.localStorageService.setItem(constants.OAUTH.SCOPE, authSession.scope);
        this.localStorageService.setItem(constants.OAUTH.EXPIRE, authSession.expires_at);
        this.localStorageService.setItem(constants.OAUTH.REFRESH_TOKEN_NAME, authSession.refresh_token);
    }

    private removeAllTokens(): void {
        this.localStorageService.deleteItem(constants.LOCAL_STORAGE_BYPASS_ONBOARDING);
        this.localStorageService.deleteItem(constants.OAUTH.ACCESS_TOKEN_NAME);
        this.localStorageService.deleteItem(constants.OAUTH.REFRESH_TOKEN_NAME);
        this.localStorageService.deleteItem(constants.OAUTH.EXPIRE);
        this.localStorageService.deleteItem(constants.OAUTH.SCOPE);
    }
}
