import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminHttpService } from '../../services/http.service';

@Injectable()
export class DebugApi {
    constructor(private adminHttpService: AdminHttpService) {}

    getCmsGatewayStatus(gatewayId: string, country: string): Observable<any> {
        return this.adminHttpService.get(`/debug/cms/${gatewayId}/${country}/status`, { prefix: false });
    }

    getCmsGateway(gatewayId: string): Observable<any> {
        return this.adminHttpService.get(`/debug/cms/${gatewayId}`, {
            prefix: false,
        });
    }

    getMsSiteByGateway(gatewayId: string): Observable<any> {
        return this.adminHttpService.get(`/debug/seg/${gatewayId}`, {
            prefix: false,
        });
    }

    getOvkSetup(gatewayId: string): Observable<any> {
        return this.adminHttpService.get(`/debug/ovk/${gatewayId}`, {
            prefix: false,
        });
    }

    getOvkPartner(gatewayId: string): Observable<any> {
        return this.adminHttpService.get(`/debug/ovk/${gatewayId}/partner`, {
            prefix: false,
        });
    }
}
