import type { DeviceId } from '../../site-detail/models/Device';
import { SiteId } from '../../site-detail/models/Site';

export type CompanyId = string;
export type AlertId = string;

export type TriggerId = number;

export type AlertSeverity = (typeof ALERT_SEVERITY)[keyof typeof ALERT_SEVERITY];

export const ALERT_SEVERITY = {
    NORMAL: 0,
    HIGH: 1,
} as const;

export type AlertStatus = (typeof ALERT_STATUS)[keyof typeof ALERT_STATUS];

export const ALERT_STATUS = {
    NEW: 'new',
    IN_PROGRESS: 'in_progress',
    RESOLVED: 'resolved',
} as const;

export interface Alert {
    id: AlertId;
    id_device: DeviceId;
    id_site: SiteId;
    id_company: CompanyId;
    id_trigger: TriggerId;
    date: number;
    device_name: string;
    user_name?: string;
    severity: AlertSeverity;
    status: AlertStatus;
    title?: string;
}

export interface SiteAlertsGroup {
    siteId: SiteId;
    userName: string;
    alerts: Alert[];
}
