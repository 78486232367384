import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { durationToSeconds, secondsToDuration } from '../../../../../../utils/duration';
import { DurationData } from '../../StateData';
import { PadZeroDirective } from '../pad-zero.directive';
import { StateEditorComponent } from '../StateEditorComponent';

export interface DurationEditorConfig {
    precision: 'minutes' | 'seconds';
    max: number;
}

@Component({
    selector: 'app-duration-state-editor',
    standalone: true,
    imports: [
        MatDialogModule,
        MatInputModule,
        NgxMaskDirective,
        ReactiveFormsModule,
        TranslateModule,
        FormsModule,
        PadZeroDirective,
    ],
    templateUrl: './duration-editor.component.html',
    styleUrls: ['./duration-editor.component.scss'],
})
export class DurationEditorComponent implements OnInit, StateEditorComponent<DurationData, DurationEditorConfig> {
    @Input({ required: true }) value: DurationData;
    @Input({ required: true }) config: DurationEditorConfig;
    @Output() valueChange = new EventEmitter<DurationData>();

    hoursValue = 0;
    minutesValue = 0;
    secondsValue = 0;

    constructor(public translateService: TranslateService) {}

    ngOnInit() {
        const timerData = secondsToDuration(this.value);
        this.hoursValue = timerData.hours;
        this.minutesValue = timerData.minutes;
        this.secondsValue = this.config.precision === 'minutes' ? 0 : timerData.seconds;
    }

    onUserInput() {
        this.valueChange.emit(this.editedValue);
    }

    get editedValue(): number {
        return durationToSeconds(this.hoursValue, this.minutesValue, this.secondsValue);
    }

    isValid(): boolean {
        const durationNotExceedingMax = this.config.max == null || this.editedValue <= this.config.max;
        return durationNotExceedingMax && this.minutesValue < 60 && this.secondsValue < 60;
    }
}
