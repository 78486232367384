import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { getSiteOwner } from '../../../site-detail/store/selectors/site';
import { getUISiteId } from '../../../site-detail/store/selectors/ui';
import { Intervention } from '../../models/Intervention';
import type { InterventionsState } from '../reducers/interventions';
import type { InterventionButtonVm } from '../view-models/InterventionButtonVm';

const getInterventions = createFeatureSelector<InterventionsState>('interventions');

const getCurrentSiteInterventions = createSelector(
    getUISiteId,
    getInterventions,
    (siteId, interventions): Intervention[] =>
        Object.values(interventions).filter((intervention) => intervention.siteId === siteId),
);

export const getCurrentIntervention = createSelector(
    getCurrentSiteInterventions,
    (interventions): Intervention => interventions.find((intervention) => !intervention.date?.end),
);

export const interventionInProgress = createSelector(
    getCurrentIntervention,
    (intervention): boolean => !!intervention?.accessGranted,
);

export const getSiteInterventions = (siteId: SiteId) =>
    createSelector(getInterventions, (interventions): Intervention[] =>
        Object.values(interventions).filter((intervention) => intervention.siteId === siteId),
    );

export const getOpenedSiteIntervention = (siteId: SiteId) =>
    createSelector(getSiteInterventions(siteId), (interventions): Intervention | null =>
        interventions.find((intervention) => !intervention.date?.end),
    );

export const getInterventionInProgress = (siteId: SiteId) =>
    createSelector(getSiteInterventions(siteId), (interventions): Intervention | null =>
        interventions.find((intervention) => !intervention.date?.end && intervention.accessGranted),
    );

export const getInterventionButtonVm = (siteId: SiteId) =>
    createSelector(
        getSiteOwner(siteId),
        getOpenedSiteIntervention(siteId),
        (owner, openedIntervention): InterventionButtonVm => {
            if (!openedIntervention) {
                return {
                    owner,
                    asking: false,
                    access: false,
                    startDate: null,
                };
            }

            return {
                owner,
                asking: !openedIntervention.accessGranted,
                access: openedIntervention.accessGranted,
                startDate: openedIntervention.date.start,
            };
        },
    );
