import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { User } from '../../../shared/models/User';
import { UserAccess } from '../../models/access';
import { BackOfficeUser } from '../../models/backOfficeUser';
import { CMSAssociationResult, CrmUser, InstallerSignatureInfo } from '../../models/installer';
import { LegalDocument, LegalDocumentVersion, UserSignature, UserSubscription } from '../../models/subscription';
import * as AdminActions from '../actions/admin';

export interface AdminState {
    installers: {
        list: User[];
        cmsAssociations: CrmUser[];
    };
    usersAccess: {
        list: UserAccess[];
    };
    selectedInstaller: CrmUser;
    selectedInstallerSignature?: InstallerSignatureInfo[];
    legalDocsServiceId: string;
    legalDocsDocuments: LegalDocument[];
    legalDocsVersions: LegalDocumentVersion[];
    legalDocsSubscriptions: UserSubscription[];
    legalDocsSignatures: UserSignature[];
    currentUser: BackOfficeUser;
}

export const ADMIN_INITIAL_STATE: AdminState = {
    installers: {
        cmsAssociations: [],
        list: [],
    },
    selectedInstaller: null,
    selectedInstallerSignature: [],
    usersAccess: {
        list: [],
    },
    legalDocsServiceId: null,
    legalDocsDocuments: [],
    legalDocsVersions: [],
    legalDocsSubscriptions: [],
    legalDocsSignatures: [],
    currentUser: null,
};

export const removeSubjectFromList = <T>(list: Array<T>, subject: T) => {
    const i = list.indexOf(subject);
    return [...list.slice(0, i), ...list.slice(i + 1)];
};

export default createReducer(
    ADMIN_INITIAL_STATE,

    on(AdminActions.loadInstallersCmsAssociation, (state, { cmsAssociationResults }): AdminState => {
        const cmsAssociations = cmsAssociationResults
            .map((cmsAssoc: CMSAssociationResult) => cmsAssoc.usersBu)
            .reduce((acc, val) => {
                acc.push(...val);
                return acc;
            }, []);
        return {
            ...state,
            installers: { ...state.installers, cmsAssociations },
        };
    }),

    on(AdminActions.loadUserAccess, (state, { users: usersaccess }): AdminState => {
        let list = [];
        if (usersaccess) {
            list = usersaccess.map((u) => ({
                ...u,
                role: u.extra['technical_name'],
            }));
        }
        return {
            ...state,
            usersAccess: { ...state.usersAccess, list },
        };
    }),

    on(AdminActions.updateUserAccess, (state, { userAccess }): AdminState => {
        // FIXME type UpdateUserAccess and UserAccess do not map
        userAccess.role = (userAccess as UserAccess).extra['technical_name'];

        const useraccessList = [...state.usersAccess.list, userAccess as UserAccess];

        return {
            ...state,
            usersAccess: { ...state.usersAccess, list: useraccessList },
        };
    }),

    on(
        AdminActions.setSelectedInstaller,
        (state, { installer }): AdminState => ({
            ...state,
            selectedInstaller: installer,
        }),
    ),

    on(AdminActions.setSelectedInstallerSignatures, (state, { signatureInfo }): AdminState => {
        const signatures = signatureInfo.map((signature) => ({
            ...signature,
            lastPublishDate: new Date(signature.lastPublishDate),
            lastSignatureDate: signature.lastSignatureDate ? new Date(signature.lastSignatureDate) : undefined,
        }));
        return { ...state, selectedInstallerSignature: signatures };
    }),

    on(
        AdminActions.clearSelectedInstallerSignatures,
        (state): AdminState => ({
            ...state,
            selectedInstallerSignature: [],
        }),
    ),

    on(
        AdminActions.setServices,
        (state, { serviceId }): AdminState => ({
            ...state,
            legalDocsServiceId: serviceId,
        }),
    ),

    on(
        AdminActions.setUserSubscriptions,
        (state, { subscriptions }): AdminState => ({
            ...state,
            legalDocsSubscriptions: subscriptions,
        }),
    ),

    on(
        AdminActions.setUserSignatures,
        (state, { signatures }): AdminState => ({
            ...state,
            legalDocsSignatures: signatures,
        }),
    ),

    on(
        AdminActions.setLegalDocuments,
        (state, { documents }): AdminState => ({
            ...state,
            legalDocsDocuments: documents,
        }),
    ),

    on(
        AdminActions.setLegalDocsVersions,
        (state, { versions }): AdminState => ({
            ...state,
            legalDocsVersions: versions,
        }),
    ),

    on(
        AdminActions.addCreatedLegalDoc,
        (state, { doc }): AdminState => ({
            ...state,
            legalDocsDocuments: [...state.legalDocsDocuments, doc],
        }),
    ),

    on(
        AdminActions.addCreatedLegalDocVersion,
        (state, { version }): AdminState => ({
            ...state,
            legalDocsVersions: [...state.legalDocsVersions, version],
        }),
    ),

    on(AdminActions.addCreatedLegalDocTranslation, (state, { transl }): AdminState => {
        const docVersion = state.legalDocsVersions.find((v) => v.id === transl.versionId);
        if (!docVersion) {
            return state;
        }
        const docVersionIndex = state.legalDocsVersions.indexOf(docVersion);

        const updatedVersion = { document_translation: [], ...docVersion };
        updatedVersion.document_translation.push(transl);
        const updatedLegalVersionList = [
            ...state.legalDocsVersions.slice(0, docVersionIndex),
            updatedVersion,
            ...state.legalDocsVersions.slice(docVersionIndex + 1),
        ];

        return { ...state, legalDocsVersions: updatedLegalVersionList };
    }),

    on(AdminActions.removeLegalDocument, (state, { deleteDocId }): AdminState => {
        const document = state.legalDocsDocuments.find((d) => d.id === deleteDocId);
        if (!document) {
            return state;
        }

        const legalDocsDocuments = removeSubjectFromList(state.legalDocsDocuments, document);
        return { ...state, legalDocsDocuments };
    }),

    on(AdminActions.removeLegalDocVersion, (state, { deleteDocVersionId }): AdminState => {
        const version = state.legalDocsVersions.find((dv) => dv.id === deleteDocVersionId);
        if (!version) {
            return state;
        }

        const legalDocsVersions = removeSubjectFromList(state.legalDocsVersions, version);
        return { ...state, legalDocsVersions };
    }),

    on(
        AdminActions.setCurrentBackOfficeUserInfo,
        (state, { user }): AdminState => ({
            ...state,
            currentUser: user,
        }),
    ),
);

export const getAdminState = createFeatureSelector<AdminState>('admin');
