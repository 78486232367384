import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterMailSentMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-mail-sent-item',
    templateUrl: './mail-sent-item.component.html',
    styleUrls: ['./mail-sent-item.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class MailSentItemComponent implements OnInit {
    @Input()
    content: ToasterMailSentMessageContent;

    @Input()
    id: string;

    translatedUsername = '';

    constructor(
        private translateService: TranslateService,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        this.translatedUsername = this.translateService.instant('TOASTER_MAIL_SENT_USERNAME', {
            userName: this.content.userSiteName,
        });

        setTimeout(() => {
            this.removeMessage();
        }, 4000);
    }

    removeMessage() {
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }
}
