import { PropertyId } from '../../../../models/DeviceDetail';
import { defaultValues } from '../defaultValues';
import buildTextViewer, { TextViewerVm } from './buildTextViewer';

type EmptyValue = null | undefined | '' | [];

export function isEmpty(value: unknown): value is EmptyValue {
    return value == null || value === '' || (Array.isArray(value) && value.length < 1);
}

function getEmptyLabel(propertyId: PropertyId, value: EmptyValue) {
    if (defaultValues[propertyId]) {
        return defaultValues[propertyId];
    }

    return value === undefined ? 'NOT_AVAILABLE' : 'NOT_FILLED';
}

export default function buildEmptyViewer(propertyId: PropertyId, value: EmptyValue): TextViewerVm {
    return buildTextViewer(getEmptyLabel(propertyId, value));
}
