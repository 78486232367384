export type AlertsNotificationConfigId = string;

export type EmailFrequency = (typeof EMAIL_FREQUENCY)[keyof typeof EMAIL_FREQUENCY];

export const EMAIL_FREQUENCY = {
    WEEK: 'week',
    DAY: 'day',
} as const;

export type EmailSubscriptionStatus = (typeof EMAIL_SUBSCRIPTION_STATUS)[keyof typeof EMAIL_SUBSCRIPTION_STATUS];

export const EMAIL_SUBSCRIPTION_STATUS = {
    SUBSCRIBED: true,
    UNSUBSCRIBED: false,
} as const;

export type ProductType = (typeof PRODUCT_TYPE)[keyof typeof PRODUCT_TYPE];

export const PRODUCT_TYPE = {
    SHUTTER: 'shutter',
    AWNING: 'awning',
    ACCESS: 'access',
    WINDOW: 'window',
    INTERIOR: 'interior',
} as const;

export interface AlertConfig {
    name: string;
    productType: ProductType;
    isActive: boolean;
    external_uid: number[];
}

export interface AlertsMailingConfig {
    subscriptionEnabled: EmailSubscriptionStatus;
    frequency: EmailFrequency;
}

export interface AlertsNotificationConfig {
    alertsConfig: AlertConfig[];
    mailConfig: AlertsMailingConfig;
}

export interface ProductAlertsGroup {
    name: string;
    productType: ProductType;
    triggerIdList: number[];
}

export interface AlertsNotificationConfigGroup {
    name: string;
    productType: ProductType;
    isActive: boolean;
    alertsConfig: AlertConfig[];
}
