import { PropertyMetadata } from '../../../models/DeviceDetail';
import { UiProfileProperty } from '../../../models/UiProfile';
import { InputHelperArguments } from '../../dummies-components/device/modals/state-edition-modal/state-edition-modal.component';
import { DurationEditorConfig } from '../../dummies-components/device/state-editors/duration-editor/duration-editor.component';
import { NamedValueEditorConfig } from '../../dummies-components/device/state-editors/named-value-editor/named-value-editor.component';
import { NumberEditorConfig } from '../../dummies-components/device/state-editors/number-editor/number-editor.component';
import { SliderEditorConfig } from '../../dummies-components/device/state-editors/slider-editor/slider-editor.component';
import { TextEditorConfig } from '../../dummies-components/device/state-editors/text-state-editor/text-editor.component';
import { buildDateEditor } from './builders/buildDateEditor';
import { buildDurationEditor } from './builders/buildDurationEditor';
import { buildNamedValueEditor } from './builders/buildNamedValueEditor';
import { buildNumberEditor } from './builders/buildNumberEditor';
import { buildTextEditor } from './builders/buildTextEditor';

export type DeviceEditablePropertyValue = string | number;

export type SectionItemValue = string | number | null;

export interface InputHelper {
    key: string;
    arguments?: InputHelperArguments;
}

export interface SectionItemEditor {
    value: SectionItemValue;
    type?: string;
    inputHelper?: InputHelper;
    warningMessage?: string;
    config?: TextEditorConfig | NamedValueEditorConfig | NumberEditorConfig | SliderEditorConfig | DurationEditorConfig;
}

const buildersByType = {
    text: buildTextEditor,
    namedValue: buildNamedValueEditor,
    number: buildNumberEditor,
    duration: buildDurationEditor,
    date: buildDateEditor,
};

export default function buildEditor(
    uiProperty: UiProfileProperty,
    propertyValue: DeviceEditablePropertyValue,
    metadata?: PropertyMetadata,
): SectionItemEditor {
    const editorBuilder = buildersByType[uiProperty.editor?.type];
    if (!editorBuilder) {
        return null;
    }

    const editor = editorBuilder(uiProperty, propertyValue, metadata ?? {});
    if (uiProperty.editor?.warningMessage) {
        editor.warningMessage = uiProperty.editor.warningMessage;
    }
    return editor;
}
