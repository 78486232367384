import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AlertResolutionActions } from '../../../alert-resolution/store/actions/alert-resolution';
import { AppActions } from '../../../core/store/actions/app';
import { DeviceActions } from '../../../site-detail/store/actions/device-detail';
import { AlertService } from '../../services/alert.service';
import { AlertsActions, AlertsPageActions } from '../actions/alerts';

@Injectable()
export class AlertsEffects {
    // Reload alerts every 15 min
    private readonly LOAD_ALERTS_INTERVAL = 15 * 60 * 1000;

    loadAlertsAfterInitialization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initialized),
            switchMap(() => this.alertService.getAlerts()),
            map((alerts) => AlertsActions.alertsLoaded({ alerts })),
        ),
    );

    refreshAlertsByInterval$ = createEffect(() =>
        interval(this.LOAD_ALERTS_INTERVAL).pipe(
            switchMap(() => this.alertService.getAlerts()),
            map((alerts) => AlertsActions.alertsLoaded({ alerts })),
        ),
    );

    startAlertResolution$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertResolutionActions.alertResolutionPageOpened),
            switchMap(({ alertId }) =>
                this.alertService
                    .startResolution(alertId)
                    .pipe(map(() => AlertsActions.alertResolutionStarted({ alertId }))),
            ),
        ),
    );

    resolveAlert$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DeviceActions.alertResolutionRequested,
                AlertResolutionActions.alertResolutionRequested,
                AlertsPageActions.alertResolutionRequested,
            ),
            switchMap(({ alertId }) =>
                this.alertService.resolveAlert(alertId).pipe(map(() => AlertsActions.alertResolved({ alertId }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
    ) {}
}
