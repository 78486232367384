import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { DEFAULT_LOCALE } from '../../../utils/url';
import { envLoaded, startLoading, userLanguageChanged } from '../actions/user-env';

export interface UserEnvState {
    loading: boolean;
    language: string;
}

export const USER_ENV_INITIAL_STATE: UserEnvState = {
    loading: false,
    language: DEFAULT_LOCALE,
};

export default createReducer(
    USER_ENV_INITIAL_STATE,

    on(startLoading, (state): UserEnvState => ({ ...state, loading: true })),

    on(envLoaded, (state): UserEnvState => ({ ...state, loading: false })),

    on(userLanguageChanged, (state, { language }): UserEnvState => ({ ...state, language })),
);

export const getUserEnvState = createFeatureSelector<UserEnvState>('user-env');
