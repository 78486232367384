import { availableAppLocale, languageMapping } from '../shared/i18n/i18n.config';

export const mapLocale = (locale) => {
    const mappedLocale = languageMapping.find((el) => el.frontLang === locale);
    return mappedLocale ? mappedLocale.dbLang : locale;
};

export const getAvailableAppLocaleMapped = () => {
    const result = availableAppLocale.map(({ adminName, shortName }) => ({
        name: adminName,
        value: mapLocale(shortName),
    }));
    return result;
};
