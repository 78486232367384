import { createSelector } from '@ngrx/store';
import {
    AlertConfig,
    AlertsNotificationConfigGroup,
    ProductAlertsGroup,
    ProductType,
} from '../../models/AlertsNotificationConfig';
import productAlertsNotificationGroups from '../../productAlertsNotificationGroups';
import { AlertsNotificationState, getAlertsNotificationState } from '../reducers/alerts-notification-config';
import { AlertsNotificationConfigVm } from '../view-models/AlertsNotificationConfigVm';

export const getAlertsConfig = createSelector(
    getAlertsNotificationState,
    (state: AlertsNotificationState) => state?.alertsConfig,
);

export const getAlertConfig = (name: string, productType: ProductType) =>
    createSelector(getAlertsConfig, (alertsConfig: AlertConfig[]): AlertConfig => {
        return alertsConfig.find((alertConfig) => alertConfig.name === name && alertConfig.productType === productType);
    });

export const getAlertsConfigByProductType = (productType: ProductType) =>
    createSelector(getAlertsConfig, (alertsConfig: AlertConfig[]): AlertConfig[] => {
        return alertsConfig.filter((alertConfig) => alertConfig.productType === productType);
    });

export const getAlertsNotificationConfigByGroups = createSelector(
    getAlertsConfig,
    (alertsConfig: AlertConfig[]): AlertsNotificationConfigGroup[] => {
        return productAlertsNotificationGroups
            .map((productGroup: ProductAlertsGroup) => {
                const configGroup: AlertsNotificationConfigGroup = {
                    name: productGroup.name,
                    productType: productGroup.productType,
                    alertsConfig: [],
                    isActive: false,
                };

                configGroup.alertsConfig = alertsConfig.filter(
                    (alert: AlertConfig) => alert.productType === productGroup.productType,
                );
                configGroup.isActive = configGroup.alertsConfig.every((alert: AlertConfig) => alert.isActive);

                return configGroup;
            })
            .filter((group) => group.alertsConfig.length > 0);
    },
);

export const getAlertsNotificationConfig = createSelector(
    getAlertsNotificationState,
    getAlertsNotificationConfigByGroups,
    (
        alertsNotificationConfig: AlertsNotificationState,
        alertsConfigByGroups: AlertsNotificationConfigGroup[],
    ): AlertsNotificationConfigVm => {
        const { mailConfig } = alertsNotificationConfig;
        return {
            alertsConfig: alertsConfigByGroups,
            mailConfig,
        };
    },
);
