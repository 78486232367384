/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { BackOfficeUser } from '../models/backOfficeUser';
import { Login } from '../models/login';

@Injectable()
export class AdminSecurityService {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    getOAuthToken(login: Login) {
        return this.http
            .post(this.appConfig.servegoAdminApi.getOAuthTokenUrl, login)
            .pipe(catchError(this.handleError));
    }

    refrehOAuthToken(refreshToken: string) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${refreshToken}`,
        });
        return this.http.get(this.appConfig.servegoAdminApi.refreshOAuthTokenUrl).pipe(catchError(this.handleError));
    }

    logout(sessionToken: string) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${sessionToken}`,
        });
        return this.http
            .get(this.appConfig.servegoAdminApi.logoutUrl, {
                headers,
                responseType: 'text',
            })
            .pipe(catchError(this.handleError));
    }

    getCurrentLoggedInUserInfo(sessionToken: string): Observable<BackOfficeUser> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${sessionToken}`,
        });
        return this.http
            .get<any>(`${this.appConfig.servegoAdminApi.rootUrl}-security/user`, {
                headers,
            })
            .pipe(catchError(this.handleError));
    }

    // Implement a method to handle errors if any
    private handleError(err: HttpErrorResponse | any) {
        console.error('An error occurred', err);
        return observableThrowError(err.message || err);
    }
}
