import { createActionGroup, props } from '@ngrx/store';
import { AlertConfig, AlertsNotificationConfig, ProductType } from '../../models/AlertsNotificationConfig';

export const AlertsNotificationConfigActions = createActionGroup({
    source: 'Alerts Notification Config',
    events: {
        alertsNotificationConfigLoaded: props<AlertsNotificationConfig>(),
        alertNotificationGroupToggled: props<{ productType: ProductType; isActive: boolean }>(),
        alertNotificationToggled: props<{ name: string; productType: ProductType; isActive: boolean }>(),
        alertNotificationConfigUpdateRequested: props<{ alertsConfig: AlertConfig[] }>(),
        alertNotificationConfigUpdateSucceed: props<{ alertsConfig: AlertConfig[] }>(),
        alertNotificationConfigUpdateFailed: props<{ alertsConfig: AlertConfig[] }>(),
    },
});
