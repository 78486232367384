<div class="seg-modal">
  <div class="seg-modal-header">
    <h4 mat-dialog-title class="seg-modal-title">
      {{ 'SIGN_LEGAL_DOCUMENT_MODAL_TITLE' | translate }}
    </h4>
  </div>
  <mat-dialog-content class="seg-modal-body">
    @if (data.docToSign && fileURL) {
      <iframe class="seg-modal-iframe" [attr.src]="fileURL"></iframe>
    } @else {
      <app-loader [visible]="true"></app-loader>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="seg-flex-justify-space-around">
    <button [disabled]="submitButtonDisabled"
            id="btn-validate"
            class="seg-btn seg-bg-color-primary"
            mat-button
            mat-dialog-close
            (click)="signDocument();"
    >
      {{ 'SIGN_LEGAL_DOCUMENT' | translate }}
    </button>
  </mat-dialog-actions>
</div>
