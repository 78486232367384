import { LevelsViewerSpec } from '../../../../models/ViewerSpec';

export interface LevelsViewerVm {
    type: 'levels';
    value: number[];
    config: LevelsViewerConfig;
}

export interface LevelsViewerConfig {
    labelTranslationKey: string;
}

export default function buildLevelsViewer(viewer: LevelsViewerSpec, value: number[]): LevelsViewerVm {
    return {
        type: 'levels',
        value: value.slice(1, 10),
        config: {
            labelTranslationKey: viewer.labelTranslationKey,
        },
    };
}
