import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { AlertResolutionActions } from '../../../alert-resolution/store/actions/alert-resolution';
import { GatewayId, GatewayListItem } from '../../models/Gateway';
import { SiteId } from '../../models/Site';
import { FetchGatewaysListSucceededEvent, GatewaysActions } from '../actions/gateways';
import {
    SiteDevicesPageActions,
    SiteInstallationPageActions,
    SitePageActions,
    SitePageLifeCycleActions,
} from '../actions/site';

export interface GatewaysState {
    selectedGatewayId: GatewayId;
    gateways: Record<SiteId, GatewayListItem[]>;
}

export const GATEWAYS_INITIAL_STATE: GatewaysState = {
    selectedGatewayId: null,
    gateways: {},
};

export default createReducer(
    GATEWAYS_INITIAL_STATE,
    on(
        GatewaysActions.fetchGatewaysListItemsSucceeded,
        (state: Readonly<GatewaysState>, { siteId, gateways }: FetchGatewaysListSucceededEvent): GatewaysState => {
            return {
                ...state,
                gateways: { ...state.gateways, [siteId]: gateways },
            };
        },
    ),
    on(
        SitePageLifeCycleActions.deviceGatewaySelected,
        SitePageLifeCycleActions.defaultGatewaySelected,
        SiteInstallationPageActions.gatewaySelected,
        SiteDevicesPageActions.gatewaySelected,
        AlertResolutionActions.deviceGatewaySelected,
        (state: Readonly<GatewaysState>, { gatewayId }): GatewaysState => {
            return { ...state, selectedGatewayId: gatewayId };
        },
    ),
    on(
        SitePageActions.closed,
        AlertResolutionActions.alertResolutionClosed,
        (state: Readonly<GatewaysState>): GatewaysState => {
            return { ...state, selectedGatewayId: null };
        },
    ),
);

export const getGatewaysState = createFeatureSelector<GatewaysState>('gateways');
