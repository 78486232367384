import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BUSINESS_UNIT } from '../../../shared/i18n/i18n.config';
import { User } from '../../../shared/models/User';
import { AppState } from '../../../shared/store/app-state';
import { getUserLoginState } from '../../../shared/store/selectors/login';

@Component({
    selector: 'app-iadvize',
    template: '',
    standalone: true,
})
export class IAdvizeComponent implements OnInit, OnDestroy {
    private readonly iAdvizeId = 18216;

    private readonly iadvizeUrl = `https://lc.iadvize.com/iadvize.js?sid=${this.iAdvizeId}`;

    readonly userLogin$: Observable<User>;

    private unsubscribe$: Subject<void> = new Subject();

    constructor(private store: Store<AppState>) {
        this.userLogin$ = this.store.select(getUserLoginState);
    }

    ngOnInit(): void {
        this.userLogin$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((v) => !!v),
            )
            .subscribe((userLogin: User) => {
                this.checkForIAdvizeChat(userLogin);
            });
    }

    checkForIAdvizeChat(userLogin: User) {
        const existingScript = document.querySelector(`script[src="${this.iadvizeUrl}"]`);

        const country = userLogin.country;
        const bu = userLogin.buisnessUnit;

        let shouldDisplayIAdvizeChat = false;
        if (bu && bu.toLowerCase() === BUSINESS_UNIT.FRANCE.toLowerCase()) {
            shouldDisplayIAdvizeChat = true;
        } else if (country && country.toLowerCase() === 'france') {
            shouldDisplayIAdvizeChat = true;
        }
        if (shouldDisplayIAdvizeChat && !existingScript) {
            this.showIAdvizeChat(userLogin);
        }
    }

    showIAdvizeChat(userLogin: User): void {
        window.idzCustomData = {
            connected: true,
            compte_client: userLogin.crmId,
        };

        const idz = document.createElement('script');
        idz.type = 'text/javascript';
        idz.async = true;
        idz.src = this.iadvizeUrl;
        const allScripts = document.getElementsByTagName('script');
        const s = allScripts?.[allScripts?.length - 1];
        s.parentNode.append(idz);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
