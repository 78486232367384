import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Buffer } from 'buffer';
import { CrmUser } from '../admin/models/installer';
import { buList } from '../shared/i18n/i18n.config';
import { DeviceMS } from '../site-detail/models/Device';
import { DeviceListItemVm } from '../site-detail/store/view-models/device';
import { GatewayListItemVm } from '../site-detail/store/view-models/gateway-list';

export function implementsCrmUser(object: any): object is CrmUser {
    return 'crmId' in object && 'buisnessUnit' in object;
}

export function getQueryParams(url: string): Record<string, string> {
    const urlWithoutHash = new URL(url.replace('#/', ''));
    return Object.fromEntries(urlWithoutHash.searchParams.entries());
}

export function sortDevices(a: DeviceListItemVm, b: DeviceListItemVm, language: string): number {
    const severityA = a.severity ?? -1;
    const severityB = b.severity ?? -1;

    if (severityA !== severityB) {
        return severityB - severityA;
    }

    if (a.deviceUrl.includes('rts://') && !b.deviceUrl.includes('rts://')) {
        return 1;
    } else if (!a.deviceUrl.includes('rts://') && b.deviceUrl.includes('rts://')) {
        return -1;
    }

    if (!a.name || !b.name) {
        return -1;
    }

    return a.name.localeCompare(b.name, language, {
        numeric: true,
        sensitivity: 'base',
        caseFirst: 'upper',
    });
}

export function sortGateways(gateway1: GatewayListItemVm, gateway2: GatewayListItemVm, language: string) {
    if (!gateway1.isZigbee && gateway2.isZigbee) {
        return 1;
    } else if (gateway1.isZigbee && !gateway2.isZigbee) {
        return -1;
    }
    return gateway1.label.localeCompare(gateway2.label, language, {
        numeric: true,
        sensitivity: 'base',
        caseFirst: 'upper',
    });
}

// https://gomakethings.com/how-to-check-if-two-arrays-are-equal-with-vanilla-js/
/**
 * Check if 2 different arrays (not the same reference) contains the same values in the same order
 * @param arr1 {string[] | number[] | boolean []}
 * @param arr2 {string[] | number[] | boolean []}
 */
export function arraysMatch(arr1: string[] | number[] | boolean[], arr2: string[] | number[] | boolean[]) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    // Otherwise, return true
    return true;
}

/**
 * Check if given file (as ArrayBuffer) looks like a PDF
 * @param buffer (ArrayBuffer)
 *
 *
 * From this document https://en.wikipedia.org/wiki/List_of_file_signatures
 * All PDF files start with '%PDF' (ASCII) = '25 50 44 46 2d' (HEX) = '37,80,68,70' (Dec)
 * So farn we get the values directly in Decimal format
 */
export function fileIsPDF(buffer: ArrayBuffer): boolean {
    //         HEXA                  [25  50  44   46  2d]
    //         ASCII                 [%   P   D    F   - ]
    const PDF_HEADER_4_FIRST_BYTES = [37, 80, 68, 70, 45];
    // because Buffre.from() return a TypedArray (which is a special array) and i just want a "standard" array,
    // i join then split to get just the values
    // finaly, a recast with parseint
    const fileFirst5Bytes = Buffer.from(buffer, 0, 5)
        .join(',')
        .split(',')
        .map((str) => Number.parseInt(str, 10));
    return arraysMatch(fileFirst5Bytes, PDF_HEADER_4_FIRST_BYTES);
}

// https://stackoverflow.com/a/54126828
export function isFormGroup(control: AbstractControl): control is UntypedFormGroup {
    return !!(<UntypedFormGroup>control).controls;
}

// slightly modified to return array instead of object
export function collectErrors(control: AbstractControl): any | null {
    if (!isFormGroup(control)) {
        return control.errors;
    }

    return Object.entries(control.controls).reduce((acc, [key, childControl]) => {
        const childErrors = collectErrors(childControl);
        if (childErrors) {
            // acc = {...acc, [key]: childErrors};
            acc = [...acc, { name: key, errors: childErrors }];
        }
        return acc;
    }, []);
}

export function getInstallerCountryFromBUCode(country: string): string {
    const foundBU = buList.find((bu) => bu.value === country.toUpperCase());
    if (foundBU) {
        return foundBU.viewValue;
    }
    return '';
}

export function checkQRCodeString(str: string): boolean {
    const REMOTE_QR_CODE_LENGHT_SITUO = 56;
    const REMOTE_QR_CODE_LENGHT_KEYGO = 40;
    const regExp = new RegExp(/^[0-9A-F\r\n]+$/);
    return (
        regExp.test(str) && (str.length === REMOTE_QR_CODE_LENGHT_KEYGO || str.length === REMOTE_QR_CODE_LENGHT_SITUO)
    );
}

export class EnumHelpers {
    static getNames(e: any) {
        return EnumHelpers.getObjValues(e).filter((v) => typeof v === 'string') as string[];
    }

    static getValues<T extends number>(e: any) {
        return EnumHelpers.getObjValues(e).filter((v) => typeof v === 'number') as T[];
    }

    static getSelectList<T extends number, U>(e: any, stringConverter: (arg: U) => string) {
        const selectList = new Map<T, string>();
        this.getValues(e).forEach((val) => selectList.set(val as T, stringConverter(val as unknown as U)));
        return selectList;
    }

    private static getObjValues(e: any): (number | string)[] {
        return Object.keys(e).map((k) => e[k]);
    }
}

export function isDeviceRTSOrUnknown(device: DeviceMS): boolean {
    return device.protocol_profile === 'generic_rts' || device.protocol_profile === null;
}
