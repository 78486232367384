import { createSelector } from '@ngrx/store';
import { getCurrentIntervention } from '../../../interventions/store/selectors/interventions';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';
import { DeviceId } from '../../models/Device';
import { Execution } from '../../models/Execution';
import {
    buildPendingAccessTahomaAction,
    buildPendingDeviceAction,
    buildPendingRebootAction,
} from '../effects/buildPendingDeviceAction';
import { getRawDevice, getUiProperty } from './device-detail';

export const getPendingDeviceActionCreationData = (command: UpdateDeviceCommand, pendingTask: Execution) =>
    createSelector(
        getRawDevice(command.device.id),
        getUserLoginState,
        getUiProperty(command.device.id, command.property.id),
        (device, installer, property) =>
            buildPendingDeviceAction(device, installer.id, pendingTask, command, property.formatter),
    );

export const getPendingBoxRebootActionCreationData = (command: RebootBoxCommand, pendingTask: Execution) =>
    createSelector(getRawDevice(command.device.id), getUserLoginState, (device, installer) =>
        buildPendingRebootAction(device, installer.id, pendingTask, command),
    );

export const getTahomaAccessActionCreationData = (deviceId: DeviceId) =>
    createSelector(
        getRawDevice(deviceId),
        getUserLoginState,
        getCurrentIntervention,
        (device, installer, intervention) => buildPendingAccessTahomaAction(device, installer.id, intervention),
    );
