import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../shared/store/app-state';
import { CountryOption, getCountryValuesByPartners, getCountryValuesWithALLOption } from '../../utils/bu';
import { getCurrentUserPartner } from '../store/selectors/admin';

@Injectable()
export class CountriesService {
    private countries: CountryOption[] = [];

    constructor(
        private store: Store<AppState>,
        private translateService: TranslateService,
    ) {
        this.store.select(getCurrentUserPartner).subscribe((partners) => {
            if (!partners || partners.length === 0 || (partners.length > 0 && partners.includes('all'))) {
                this.countries = getCountryValuesWithALLOption();
            } else {
                this.countries = getCountryValuesByPartners(partners);
            }
        });
    }

    sortCountries = (a, b) => {
        if (a.code === 'all') {
            return -1;
        }
        if (b.code === 'all') {
            return 1;
        }
        return a.value.localeCompare(b.value);
    };

    getCountries() {
        const countries = this.countries;
        countries.forEach((country) => {
            country.value = this.translateService.instant(country.value);
        });
        return countries.sort(this.sortCountries);
    }
}
