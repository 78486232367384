import { createReducer, on } from '@ngrx/store';
import { DeviceId } from '../../models/Device';
import { Device, DeviceProperty, PropertyId } from '../../models/DeviceDetail';
import { ProfileId, UiProfile } from '../../models/UiProfile';
import { DeviceActions, DeviceLoadedEvent } from '../actions/device-detail';

export type DevicePropertiesStore = {
    [key: `${DeviceId}.${PropertyId}`]: DeviceProperty;
};

export type DeviceInfo = Omit<Device, 'properties'>;

export interface DeviceDetailState {
    activeDeviceId: DeviceId;
    devices: {
        [key: DeviceId]: DeviceInfo;
    };
    properties: DevicePropertiesStore;
    uiProfiles: {
        [key: ProfileId]: UiProfile;
    };
}

export const DEVICE_DETAIL_INITIAL_STATE: DeviceDetailState = {
    activeDeviceId: null,
    devices: {},
    properties: {},
    uiProfiles: {},
};

export default createReducer(
    DEVICE_DETAIL_INITIAL_STATE,

    on(DeviceActions.deviceSelected, (state: DeviceDetailState, { deviceId }): DeviceDetailState => {
        return { ...state, activeDeviceId: deviceId };
    }),

    on(
        DeviceActions.deviceLoaded,
        (state: DeviceDetailState, { device, uiProfile }: DeviceLoadedEvent): DeviceDetailState => {
            if (!device) {
                return state;
            }

            const { properties: initialProperties, ...deviceInfo } = device;
            const devices = { ...state.devices, [device.id]: deviceInfo };
            const uiProfiles = uiProfile ? { ...state.uiProfiles, [device.profileId]: uiProfile } : state.uiProfiles;

            const properties: DevicePropertiesStore = Object.values(initialProperties).reduce(
                (acc, property) => {
                    const k = `${device.id}.${property.id}`;
                    acc[k] = property;
                    return acc;
                },
                { ...state.properties },
            );

            return { ...state, devices, uiProfiles, properties };
        },
    ),

    on(DeviceActions.deviceUpdateCompleted, (state, { deviceId, stateId, value }): DeviceDetailState => {
        const propertyKey = `${deviceId}.${stateId}`;
        const property = { ...state.properties[propertyKey], value };

        const devices =
            stateId === 'available' && state.devices[deviceId]
                ? { ...state.devices, [deviceId]: { ...state.devices[deviceId], available: value } }
                : state.devices;

        return { ...state, devices, properties: { ...state.properties, [propertyKey]: property } };
    }),
);
