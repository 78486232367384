import { createActionGroup, props } from '@ngrx/store';
import { Alert, AlertId, TriggerId } from '../../../alerts/models/Alert';
import { EditedProperty } from '../../../site-detail/components/device-detail/DeviceGateway';
import { DeviceId, DevicePropertyValue } from '../../../site-detail/models/Device';
import { GatewayId } from '../../../site-detail/models/Gateway';
import { SiteId } from '../../../site-detail/models/Site';
import { AlertResolutionStep } from '../../models/AlertResolutionSteps';

export const AlertResolutionActions = createActionGroup({
    source: 'AlertResolution',
    events: {
        alertResolutionPageOpened: props<{
            alertId: AlertId;
        }>(),
        alertLoaded: props<Alert>(),
        alertResolutionPathApiSucceeded: props<{
            alertId: AlertId;
            triggerId: TriggerId;
            resolutionPath: AlertResolutionStep[];
        }>(),
        siteInfoLoaded: props<{
            alertId: AlertId;
            siteId: SiteId;
        }>(),
        deviceSelected: props<{
            deviceId: DeviceId;
        }>(),
        deviceGatewaySelected: props<{
            gatewayId: GatewayId;
        }>(),
        deviceNotFound: props<{
            alertId: AlertId;
            deviceId: DeviceId;
        }>(),
        deviceUpdateRequested: props<{
            deviceId: DeviceId;
            property: EditedProperty;
            initialValue: DevicePropertyValue;
            newValue: DevicePropertyValue;
        }>(),
        alertResolutionClosed: props<{
            alertId: AlertId;
        }>(),
        alertResolutionRequested: props<{
            alertId: AlertId;
        }>(),
        interventionsLoaded: props<{
            alertId: AlertId;
        }>(),
        interventionRequested: props<{
            siteId: SiteId;
        }>(),
        alertLoadingFailed: props<{
            alertId: AlertId;
            errorMessage: string;
        }>(),
        contactSupportRequested: props<{
            alertId: AlertId;
        }>(),
    },
});
