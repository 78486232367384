import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { DeviceId, DeviceMS } from '../../models/Device';
import { SiteId } from '../../models/Site';
import { SiteDevicesActions } from '../actions/devices';

type DeviceById = Record<DeviceId, DeviceMS>;

export type DevicesState = Record<SiteId, DeviceById>;

export const DEVICES_INITIAL_STATE: DevicesState = {};

export default createReducer(
    DEVICES_INITIAL_STATE,

    on(SiteDevicesActions.devicesLoaded, (state, { siteId, devices }): DevicesState => {
        const devicesCopy = devices.reduce((acc, device: DeviceMS) => {
            acc[device.id] = cleanDeviceFields(device);
            return acc;
        }, {});

        return { ...state, [siteId]: devicesCopy };
    }),

    on(SiteDevicesActions.deviceLoaded, SiteDevicesActions.deviceCreated, (state, { siteId, device }): DevicesState => {
        const siteDevices = state[siteId] || {};

        if (siteDevices[device.id]) {
            return state;
        }

        return {
            ...state,
            [siteId]: {
                ...siteDevices,
                [device.id]: cleanDeviceFields(device),
            },
        };
    }),

    on(SiteDevicesActions.deviceUpdated, (state, { siteId, device }): DevicesState => {
        const siteDevices = state[siteId] || {};

        if (!siteDevices[device.id]) {
            return state;
        }

        return {
            ...state,
            [siteId]: {
                ...siteDevices,
                [device.id]: cleanDeviceFields(device),
            },
        };
    }),

    on(SiteDevicesActions.deviceLabelUpdated, (state, { siteId, deviceId, deviceLabel }): DevicesState => {
        const siteDevices = state[siteId] || {};

        if (!siteDevices[deviceId]) {
            return state;
        }

        return {
            ...state,
            [siteId]: {
                ...siteDevices,
                [deviceId]: {
                    ...siteDevices[deviceId],
                    name: deviceLabel,
                },
            },
        };
    }),

    on(SiteDevicesActions.deviceRemoved, (state, { siteId, deviceId }): DevicesState => {
        const siteDevices = state[siteId] || {};

        if (!siteDevices[deviceId]) {
            return state;
        }

        const devicesCopy = { ...siteDevices };
        delete devicesCopy[deviceId];

        return {
            ...state,
            [siteId]: devicesCopy,
        };
    }),
);

export const getDevicesState = createFeatureSelector<DevicesState>('devices');

function cleanDeviceFields(rawDevice: DeviceMS): DeviceMS {
    return {
        ...rawDevice,
        uiType: rawDevice.protocol_profile?.replace(' ', '') ?? 'default',
        icon: rawDevice?.vertical_definition ?? 'generic',
        commercial_name: rawDevice.protocol_profile?.replace(' ', '') ?? null,
    };
}
