import { StaticViewerSpec } from '../../../../models/ViewerSpec';

type StaticViewer = 'rs100Compat';

export interface StaticViewerVm {
    type: StaticViewer;
    value?: null;
}

export default function buildStaticViewer(viewer: StaticViewerSpec): StaticViewerVm {
    return { type: viewer.component as StaticViewer, value: null };
}
