import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractToaster } from '../AbstractToaster';

@Component({
    selector: 'app-error-item',
    templateUrl: './error-item.component.html',
    styleUrls: ['./error-item.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class ErrorItemComponent extends AbstractToaster implements OnInit {}
