import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { AlertMailingConfigGateway } from '../../gateways/AlertMailingConfigGateway';
import { AlertsMailingConfigActions } from '../actions/alerts-mailing-config';

@Injectable()
export class AlertsMailingConfigEffects {
    subscribeToAlertMailing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsMailingConfigActions.subscriptionEnabled),
            debounceTime(500),
            switchMap(() =>
                this.alertMailingConfigGateway.subscribeToAlertMailing().pipe(
                    map(() => AlertsMailingConfigActions.apiSucceed({ operation: 'subscribe' })),
                    catchError(() => of(AlertsMailingConfigActions.apiFailed({ operation: 'subscribe' }))),
                ),
            ),
        ),
    );

    unsubscribeToAlertMailing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsMailingConfigActions.subscriptionDisabled),
            debounceTime(500),
            switchMap(() =>
                this.alertMailingConfigGateway.unsubscribeToAlertMailing().pipe(
                    map(() => AlertsMailingConfigActions.apiSucceed({ operation: 'unsubscribe' })),
                    catchError(() => of(AlertsMailingConfigActions.apiFailed({ operation: 'unsubscribe' }))),
                ),
            ),
        ),
    );

    changeMailingFrequency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsMailingConfigActions.frequencySelected),
            switchMap(({ frequency }) =>
                this.alertMailingConfigGateway.changeMailingFrequency(frequency).pipe(
                    map(() => AlertsMailingConfigActions.apiSucceed({ operation: 'change frequency' })),
                    catchError(() => of(AlertsMailingConfigActions.apiFailed({ operation: 'change frequency' }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('AlertMailingConfigGateway') private alertMailingConfigGateway: AlertMailingConfigGateway,
    ) {}
}
