import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileId, UiProfile } from '../../models/UiProfile';
import { UiProfileGateway } from './UiProfileGateway';

@Injectable()
export class HttpUiProfileGateway implements UiProfileGateway {
    constructor(private http: HttpClient) {}

    fetchUiProfile(profileId: ProfileId): Observable<UiProfile> {
        return this.http.get<UiProfile>(`./assets/definitionsUI/${profileId}.json`);
    }
}
