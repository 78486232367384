import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle],
})
export class LoaderComponent {
    @Input() fullPage = true;
    @Input() size = '100px';
    @Input() strokeWidth = '28%';
    @Input() showBackgroundCircle = true;
    @Input() visible: boolean;
}
