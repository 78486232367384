import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDeviceAlerts } from '../../../alerts/store/selectors/alerts';
import { triggerStateMapping } from '../../../alerts/triggerStateMapping';
import buildEditor, {
    DeviceEditablePropertyValue,
    SectionItemEditor,
} from '../../components/device-detail/editor-builder/buildEditor';
import resolveNotice from '../../components/device-detail/notice-resolver/resolve-notice';
import buildViewer from '../../components/device-detail/viewer-builder/buildViewer';
import { DeviceId, DevicePropertyValue } from '../../models/Device';
import { DeviceProperty, PropertyId } from '../../models/DeviceDetail';
import { ExecutionInfo, ExecutionStatus } from '../../models/Execution';
import { UiProfileProperty, UiProfileSection } from '../../models/UiProfile';
import { DeviceDetailState, DeviceInfo } from '../reducers/device-detail';
import emitOnChangeOnly from '../utils/emitOnChangeOnly';
import {
    DeviceDetail,
    Section,
    SectionItem,
    SectionItemAlert,
    SectionItemEditionData,
    SectionRefreshData,
} from '../view-models/device-detail';
import { isSelectedGatewayAvailable } from './gateways';
import { getCurrentIntervention, interventionInProgress } from '../../../interventions/store/selectors/interventions';
import { getUIDevicesSectionsSync, getUIDevicesStatesSync } from './ui';

export const getDeviceState = createFeatureSelector<DeviceDetailState>('device-detail');

export const getDevices = createSelector(getDeviceState, (deviceState) => deviceState.devices);

export const getUiProfiles = createSelector(getDeviceState, (deviceState) => deviceState.uiProfiles);

export const getDevice = (deviceId: DeviceId) => createSelector(getDevices, (devices) => devices[deviceId]);

export const getActiveDeviceId = createSelector(getDeviceState, (deviceState) => deviceState.activeDeviceId);

export const getDeviceProperties = createSelector(getDeviceState, (deviceState) => deviceState.properties);

export const getPropertiesKeys = emitOnChangeOnly(
    createSelector(getDeviceProperties, (properties) => Object.keys(properties)),
);

export const getUiProfile = (deviceId: DeviceId) =>
    createSelector(getUiProfiles, getDevice(deviceId), (uiProfiles, device) => uiProfiles[device?.profileId]);

export const getRawDevice = (deviceId: DeviceId) => createSelector(getDevice(deviceId), (device) => device?.rawData);

function getRefreshingStatus(
    gatewayAvailable: boolean,
    device: DeviceInfo,
    section: UiProfileSection,
    sectionRefreshTasks: ExecutionInfo[],
): SectionRefreshData {
    if (
        !section.refreshable ||
        (device.rawData.categories.includes('sensor') && device.rawData.product_profile !== 'window_sensor') ||
        (device.rawData.type === 'generic_zigbee' && device.rawData.categories.includes('remote_controller'))
    ) {
        return { status: 'none' };
    }
    if (!gatewayAvailable) {
        return { status: 'disabled', tooltip: 'REFRESH_SECTION_NOT_AVAILABLE_BECAUSE_GATEWAY_DOWN' };
    }
    if (!device.available) {
        return { status: 'disabled', tooltip: 'REFRESH_SECTION_NOT_AVAILABLE_BECAUSE_DEVICE_DOWN' };
    }

    const refreshTasksFound = !!sectionRefreshTasks?.some(
        (task) => task.deviceId === device.id && task.section === section.title,
    );

    return { status: refreshTasksFound ? 'inprogress' : 'enabled' };
}

export const getUiSections = (deviceId: DeviceId) =>
    createSelector(
        isSelectedGatewayAvailable,
        getDevice(deviceId),
        getPropertiesKeys,
        getUiProfile(deviceId),
        getUIDevicesSectionsSync,
        (gatewayAvailable, device, properties, uiProfile, sectionRefreshTasks): Section[] => {
            return (
                uiProfile?.sections
                    .map((section) => ({
                        title: section.title,
                        properties: device.deviceUrl.startsWith('zigbee')
                            ? computeZigbeeSectionProperties(device.id, properties, section)
                            : section.properties,
                        refresh: getRefreshingStatus(gatewayAvailable, device, section, sectionRefreshTasks),
                    }))
                    .filter((section) => section.properties.length > 0) || []
            );
        },
    );

export const getUiSection = (deviceId: DeviceId, sectionId: string) =>
    createSelector(getUiSections(deviceId), (sections) => sections?.find((section) => section.title === sectionId));

export const getUiProperties = (deviceId: DeviceId) =>
    createSelector(getUiProfile(deviceId), (uiProfile) => uiProfile?.properties ?? {});

export const getPropertyInfos = (deviceId: DeviceId, id: PropertyId) =>
    createSelector(getUiProperties(deviceId), (properties) => {
        const uiProperty = properties[id];
        return {
            id,
            label: uiProperty.label,
            writeType: uiProperty.writeType,
        };
    });

export const getLastDeviceUpdate = (deviceId: DeviceId) =>
    createSelector(getDevice(deviceId), (device) => device?.lastUpdateTime);

export const getDeviceProperty = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(getDeviceProperties, (properties) => properties[`${deviceId}.${propertyId}`]);

export const getDevicePropertyMetadata = (deviceId: DeviceId, id: PropertyId) =>
    createSelector(getDeviceProperty(deviceId, id), (property) => property?.metadata);

export const getUiProperty = (deviceId: DeviceId, id: PropertyId) =>
    createSelector(getUiProperties(deviceId), getDevicePropertyMetadata(deviceId, id), (uiProperties, metadata) => {
        const uiProperty = uiProperties[id];
        if (!metadata?.possibleValues) {
            return uiProperty;
        }
        return {
            ...uiProperty,
            editor: {
                type: 'namedValue',
                possibleValues: metadata.possibleValues,
            },
            formatter: {
                type: 'named-value',
                translationKey: uiProperty.label,
            },
        } as UiProfileProperty;
    });

export const propertyIsUpdating = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(getUIDevicesStatesSync, (executionInfos) => {
        const updateJob = executionInfos?.find(
            (executionInfos) => executionInfos.deviceId === deviceId && executionInfos.state === propertyId,
        );
        return updateJob && ![ExecutionStatus.COMPLETED, ExecutionStatus.FAILED].includes(updateJob?.status);
    });

// FIXME utilisé que dans les tests ?
export const getSectionItem = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(
        getUiProperty(deviceId, propertyId),
        getDeviceProperty(deviceId, propertyId),
        (uiProfile, property) => {
            const viewer = {
                type: uiProfile.formatter.type,
                properties: {},
            };
            return {
                label: uiProfile.label,
                viewer: viewer,
                data: property.value,
            };
        },
    );

function computeZigbeeSectionProperties(deviceId: DeviceId, properties: string[], section: UiProfileSection): string[] {
    return (
        section.properties
            .filter((propertyId) => properties.includes(`${deviceId}.${propertyId}`))
            // FIXME is there a better way to distinct product families?
            .filter(
                (propertyId) =>
                    propertyId !== 'core:ProductSoftwareBuildIdState' ||
                    !properties.includes(`${deviceId}.Identification.MotorSoftRelease`),
            )
    );
}

export const getDeviceInfos = (deviceId: DeviceId) => {
    return createSelector(getDevice(deviceId), getUiSections(deviceId), (device, sections): DeviceDetail => {
        if (device == null) {
            return null;
        }
        return {
            id: device.id,
            name: device.name,
            icon: device.icon,
            available: device.available,
            sections,
        };
    });
};

export const getServegoDefects = (deviceId: DeviceId) =>
    createSelector(getDeviceProperty(deviceId, 'servego_default'), (property: DeviceProperty): string[] => {
        return property?.value as string[];
    });

export const getSectionItemAlert = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(getDeviceAlerts(deviceId), (alerts): SectionItemAlert | undefined => {
        return alerts
            .filter((alert) => triggerStateMapping.get(alert.id_trigger) === propertyId)
            .map(({ id_trigger: triggerId, id, severity }) => ({ id, triggerId, severity }))[0];
    });

export const getSectionItemEdition = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(
        isSelectedGatewayAvailable,
        getDevice(deviceId),
        getUiProperty(deviceId, propertyId),
        getDeviceProperty(deviceId, propertyId),
        interventionInProgress,
        propertyIsUpdating(deviceId, propertyId),
        (
            gatewayAvailable,
            device,
            uiProperty,
            property,
            isInIntervention,
            isPropertyUpdating,
        ): SectionItemEditionData => {
            if (uiProperty == null) {
                return null;
            }

            if (!uiProperty.editable) {
                return { status: 'none' };
            }
            if (!isInIntervention) {
                return { status: 'disabled', tooltip: 'EQUIPMENT_EDIT_NOT_EDITABLE_HELP_TEXT' };
            }
            if (!gatewayAvailable || !device.available) {
                return { status: 'disabled', tooltip: 'EQUIPMENT_EDIT_NOT_AVAILABLE_HELP_TEXT' };
            }
            if (!property) {
                return { status: 'disabled', tooltip: 'EQUIPMENT_STATE_VALUE_NOT_AVAILABLE_TOAST_ERROR' };
            }
            if (isPropertyUpdating) {
                return { status: 'inprogress' };
            }

            return { status: 'enabled' };
        },
    );

function getAdvice(uiProperty: UiProfileProperty) {
    return uiProperty.advice ? { advice: uiProperty.advice } : {};
}

function getLabel(uiProperty: UiProfileProperty) {
    return uiProperty.label ? { label: uiProperty.label } : {};
}

function getNotice(uiProperty: UiProfileProperty, value: DevicePropertyValue) {
    return uiProperty.noticeRule ? { notice: resolveNotice(uiProperty.noticeRule, value as number) } : {};
}

function getStateName(uiProperty: UiProfileProperty, propertyId: string) {
    return uiProperty.viewer?.type !== 'static' ? { stateName: propertyId } : {};
}

export const getSectionItemDetail = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(
        getLastDeviceUpdate(deviceId),
        getDeviceProperty(deviceId, propertyId),
        getUiProperty(deviceId, propertyId),
        getSectionItemAlert(deviceId, propertyId),
        getSectionItemEdition(deviceId, propertyId),
        (timestamp, property, uiProperty, alert, edition): SectionItem => {
            return {
                ...getLabel(uiProperty),
                ...getAdvice(uiProperty),
                ...getNotice(uiProperty, property?.value),
                ...getStateName(uiProperty, propertyId),
                ...(alert ? { alert } : {}),
                viewer: buildViewer(uiProperty, property, { timestamp }),
                edition,
            };
        },
    );

export const getDeviceEditor = (deviceId: DeviceId, propertyId: PropertyId) =>
    createSelector(
        getDeviceProperty(deviceId, propertyId),
        getUiProperty(deviceId, propertyId),
        (property, uiProperty): SectionItemEditor => {
            if (property.value != null && !['string', 'number'].includes(typeof property.value)) {
                return;
            }
            return buildEditor(uiProperty, property.value as DeviceEditablePropertyValue, property.metadata);
        },
    );

export const getRefreshSectionData = (deviceId: DeviceId, sectionId) =>
    createSelector(getUiSection(deviceId, sectionId), getRawDevice(deviceId), (section, device) => ({
        section,
        device,
    }));

export const getUpdateDeviceStateData = (updateRequestedEvent) =>
    createSelector(getRawDevice(updateRequestedEvent.deviceId), getCurrentIntervention, (device, intervention) => ({
        device,
        property: updateRequestedEvent.property,
        newValue: updateRequestedEvent.newValue,
        sessionId: intervention.sessionId,
    }));
