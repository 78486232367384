const protocolProfileToProductTypeMapping = {
    // Window
    sliding_air: 'window',
    sliding_air_v1_v2: 'window',

    // Shutter
    altus_rs_io: 'shutter',
    oximo_40_io: 'shutter',
    oximo_io_cqfd: 'shutter',
    oximo_io_v1: 'shutter',
    oximo_redesign_io: 'shutter',
    oximo_s_auto_io: 'shutter',
    oximo_s_io_v1: 'shutter',
    rs100_solar_io_high_torque: 'shutter',
    rs100_solar_io_low_torque: 'shutter',
    sso_rs100_io_air: 'shutter',
    sso_rs100_io_hybrid: 'shutter',
    sso_rs100_io_phase1: 'shutter',
    sso_rs100_io_sans_ppp: 'shutter',
    ilimo_50_io: 'shutter',
    izymo_rs_micromodule: 'shutter',
    oximo_solar_io: 'shutter',
    slim_receiver_io: 'shutter',
    smoove_uno_io_curtain: 'shutter',
    smoove_uno_io_rs: 'shutter',
    smoove_uno_io_window: 'shutter',
    solus_50_io: 'shutter',

    // Awning
    sunea_40_io: 'awning',
    sunea_40_solar_io: 'awning',
    sunea_50_csi_io: 'awning',
    sunea_io_cqfd: 'awning',
    sunea_io_v1: 'awning',
    sunea_screen_io_cqfd: 'awning',
    sunea_screen_io_v1: 'awning',
    sunilus_io_cqfd: 'awning',
    sunilus_io_v1: 'awning',
    sunilus_screen_io_cqfd: 'awning',
    sunilus_screen_io_v1: 'awning',
    maestria_io: 'awning',
    pergola_gibus_io: 'awning',
    pergola_io: 'awning',
    pergola_io_cqfd: 'awning',
    pergola_louver: 'awning',
    pergola_rgs_io: 'awning',
    pergola_screen_io: 'awning',
    control_unit_pergola: 'awning',
    screen_slim_receiver_io: 'awning',
    slim_receiver_pergola_io: 'awning',
    awning_slim_receiver_io: 'awning',
    receiver_io_valance: 'awning',
    smoove_uno_awning: 'awning',
    smoove_uno_interior_blind: 'awning',
    smoove_uno_screen: 'awning',

    // Receiver
    lighting_receiver_io_variation: 'receiver',
    lighting_receiver_on_off: 'receiver',
    heating_receiver_io_dimming: 'receiver',
    heating_receiver_on_off: 'receiver',
    izymo_dimmable: 'receiver',
    izymo_on_off: 'receiver',
    on_off_plug_io: 'receiver',
    rgb_led_receiver: 'receiver',
    tuneable_white_led_receiver: 'receiver',
    white_led_receiver: 'receiver',

    // Sensor
    generic_sensor_io: 'sensor',
    intellitag_sliding: 'sensor',
    intellitag_tilt: 'sensor',
    sunis_wirefree_io: 'sensor',
    sunis_wirefree_io_2: 'sensor',
    thermis_v1: 'sensor',
    thermis_v2: 'sensor',

    // EVB
    j4_io: 'evb',
    j4_io_protect: 'evb',
    evb_slim_receiver_variation: 'evb',
    evb_slim_receiver_variation_io_wp: 'evb',
    smoove_uno_evb: 'evb',

    // Interior
    actuo_40_io: 'interior',
    optuo_40_io: 'interior',
    sonesse_40_io: 'interior',

    // Access Smart
    dexxo_smart_800_io: 'access smart',
    dexxo_smart_1000_io: 'access smart',
    elixo_smart_io: 'access smart',
    metro_smart_800_io: 'access smart',
    metro_smart_1000_io: 'access smart',
    rollixo_smart_io: 'access smart',
    roly_smart_io: 'access smart',

    // Access 3S
    axovia_3s_io_v1: 'access 3s',
    axovia_3s_io_v2: 'access 3s',
    axovia_200_3s_io_v1: 'access 3s',
    axovia_200_3s_io_v2: 'access 3s',
    elixo_3s_io_v1: 'access 3s',
    elixo_3s_io_v2: 'access 3s',
    invisio_3s_io_v1: 'access 3s',
    invisio_3s_io_v2: 'access 3s',
    ixengo_3s_io_v1: 'access 3s',
    ixengo_3s_io_v2: 'access 3s',
    dexxo_pro_io: 'access 3s',
    dexxo_pro_io_fermetures: 'access 3s',
    dexxo_pro_io_futurol: 'access 3s',
    rollixio_io_v1: 'access 3s',
};

export default protocolProfileToProductTypeMapping;
