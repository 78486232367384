export interface Duration {
    hours: number;
    minutes: number;
    seconds: number;
}

const SECOND_PER_HOURS = 3600;
const SECOND_PER_MINUTE = 60;

export function secondsToDuration(secs: number): Duration {
    const hours = Math.floor(secs / SECOND_PER_HOURS);
    const minutes = Math.floor((secs % SECOND_PER_HOURS) / SECOND_PER_MINUTE);
    const seconds = Math.round(secs % SECOND_PER_MINUTE);
    return { hours, minutes, seconds };
}

export function formatDuration(duration: number, durationUnit: 'seconds' | 'minutes' = 'seconds'): string {
    const durationInSeconds = durationUnit === 'minutes' ? duration * SECOND_PER_MINUTE : duration;
    const { hours, minutes, seconds } = secondsToDuration(durationInSeconds);
    const result = [];
    if (hours > 0) {
        result.push(`${hours}h`);
    }
    if (minutes > 0 || (durationUnit === 'minutes' && duration === 0)) {
        result.push(`${minutes}min`);
    }
    if (durationUnit === 'seconds' && (seconds > 0 || duration === 0)) {
        result.push(`${seconds}s`);
    }

    return result.join('\u00a0');
}

export function durationToSeconds(hours: number, minutes: number, seconds: number) {
    const hoursSeconds = hours * SECOND_PER_HOURS;
    const minSeconds = minutes * SECOND_PER_MINUTE;
    return hoursSeconds + minSeconds + seconds;
}
