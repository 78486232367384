<div class="full">
    <img class="background" src="assets/img/login-background.jpg">
    <div class="form-container">
        <div class="logo-header">
            <img class="somfy-logo" src="assets/img/app-somfy-logo.svg">
            <img class="serv-e-go-logo" src="assets/img/app-serv-e-go-logo.svg">
        </div>
        <div class="form-content">
            <div class="form-section logo">
                <img class="forbidden-logo" src="assets/img/forbidden.png">
            </div>
            <div class="form-section">
                <div>{{ 'UNAUTHORIZED_MESSAGE' | translate }}</div>
                <a (click)="onLeave()">{{ 'UNAUTHORIZED_LEAVE_LINK' | translate }}</a>
            </div>
        </div>
    </div>

</div>
