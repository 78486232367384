import { createAction, props } from '@ngrx/store';
import { MessageContent, MessageMS } from '../../models/Message';

export class MessagesActions {
    static readonly LOAD_MESSAGES = 'LOAD_MESSAGES';
    static readonly MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ';
    static readonly DELETE_MESSAGE = 'DELETE_MESSAGE';
    static readonly DELETE_ADMIN_MESSAGE = 'DELETE_ADMIN_MESSAGE';
    static readonly LOAD_ADMIN_MESSAGES = 'LOAD_ADMIN_MESSAGES';
}

export const loadMessages = createAction(MessagesActions.LOAD_MESSAGES, props<{ messages: MessageMS[] }>());

export const markMessageAsRead = createAction(MessagesActions.MARK_MESSAGE_AS_READ, props<{ messageId: string }>());

export const deleteMessage = createAction(MessagesActions.DELETE_MESSAGE, props<{ messageId: string }>());

export const loadAdminMessages = createAction(
    MessagesActions.LOAD_ADMIN_MESSAGES,
    props<{ messages: MessageContent[] }>(),
);

export const deleteAdminMessage = createAction(MessagesActions.DELETE_ADMIN_MESSAGE, props<{ messageId: string }>());
