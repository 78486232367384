import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import type { SiteId } from '../../../site-detail/models/Site';
import type { Intervention } from '../../models/Intervention';
import { InterventionService } from '../../services/intervention.service';
import { InterventionActions } from '../actions/interventions';

@Injectable()
export class InterventionsApi {
    constructor(
        private store: Store<AppState>,
        private interventionsService: InterventionService,
    ) {}

    loadSiteInterventions(siteId: SiteId): Observable<Intervention[]> {
        return this.interventionsService.getInterventions(siteId).pipe(
            map((interventions: Intervention[]) => {
                this.store.dispatch(InterventionActions.loadSiteInterventions({ interventions }));
                return interventions;
            }),
        );
    }
}
