import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndUserUpdateForm } from '../../shared/models/User';
import { HttpService } from '../../shared/services/http.service';
import { buildSiteFromApiResult } from '../../utils/buildSiteFromApiResult';
import { GatewayId } from '../models/Gateway';
import { OnBoardingUpdateRequest, Site, SiteUserCreateRequest } from '../models/Site';
import { SiteGateway } from './SiteGateway';

@Injectable()
export class SiteService implements SiteGateway {
    constructor(private httpService: HttpService) {}

    getSite(id: string): Observable<Site> {
        return this.httpService.get(`/site/${id}`).pipe(map(buildSiteFromApiResult));
    }

    setConnection(site: string, idUser: string, mail: any): Observable<any> {
        const siteUser: SiteUserCreateRequest = {
            id_site: site,
            id_user: idUser,
            mail: mail,
        };
        return this.httpService.patch('/site-connection', siteUser);
    }

    public getSiteConnection(): Observable<any> {
        return this.httpService.get('/site-connection');
    }

    // behave like a toggle: each time it's call, it's flip status between true/false
    public updateOnboardingStatus(bool: boolean): Observable<any> {
        const req: OnBoardingUpdateRequest = {
            skipOnboarding: bool,
        };
        return this.httpService.patch('/site-connection/onboarding', req);
    }

    setActivationServego(gatewayId: GatewayId): Observable<GatewayId> {
        return this.httpService.patch(`/site/activate/${gatewayId}/gateway`, gatewayId);
    }

    updateEndUserInformations(site: Site, endUserUpdate: EndUserUpdateForm): Observable<any> {
        return this.httpService.patch(`/site/${site.id}/owner`, endUserUpdate);
    }

    public deleteInstallerSite(siteId: string): Observable<void> {
        return this.httpService.delete(`/site/${siteId}`);
    }
}
