import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { AppState } from '../../../shared/store/app-state';
import { SitesList } from '../../models/sites';
import { HttpSitesGateway } from '../../services/http-sites-gateway.service';
import { SitesActions, SitesPageActions } from '../actions/sites';
import { getUISiteFilters } from '../selectors/ui';

@Injectable()
export class SitesEffects {
    loadSitesOnPageOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesPageActions.opened, AppActions.initialized),
            switchMap(() => this.store.select(getUISiteFilters).pipe(take(1))),
            switchMap(({ keywords }) =>
                this.sitesService
                    .getSites({ keywords })
                    .pipe(map((sitesList: SitesList) => SitesActions.sitesLoaded({ keywords, ...sitesList }))),
            ),
        ),
    );

    onSitesSearched$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.sitesSearched),
            switchMap(({ keywords }) =>
                this.sitesService
                    .getSites({ keywords })
                    .pipe(map((sitesList: SitesList) => SitesActions.sitesLoaded({ keywords, ...sitesList }))),
            ),
        ),
    );

    fetchMoreSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.loadSitesRequested),
            switchMap(({ filters }) =>
                this.sitesService
                    .getSites(filters)
                    .pipe(
                        map((sitesList: SitesList) =>
                            SitesActions.sitesLoaded({ keywords: filters.keywords, ...sitesList }),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private sitesService: HttpSitesGateway,
        private store: Store<AppState>,
    ) {}
}
