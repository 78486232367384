import { Injectable } from '@angular/core';
import { Selector, Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';
import { AppState } from '../store/app-state';

@Injectable({
    providedIn: 'root',
})
export class StoreService<T> {
    constructor(private store: Store<AppState>) {}

    select<V>(selector: Selector<T, V>): Observable<V> {
        return this.store.select(selector).pipe(first());
    }
}
