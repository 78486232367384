import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { removeSubjectFromList } from '../../../store/reducers/admin';
import { MaintenancePeriod } from '../model/maintenance-period';
import { addCreatedMaintenancePeriod, loadMaintenancePeriods, removeDeletedMaintenancePeriod } from './actions';

export interface AdminMaintenancePeriodState {
    maintenancePeriods: MaintenancePeriod[];
}

export const ADMIN_MAINTENANCE_PERIOD_INITIAL_STATE: AdminMaintenancePeriodState = {
    maintenancePeriods: [],
};

export default createReducer(
    ADMIN_MAINTENANCE_PERIOD_INITIAL_STATE,

    on(
        addCreatedMaintenancePeriod,
        (state, { maintenancePeriod }): AdminMaintenancePeriodState => ({
            ...state,
            maintenancePeriods: [...state.maintenancePeriods, maintenancePeriod].sort((a, b) =>
                a.noticeDate > b.noticeDate ? -1 : 1,
            ),
        }),
    ),

    on(
        loadMaintenancePeriods,
        (state, { maintenancePeriods }): AdminMaintenancePeriodState => ({
            ...state,
            maintenancePeriods,
        }),
    ),

    on(removeDeletedMaintenancePeriod, (state, { id }): AdminMaintenancePeriodState => {
        const maintenancePeriod = state.maintenancePeriods.find((mp) => mp.id === id);
        if (!maintenancePeriod) {
            return state;
        }

        const maintenancePeriods = removeSubjectFromList(state.maintenancePeriods, maintenancePeriod);
        return { ...state, maintenancePeriods };
    }),
);

export const getAdminMaintenancePeriodState =
    createFeatureSelector<AdminMaintenancePeriodState>('admin-maintenance-periods');
