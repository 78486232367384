import { createSelector } from '@ngrx/store';
import { Site } from '../../models/Site';
import { DevicesState, getDevicesState } from '../reducers/devices';
import { getSelectedSite } from './site';

export const getSelectedSiteDevices = createSelector(
    getSelectedSite,
    getDevicesState,
    (selectedSite: Site | null, state: DevicesState) => {
        return Object.values(state[selectedSite?.id] ?? {});
    },
);
