import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { removeSite } from '../../../site-detail/store/actions/site';
import { SitesList } from '../../../site-list/models/sites';
import { HttpSitesGateway } from '../../../site-list/services/http-sites-gateway.service';
import { SitesPageActions } from '../../../site-list/store/actions/sites';
import { HttpFavoriteSitesGateway } from '../../services/http-favorite-sites-gateway.service';
import { FavoriteSitesActions } from '../actions/favorite-site';
import { MAX_FAVORITES } from '../selectors/favorite-site';

@Injectable()
export class FavoriteSitesEffects {
    loadFavoritesOnPageOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesPageActions.opened),
            switchMap(() => this.sitesService.getSites({ limit: MAX_FAVORITES, orderBy: 'customer_favorite' })),
            map((sitesList: SitesList) =>
                FavoriteSitesActions.favoritesLoaded({ sites: sitesList.sites.filter((s) => s.favorite) }),
            ),
        ),
    );

    removeSiteFromFavorites$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FavoriteSitesActions.favoriteRemoved),
                switchMap(({ siteId }) => this.favoriteSiteService.removeFavorite(siteId)),
            ),
        { dispatch: false },
    );

    removeFavoriteIfSiteHasBeenDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeSite),
            map(({ siteId }) => FavoriteSitesActions.favoriteRemoved({ siteId })),
        ),
    );

    constructor(
        private actions$: Actions,
        private sitesService: HttpSitesGateway,
        private favoriteSiteService: HttpFavoriteSitesGateway,
    ) {}
}
