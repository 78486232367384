import buildViewer, {
    type SectionItemViewer,
} from '../../site-detail/components/device-detail/viewer-builder/buildViewer';
import type { DeviceProperty } from '../../site-detail/models/DeviceDetail';
import type { UiProfileProperty } from '../../site-detail/models/UiProfile';
import type { ExpectedAlertResolutionStateValues } from '../models/AlertResolutionSteps';

export function buildExpectedViewers(
    expectedValues: ExpectedAlertResolutionStateValues | undefined,
    uiProperty: UiProfileProperty,
    property: DeviceProperty,
    timestamp: number,
): SectionItemViewer[] {
    if (expectedValues == undefined) {
        return [];
    }
    const values = Array.isArray(expectedValues) ? expectedValues : [expectedValues];

    return values.map((value) => buildViewer(uiProperty, { ...property, value }, { timestamp }));
}
