import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import ENV_NAMES from '../../../environments/ENV_NAMES';
import { TriggerId } from '../../alerts/models/Alert';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { getMinuteTimestamp } from '../../utils/date';
import { DEFAULT_LOCALE } from '../../utils/url';
import { AlertResolutionStep } from '../models/AlertResolutionSteps';
import { AlertResolutionGateway } from './AlertResolutionGateway';

@Injectable()
export class HttpAlertResolutionGateway implements AlertResolutionGateway {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    getResolutionPath(triggerId: TriggerId): Observable<AlertResolutionStep[]> {
        return this.http
            .get<
                AlertResolutionStep[]
            >(`https://storage.googleapis.com/servego/${this.getResolvePathDirectory()}/resolve-paths/${triggerId}.json?${getMinuteTimestamp()}`)
            .pipe(map((apiResult) => [...apiResult, { text: 'SUPPORT_CONTACT_STEP_TEXT', image: 'support-contact' }]));
    }

    getTranslations(language: string): Observable<any> {
        return this.http
            .get(this.getTranslationPath(language))
            .pipe(catchError(() => this.http.get(this.getTranslationPath(DEFAULT_LOCALE))));
    }

    private getResolvePathDirectory() {
        return this.appConfig.name === ENV_NAMES.PROD ? 'alert-resolution' : 'alert-resolution-draft';
    }

    private getTranslationPath(language: string) {
        return `https://storage.googleapis.com/servego/alert-resolution/i18n/${language}.json?${getMinuteTimestamp()}`;
    }
}
