import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { AppState } from '../../../store/app-state';
import { AbstractToaster } from '../AbstractToaster';

@Component({
    selector: 'app-mail-received-item',
    templateUrl: './mail-received-item.component.html',
    styleUrls: ['./mail-received-item.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class MailReceivedItemComponent extends AbstractToaster implements OnInit {
    public messageToDisplay: string;

    constructor(
        protected translateService: TranslateService,
        private router: Router,
        protected store: Store<AppState>,
    ) {
        super(translateService, store);
    }

    ngOnInit() {
        if (this.content) {
            const MAX_MESSAGE_LENGTH = 30;
            if (this.content.message.length > MAX_MESSAGE_LENGTH) {
                this.messageToDisplay = `${this.content.message.slice(0, MAX_MESSAGE_LENGTH)}...`;
            } else {
                this.messageToDisplay = this.content.message;
            }
        }
    }

    onToasterClick() {
        this.router.navigate([`/messages`]);
        this.store.dispatch(removeToasterMessage({ id: this.id }));
        clearTimeout(this.timeoutRef);
    }
}
