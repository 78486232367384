export function replaceUpdatedElementIntoListById<T extends { id: string }>(
    initialList: ReadonlyArray<T>,
    updatedElement: T,
): T[] {
    return replaceUpdatedElementIntoListBy(['id'], initialList, updatedElement);
}

export function replaceUpdatedElementIntoListByName<T extends { name: string }>(
    initialList: ReadonlyArray<T>,
    updatedElement: T,
): T[] {
    return replaceUpdatedElementIntoListBy(['name'], initialList, updatedElement);
}

export function replaceUpdatedElementIntoListBy<T>(
    propertyNames: Array<keyof T>,
    initialList: ReadonlyArray<T>,
    updatedElement: T,
): T[] {
    const updatedElementIndex = initialList.findIndex((i) => propertyNames.every((k) => i[k] == updatedElement[k]));

    if (updatedElementIndex === -1) {
        return [...initialList, updatedElement];
    }

    return [
        ...initialList.slice(0, updatedElementIndex),
        updatedElement,
        ...initialList.slice(updatedElementIndex + 1),
    ];
}
