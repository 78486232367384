<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (link of links; track link.path) {
    <a mat-tab-link
       (click)="navigateTo(link)"
       [active]="activeLink === link.path">
      <span class="icon {{ link.path }}"></span>
      <span class="label">{{ link.label | translate }}</span>
      <span class="number-element">{{ nbResults[link.path] }}</span>
    </a>
  }
</nav>

<!-- unused panel but required by nav bar  -->
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
