import { createSelector } from '@ngrx/store';
import { AlertSeverity } from '../../../alerts/models/Alert';
import { getSelectedSiteAlerts, getSiteAlerts } from '../../../alerts/store/selectors/alerts';
import { getUserLanguage } from '../../../shared/store/selectors/user-env';
import { sortDevices, sortGateways } from '../../../utils';
import { DeviceId, DeviceMS } from '../../models/Device';
import { GatewayId, GatewayListItem } from '../../models/Gateway';
import { SiteId } from '../../models/Site';
import { getGatewaysState } from '../reducers/gateways';
import { DeviceListItemVm } from '../view-models/device';
import { GatewayListItemVm } from '../view-models/gateway-list';
import { getSelectedSiteDevices } from './devices';
import { getSelectedSite } from './site';

export interface GatewaySelection {
    deviceId: DeviceId;
    gatewayId: GatewayId;
}

export interface GatewayDevicesStatus {
    hasValidDevices: boolean;
    error?: string;
}

function isHub(device: DeviceMS) {
    return device.categories.includes('hub');
}

function isSupportedDevice(device: DeviceMS) {
    return !device.deviceurl.startsWith('rts:');
}

function isDeviceAttachedToGateway(device: DeviceMS, gatewayId: GatewayId) {
    return device.deviceurl.includes(gatewayId) && !isHub(device);
}

function checkGatewaysDevices(devices: DeviceMS[]) {
    if (devices.length === 0) {
        return 'NO_DEVICES_TO_DISPLAY_FOR_THIS_INSTALLATION';
    }

    if (!devices.some((device) => isSupportedDevice(device))) {
        return 'NO_DEVICES_IN_THIS_INSTALLATION';
    }
    return null;
}

function deviceHasDefect(device: DeviceMS) {
    const deviceDefectsState = device.states.find((s) => s.name === 'servego_default');
    return (deviceDefectsState?.value as string[])?.length > 0;
}

export const getDeviceGatewayId = (deviceId) =>
    createSelector(getSelectedSiteDevices, (siteDevices) => {
        const device = siteDevices.find((siteDevice) => siteDevice.id === deviceId);
        return device?.deviceurl.split('/')[2];
    });

export const getSelectedGatewayDevice = createSelector(
    getSelectedSiteDevices,
    getGatewaysState,
    (devices, gatewayState): DeviceMS => {
        const gatewayId = gatewayState.selectedGatewayId;
        return devices.find((siteDevice) => siteDevice.deviceurl === gatewayId);
    },
);

export const getGatewaySelection = createSelector(
    getSelectedGatewayDevice,
    getGatewaysState,
    (gatewayDevice, gatewayState): GatewaySelection => {
        return gatewayState?.selectedGatewayId
            ? { gatewayId: gatewayState?.selectedGatewayId, deviceId: gatewayDevice?.id }
            : null;
    },
);

export const isSelectedGatewayAvailable = createSelector(
    getSelectedSite,
    getGatewaysState,
    (selectedSite, gatewayState): boolean => {
        const siteGateways: GatewayListItem[] = gatewayState.gateways[selectedSite?.id];
        return !!siteGateways?.find((gateway) => gatewayState.selectedGatewayId === gateway.gatewayId)?.available;
    },
);

const getSelectedGatewayDevices = createSelector(
    getSelectedSiteDevices,
    getGatewaySelection,
    (devices, gateway): DeviceMS[] => devices.filter((device) => isDeviceAttachedToGateway(device, gateway?.gatewayId)),
);

const getDeviceSeverities = createSelector(
    getSelectedGatewayDevices,
    getSelectedSiteAlerts,
    (gatewayDevices, siteAlerts) => {
        const severityMap: Record<DeviceId, AlertSeverity> = {};

        gatewayDevices.forEach((device) => {
            const severities = siteAlerts
                .filter((alert) => alert.id_device === device.id)
                .map((alert) => alert.severity);

            severityMap[device.id] = Math.max(...severities) as AlertSeverity;
        });

        return severityMap;
    },
);

export const getSelectedGatewayDevicesListItems = createSelector(
    getSelectedGatewayDevices,
    getUserLanguage,
    getDeviceSeverities,
    (gatewayDevices, language, deviceSeverities): DeviceListItemVm[] => {
        return gatewayDevices
            .map((device) => {
                return {
                    id: device.id,
                    icon: device.icon,
                    deviceUrl: device.deviceurl,
                    name: device.name,
                    protocolProfile: device.protocol_profile,
                    commercialName: device.commercial_name,
                    hasDefect: deviceHasDefect(device),
                    severity: deviceSeverities[device.id] ?? null,
                };
            })
            .sort((deviceA: DeviceListItemVm, deviceB: DeviceListItemVm) => sortDevices(deviceA, deviceB, language));
    },
);

export const getSelectedGatewayDevicesStatus = createSelector(
    getSelectedSiteDevices,
    getGatewaySelection,
    (siteDevices, selectedGateway): GatewayDevicesStatus => {
        const devices = siteDevices.filter((device) => isDeviceAttachedToGateway(device, selectedGateway?.gatewayId));
        if (!devices) {
            return;
        }
        const devicesError = checkGatewaysDevices(devices);
        return {
            hasValidDevices: devices.some((device) => isSupportedDevice(device)),
            ...(devicesError ? { error: devicesError } : {}),
        };
    },
);

const getSiteGateways = (siteId: SiteId) =>
    createSelector(getGatewaysState, (gatewaysState) => gatewaysState.gateways[siteId] ?? []);

const siteDevicesByGateway = createSelector(
    getSelectedSiteDevices,
    (devices): Record<GatewayId, DeviceMS[]> =>
        devices
            .filter((device) => !isHub(device))
            .reduce((acc, device) => {
                const gatewayId = device.deviceurl.split('/')[2];
                if (gatewayId in acc) {
                    acc[gatewayId].push(device);
                } else {
                    acc[gatewayId] = [device];
                }
                return acc;
            }, {}),
);

export const getSortedSiteGateways = (siteId: SiteId) =>
    createSelector(
        getSiteGateways(siteId),
        siteDevicesByGateway,
        getSiteAlerts(siteId),
        getUserLanguage,
        (gateways, devicesByGateway, alerts, language): GatewayListItemVm[] => {
            return gateways
                .map((gateway) => {
                    const gatewayDevices = devicesByGateway[gateway.gatewayId] ?? [];
                    const gatewayDevicesIds = gatewayDevices.map((device) => device.id);
                    const gatewayAlerts = alerts.filter((alert) => gatewayDevicesIds.includes(alert.id_device));
                    const isZigbee = gatewayDevices.some((device) => device.deviceurl.startsWith('zigbee://'));
                    if (gatewayAlerts.length > 0) {
                        return {
                            ...gateway,
                            isZigbee,
                            maxAlertSeverity: Math.max(
                                ...gatewayAlerts.map((alert) => alert.severity),
                            ) as AlertSeverity,
                        };
                    }
                    return { ...gateway, isZigbee };
                })
                .sort((gateway1, gateway2) => sortGateways(gateway1, gateway2, language));
        },
    );

export const getDefaultSiteGateway = (siteId: SiteId) =>
    createSelector(getSortedSiteGateways(siteId), (gateways) => gateways[0]?.gatewayId);
