import { createReducer, on } from '@ngrx/store';
import type { PendingInterventionCreateRequest } from '../../../interventions/models/PendingInterventionCreateRequest';
import { replaceUpdatedElementIntoListBy } from '../../../utils/replaceUpdatedElementIntoList';
import { DeviceId } from '../../models/Device';
import { DeviceStateUpdateExecutionInfo, Execution, ExecutionInfo, ExecutionStatus } from '../../models/Execution';
import { SectionExecutionInfo, SectionExecutionStatus } from '../../models/SectionExecutionInfo';
import { SiteId } from '../../models/Site';
import { BoxActions } from '../actions/box-detail';
import * as UIActions from '../actions/ui';

export type DeviceSectionExecutionInfo = Record<DeviceId, Array<SectionExecutionInfo>>;

export interface UISiteDetailState {
    id: string;
    devicesStatesSync: DeviceStateUpdateExecutionInfo[];
    devicesSectionsSync: ExecutionInfo[];
    siteSectionExecutionInfo: Record<SiteId, DeviceSectionExecutionInfo>;
    loadingSiteContentMessage: string;
    waitingForTahomaAppOpening: boolean;
    manualExecutions: Execution[];
    pendingInterventionActions: PendingInterventionCreateRequest[];
}

export const SITE_DETAILS_INITIAL_STATE: UISiteDetailState = {
    id: null,
    devicesStatesSync: [],
    devicesSectionsSync: [],
    siteSectionExecutionInfo: {},
    loadingSiteContentMessage: null,
    waitingForTahomaAppOpening: false,
    manualExecutions: [],
    pendingInterventionActions: [],
};

export default createReducer(
    SITE_DETAILS_INITIAL_STATE,

    on(
        UIActions.addManualExecution,
        (state, { exec }): UISiteDetailState => ({
            ...state,
            manualExecutions: [...state.manualExecutions, exec],
        }),
    ),

    on(UIActions.removeManualExecution, (state, { exec }): UISiteDetailState => {
        const foundExecToRemove = state.manualExecutions.find((e) => e.job_id === exec.job_id);
        if (!foundExecToRemove) {
            return state;
        }

        const existingExecIndex = state.manualExecutions.indexOf(foundExecToRemove);
        const manualExecutions = [
            ...state.manualExecutions.slice(0, existingExecIndex),
            ...state.manualExecutions.slice(existingExecIndex + 1),
        ];
        return { ...state, manualExecutions };
    }),

    on(UIActions.updateManualExecution, (state, { exec }): UISiteDetailState => {
        const execToUpdate = state.manualExecutions.find((e) => e.job_id === exec.job_id);
        if (!execToUpdate) {
            return state;
        }

        const execToUpdateIndex = state.manualExecutions.indexOf(execToUpdate);
        const updatedExec = { ...execToUpdate, ...exec };
        const updatedList = [
            ...state.manualExecutions.slice(0, execToUpdateIndex),
            updatedExec,
            ...state.manualExecutions.slice(execToUpdateIndex + 1),
        ];

        return { ...state, manualExecutions: updatedList };
    }),

    on(
        UIActions.setSelectedSite,
        (state, { siteID }): UISiteDetailState => ({
            ...state,
            id: siteID,
        }),
    ),

    on(
        UIActions.addPendingInterventionAction,
        (state, { interventionAction }): UISiteDetailState => ({
            ...state,
            pendingInterventionActions: [...state.pendingInterventionActions, interventionAction],
        }),
    ),

    on(UIActions.removePendingInterventionAction, (state, { interventionAction }): UISiteDetailState => {
        const foundActionToRemove = state.pendingInterventionActions.find(
            (act: PendingInterventionCreateRequest) => act.job_id === interventionAction.job_id,
        );
        if (!foundActionToRemove) {
            return state;
        }

        const existingActionIndex = state.pendingInterventionActions.indexOf(foundActionToRemove);
        const pendingInterventionActions = [
            ...state.pendingInterventionActions.slice(0, existingActionIndex),
            ...state.pendingInterventionActions.slice(existingActionIndex + 1),
        ];

        return { ...state, pendingInterventionActions };
    }),

    on(UIActions.addDeviceStateToSync, (state, { siteId, deviceId, state: stateInfo }): UISiteDetailState => {
        const deviceStateUpdateExecutionInfo: DeviceStateUpdateExecutionInfo = {
            deviceId,
            job_id: '',
            siteId: siteId,
            state: stateInfo['stateName'],
            newValue: stateInfo['newValue'],
            oldValue: stateInfo['oldValue'],
            status: ExecutionStatus.INITIALIZED,
            date: Date.now(),
        };

        const devicesStatesSync = [...state.devicesStatesSync].filter(
            (executionInfo) =>
                executionInfo.siteId !== siteId ||
                executionInfo.deviceId !== deviceId ||
                executionInfo.state !== stateInfo['stateName'],
        );
        devicesStatesSync.push(deviceStateUpdateExecutionInfo);

        return { ...state, devicesStatesSync };
    }),

    on(UIActions.removeDeviceStateToSync, (state, { jobId }): UISiteDetailState => {
        const devicesStatesSync = [...state.devicesStatesSync].filter(
            (executionInfo: DeviceStateUpdateExecutionInfo) => executionInfo.job_id !== jobId,
        );

        return { ...state, devicesStatesSync };
    }),

    on(UIActions.updateDeviceStateToSyncExecutionStatus, (state, { siteId, execution }): UISiteDetailState => {
        const devicesStatesSyncCopy = [...state.devicesStatesSync];
        const foundStateExecution = state.devicesStatesSync.find(
            (d) => d.siteId === siteId && d.deviceId === execution.deviceId && d.state === execution.state,
        );

        let devicesStatesSync;
        if (foundStateExecution) {
            devicesStatesSync = replaceUpdatedElementIntoListBy(
                ['siteId', 'deviceId', 'state'],
                devicesStatesSyncCopy,
                {
                    ...foundStateExecution,
                    status: execution.status,
                    job_id: execution.job_id,
                },
            );
        } else {
            devicesStatesSync = devicesStatesSyncCopy;
        }

        // Remove all execution with completed status
        devicesStatesSync = devicesStatesSync.filter((executionInfo: DeviceStateUpdateExecutionInfo) => {
            return (
                executionInfo.status !== ExecutionStatus.COMPLETED && executionInfo.status !== ExecutionStatus.FAILED
            );
        });

        if (!foundStateExecution && devicesStatesSync.length === state.devicesStatesSync.length) {
            return state;
        }

        return { ...state, devicesStatesSync };
    }),

    on(UIActions.updateDeviceStateToSyncExecutionStatusById, (state, { execution }): UISiteDetailState => {
        const devicesStatesSyncCopy = [...state.devicesStatesSync];
        // Look for state
        const foundStateExecution = devicesStatesSyncCopy.find((s) => s.job_id === execution.job_id);

        let devicesStatesSync;
        if (foundStateExecution) {
            devicesStatesSync = replaceUpdatedElementIntoListBy(['job_id'], devicesStatesSyncCopy, {
                ...foundStateExecution,
                status: execution.status,
            });
        } else {
            devicesStatesSync = devicesStatesSyncCopy;
        }

        if (!foundStateExecution && devicesStatesSync.length === state.devicesStatesSync.length) {
            return state;
        }

        return { ...state, devicesStatesSync };
    }),

    on(UIActions.addDeviceSectionToSync, (state, { siteId, sectionTitle, executions, deviceId }): UISiteDetailState => {
        const devicesSectionsSyncCopy = [...state.devicesSectionsSync].filter(
            (executionInfo) =>
                executionInfo.section !== sectionTitle || executionInfo.deviceId !== executions[0].deviceId,
        );

        const uniqExec = new Set();
        const numberOfExecutions = executions.filter((e) => !uniqExec.has(e.job_id) && uniqExec.add(e.job_id)).length;

        executions.forEach((exec) => {
            const executionInfo: ExecutionInfo = {
                deviceId: exec.deviceId,
                job_id: exec.job_id,
                section: sectionTitle,
                siteId: siteId,
                state: exec.state,
                status: exec.status,
                date: Date.now(),
            };
            devicesSectionsSyncCopy.push(executionInfo);
        });

        const uniqSections = new Set();
        const devicesSectionsSync = devicesSectionsSyncCopy.filter((s) => {
            const k = `${s.section}-${s.state}-${s.job_id}`;
            return !uniqSections.has(k) && uniqSections.add(k);
        });

        const siteSectionExecutionInfo = addSectionRefresh(
            state.siteSectionExecutionInfo,
            siteId,
            deviceId,
            sectionTitle,
            numberOfExecutions,
        );
        return { ...state, devicesSectionsSync, siteSectionExecutionInfo };
    }),

    on(UIActions.clearDeviceSectionSyncState, (state, { siteId, sectionTitle, deviceId }): UISiteDetailState => {
        const uniqJobIds = new Set();
        const devicesSectionsSync = [...state.devicesSectionsSync]
            .filter((e) => e.section !== sectionTitle || e.deviceId !== deviceId)
            .filter((e) => !uniqJobIds.has(e.job_id) && uniqJobIds.add(e.job_id));

        const siteSectionExecutionInfo = removeSectionRefresh(
            state.siteSectionExecutionInfo,
            siteId,
            deviceId,
            sectionTitle,
        );
        return { ...state, devicesSectionsSync, siteSectionExecutionInfo };
    }),

    on(UIActions.updateExecutionStatus, (state, { execution }): UISiteDetailState => {
        let devicesSectionsSyncCopy = [...state.devicesSectionsSync];
        let foundExecution = devicesSectionsSyncCopy.find((s) => s.job_id === execution.job_id);

        let siteSectionExecutionInfo = { ...state.siteSectionExecutionInfo };
        if (foundExecution) {
            foundExecution = { ...foundExecution, status: execution['status'] };
            devicesSectionsSyncCopy = replaceUpdatedElementIntoListBy(
                ['job_id'],
                devicesSectionsSyncCopy,
                foundExecution,
            );

            if (
                foundExecution.status === ExecutionStatus.COMPLETED ||
                foundExecution.status === ExecutionStatus.FAILED
            ) {
                siteSectionExecutionInfo = decreaseSectionRefreshCount(
                    state.siteSectionExecutionInfo,
                    foundExecution.siteId,
                    foundExecution.deviceId,
                    foundExecution.section,
                    foundExecution.status,
                );
            }
        }

        const uniqJobIds = new Set();
        const devicesSectionsSync = devicesSectionsSyncCopy
            .filter((e) => e.status !== ExecutionStatus.COMPLETED && e.status !== ExecutionStatus.FAILED)
            .filter((e) => !uniqJobIds.has(e.job_id) && uniqJobIds.add(e.job_id));

        return { ...state, devicesSectionsSync, siteSectionExecutionInfo };
    }),

    on(UIActions.setLoadingSiteContentMessage, (state, { loadingMessage }): UISiteDetailState => {
        return { ...state, loadingSiteContentMessage: loadingMessage };
    }),

    on(BoxActions.openTahomaAppRequested, (state): UISiteDetailState => {
        return { ...state, waitingForTahomaAppOpening: true };
    }),

    on(
        BoxActions.openTahomaAppFailed,
        (state): UISiteDetailState => ({
            ...state,
            waitingForTahomaAppOpening: false,
        }),
    ),

    on(
        BoxActions.openTahomaAppSucceeded,
        (state): UISiteDetailState => ({
            ...state,
            waitingForTahomaAppOpening: false,
        }),
    ),
);

function addSectionRefresh(
    siteSectionExecutionInfo: Record<SiteId, DeviceSectionExecutionInfo>,
    siteId: string,
    deviceId: string,
    sectionTitle: string,
    numberOfExecutions: number,
): Record<SiteId, DeviceSectionExecutionInfo> {
    const deviceSections = siteSectionExecutionInfo[siteId]?.[deviceId] || [];

    const duplicateFreeDeviceSections = deviceSections.filter((info) => info.section !== sectionTitle);

    duplicateFreeDeviceSections.push({
        section: sectionTitle,
        pendingRefresh: numberOfExecutions,
        status: SectionExecutionStatus.IN_PROGRESS,
    });

    return {
        ...siteSectionExecutionInfo,
        [siteId]: {
            ...siteSectionExecutionInfo[siteId],
            [deviceId]: duplicateFreeDeviceSections,
        },
    };
}

function decreaseSectionRefreshCount(
    siteSectionExecutionInfo: Record<SiteId, DeviceSectionExecutionInfo>,
    siteId: string,
    deviceId: string,
    sectionTitle: string,
    executionStatus: ExecutionStatus,
): Record<SiteId, DeviceSectionExecutionInfo> {
    const deviceSections = siteSectionExecutionInfo[siteId]?.[deviceId] || [];

    const currentSection = deviceSections.find((section) => section.section === sectionTitle);

    const remainingExecutions = currentSection?.pendingRefresh - 1;
    const updatedSection: SectionExecutionInfo = {
        section: sectionTitle,
        pendingRefresh: remainingExecutions,
        status: computeStatus(currentSection.status, executionStatus, remainingExecutions),
    };

    return {
        ...siteSectionExecutionInfo,
        [siteId]: {
            ...siteSectionExecutionInfo[siteId],
            [deviceId]: deviceSections.filter((s) => s.section !== sectionTitle).concat(updatedSection),
        },
    };
}

function removeSectionRefresh(
    siteSectionExecutionInfo: Record<SiteId, DeviceSectionExecutionInfo>,
    siteId: string,
    deviceId: string,
    sectionTitle: string,
): Record<SiteId, DeviceSectionExecutionInfo> {
    const deviceSections = siteSectionExecutionInfo[siteId]?.[deviceId] || [];

    return {
        ...siteSectionExecutionInfo,
        [siteId]: {
            ...siteSectionExecutionInfo[siteId],
            [deviceId]: deviceSections.filter((s) => s.section !== sectionTitle),
        },
    };
}

function computeStatus(
    currentStatus: SectionExecutionStatus,
    executionStatus: ExecutionStatus,
    remainingExecutions: number,
) {
    if (executionStatus === ExecutionStatus.FAILED || currentStatus === SectionExecutionStatus.FAILED) {
        return SectionExecutionStatus.FAILED;
    }
    return remainingExecutions === 0 ? SectionExecutionStatus.COMPLETED : SectionExecutionStatus.IN_PROGRESS;
}
