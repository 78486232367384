import { createSelector } from '@ngrx/store';
import { AlertSeverity } from '../../../alerts/models/Alert';
import { getAlertsSeverityBySites } from '../../../alerts/store/selectors/alerts';
import { User } from '../../../shared/models/User';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { getSiteById } from '../../../site-detail/store/selectors/site';
import { toShortDate } from '../../../utils/date';
import { getSitesState, SitesState } from '../reducers/sites';

export const getSites = createSelector(getSitesState, ({ sites }: SitesState) => sites);

export interface SiteItemVM {
    site: Site;
    userLogin: User;
    alertsSeverity: AlertSeverity;
    tooltip: string | null;
    date: string;
}

export const getSiteItemVm = (siteId: SiteId) =>
    createSelector(
        getSiteById(siteId),
        getUserLoginState,
        getAlertsSeverityBySites,
        (site, userLogin, alertsSeverityBySites): SiteItemVM => {
            return {
                site,
                userLogin,
                alertsSeverity: alertsSeverityBySites[siteId] ?? null,
                tooltip: site ? getSiteItemTooltip(site) : '',
                date: site ? toShortDate(site.activateDate) : '',
            };
        },
    );

function getSiteItemTooltip(site: Site): string | null {
    if (!site.isServego) {
        return 'ACTIVATE_SERVEGO_TEXT';
    }
    if (site.isToolSite) {
        return 'TOOL_SITE_DESCRIPTION';
    }
    return null;
}
