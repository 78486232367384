import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../AppConfig';
import constants from '../../../shared/const';
import { APP_CONFIG } from '../../../shared/injectionTokens';
import { LoaderComponent } from '../../../shared/loader/loader.component';
import { User } from '../../../shared/models/User';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { AppState } from '../../../shared/store/app-state';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import {
    SomfyproLogoComponent,
    SomfyproLogoComponentVariant,
} from '../../../site-detail/components/dummies-components/logos/somfypro-logo/somfypro-logo.component';
import { SiteService } from '../../../site-detail/services/site.service';
import { currentYear } from '../../../utils/date';
import { getSomfyProUrl } from '../../../utils/url';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    standalone: true,
    imports: [LoaderComponent, SomfyproLogoComponent, FormsModule, TranslateModule],
})
export class OnboardingComponent implements OnInit, OnDestroy {
    public readonly userLogin$: Observable<User>;
    private userLogin: User;
    private unsubscribe$: Subject<void> = new Subject();
    public noOnboardingNextTime = false;
    public loading = false;
    public somfyProUrl: string;
    protected readonly SomfyproLogoComponentVariant = SomfyproLogoComponentVariant;

    constructor(
        private router: Router,
        private siteService: SiteService,
        private localStorageService: LocalStorageService,
        private store: Store<AppState>,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {
        this.userLogin$ = this.store.select(getUserLoginState);
    }

    ngOnInit(): void {
        this.userLogin$.pipe(takeUntil(this.unsubscribe$)).subscribe((user: User) => {
            this.userLogin = user;
            this.somfyProUrl = getSomfyProUrl(this.userLogin.country, this.appConfig.name);
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public currentYear(): number {
        return currentYear();
    }

    public onButtonClick() {
        this.loading = true;
        this.localStorageService.setItem(constants.LOCAL_STORAGE_BYPASS_ONBOARDING, true);

        this.siteService
            .updateOnboardingStatus(this.noOnboardingNextTime)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.loading = false;
                this.router.navigate(['/sites']);
            });
    }
}
