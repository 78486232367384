import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractToaster } from '../AbstractToaster';

@Component({
    selector: 'app-toaster-success',
    templateUrl: './toaster-success.component.html',
    styleUrls: ['./toaster-success.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class ToasterSuccessComponent extends AbstractToaster {}
