import { DateTime, Settings } from 'luxon';
import { DEFAULT_LOCALE } from './url';

export function setDefaultLocale(locale: string): void {
    Settings.defaultLocale = locale;
}

export function setDefaultZone(zone: string): void {
    Settings.defaultZone = zone;
}

function isNumber(date: Date | string | number): date is number {
    return typeof date === 'number' || (typeof date === 'string' && /^[0-9]+$/.test(date));
}

function parseDate(date: Date | string | number | null | undefined): DateTime {
    if (!date) {
        return DateTime.now();
    }
    if (isNumber(date)) {
        const len = (date + '').length;
        if (len === 10) {
            return DateTime.fromSeconds(+date);
        }
        if (len === 13) {
            return DateTime.fromMillis(+date);
        }
        throw new Error('invalid_date');
    }
    if (typeof date === 'string') {
        // FIXME ISO or RFC or ?
        return DateTime.fromISO(date);
    }
    return DateTime.fromJSDate(date);
}

const DATETIME_FULL = { ...DateTime.DATETIME_FULL, timeZoneName: undefined };

export const toShortDate = (date): string => parseDate(date).toLocaleString(DateTime.DATE_SHORT);
export const toFullDate = (date): string => parseDate(date).toLocaleString(DateTime.DATE_FULL);
export const toFullDateTime = (date?): string => parseDate(date).toLocaleString(DATETIME_FULL);
export const toTime = (date): string => parseDate(date).toLocaleString(DateTime.TIME_SIMPLE);
export const toHugeDate = (date): string => parseDate(date).toLocaleString(DateTime.DATE_HUGE);
export const getMinDate = (): string => DateTime.now().toFormat('yyyy-MM-dd');
export const currentYear = (): number => DateTime.now().year;
export const getExpireTime = (seconds: number): number => DateTime.now().plus({ seconds }).valueOf();
export const getValueOf = (date?: string): number => parseDate(date).valueOf();
export const now = (): number => DateTime.now().valueOf();
export const fromInput = (date): number => DateTime.fromSQL(date).valueOf();
export const stringDateToUnix = (date: string): number =>
    DateTime.fromFormat(date, 'MMMM, dd yyyy HH:mm:ss', {
        locale: DEFAULT_LOCALE,
    }).valueOf();

const INTERVENTION_DURATION = 8; // in hours

type RemainingTime = {
    remainingHours: string;
    remainingMins: string;
};

export function getRemainingTime(date: number): RemainingTime {
    const time = parseDate(date).plus({ hours: INTERVENTION_DURATION }).diffNow();
    if (time.valueOf() <= 0) {
        return undefined;
    }
    const [remainingHours, remainingMins] = time.toFormat('h:mm').split(':');
    return { remainingHours, remainingMins };
}

export function secondsToTimeObject(secs: number) {
    const hoursValue = Math.floor(secs / 3600);
    const minutesValue = Math.floor((secs % 3600) / 60);
    const secondsValue = Math.round(secs % 60);
    return {
        hours: hoursValue.toString().padStart(2, '0'),
        minutes: minutesValue.toString().padStart(2, '0'),
        seconds: secondsValue.toString().padStart(2, '0'),
    };
}

export function getDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
}

export function getMinuteTimestamp() {
    return Math.round(Date.now() / (60 * 1000));
}
