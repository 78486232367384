import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { OauthService } from '../../core/services/oauth.service';
import { APP_CONFIG } from '../injectionTokens';
import { NavigationService } from '../services/NavigationService';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private oauthService: OauthService,
        private navigationService: NavigationService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const currentUrl = this.navigationService.getUrl();

        if (!this.oauthService.isAuthenticated() && !route.queryParams.return_to && !route.queryParams.jwt) {
            this.goToSsoLoginPage(currentUrl);
            return false;
        }

        if (route.queryParams.return_to) {
            const redirectionUrl = this.getReturnUrl(route);
            if (!this.urlsHaveSameDomain(redirectionUrl, currentUrl)) {
                this.switchToRedirectionUrlDomain(redirectionUrl, currentUrl);
                return false;
            }
        }

        if (route.queryParams.jwt) {
            const redirectionUrl = this.getReturnUrl(route);
            this.oauthService
                .authenticate(route.queryParams.jwt)
                .pipe(tap(() => this.navigationService.gotoUrl(redirectionUrl)))
                .subscribe();
            return false;
        }

        return true;
    }

    private goToSsoLoginPage(currentUrl: string) {
        this.navigationService.gotoUrl(`${this.appConfig.sso.servegoServiceUrl}?return_to=${btoa(currentUrl)}`);
    }

    private switchToRedirectionUrlDomain(redirectionUrl: string, currentUrl: string) {
        const consistentUrl = new URL(redirectionUrl);
        consistentUrl.hash = new URL(currentUrl).hash;
        this.navigationService.gotoUrl(consistentUrl.href);
    }

    private urlsHaveSameDomain(redirectionUrl: string, currentUrl: string) {
        return new URL(redirectionUrl).hostname === new URL(currentUrl).hostname;
    }

    private getReturnUrl(route: ActivatedRouteSnapshot) {
        const { return_to: encodedReturnToUrl } = route.queryParams;
        return atob(encodedReturnToUrl).replace(/(#|#\/)$/, '');
    }
}
