import { createActionGroup, props } from '@ngrx/store';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { DeviceId } from '../../models/Device';
import { Execution } from '../../models/Execution';

export const BoxActions = createActionGroup({
    source: 'Box',
    events: {
        boxRebootRequested: props<{
            deviceId: DeviceId;
        }>(),
        boxRebootStarted: props<{
            command: RebootBoxCommand;
            pendingTask: Execution;
        }>(),
        openTahomaAppRequested: props<{
            deviceId: string;
            boxPin: string;
            userEmail: string;
        }>(),
        tahomaAppAccessAllowed: props<{
            deviceId: string;
            token: string;
            boxPin: string;
            userEmail: string;
        }>(),
        openTahomaAppSucceeded: props<{ boxPin: string }>(),
        openTahomaAppFailed: props<{ error: Error }>(),
    },
});
