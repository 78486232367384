<span class="alerts"> {{ nbNewHighAlerts + nbNewNormalAlerts }} </span>
<label> {{ 'NEW_ALERTS' | translate }} : </label>

<app-alert-severity [severity]="ALERT_SEVERITY.HIGH" class="severity-high"/>
<span> {{ nbNewHighAlerts }} </span>

<app-alert-severity [severity]="ALERT_SEVERITY.NORMAL" class="severity-normal"/>
<span> {{ nbNewNormalAlerts }} </span>


