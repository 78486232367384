import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { NumberFormatter } from '../../../../../../shared/models/Formatter';
import { NumberData } from '../../StateData';
import { StateEditorComponent } from '../StateEditorComponent';

export interface NumberEditorConfig {
    min: number;
    max: number;
    step?: number;
    formatter?: NumberFormatter;
}

@Component({
    selector: 'app-number-state-editor',
    standalone: true,
    imports: [MatSliderModule, TranslateModule, MatDialogModule, MatInputModule, FormsModule],
    templateUrl: './number-editor.component.html',
    styleUrls: ['./number-editor.component.scss'],
})
export class NumberEditorComponent implements OnInit, StateEditorComponent<NumberData, NumberEditorConfig> {
    @Input({ required: true }) value: NumberData;
    @Input({ required: true }) config: NumberEditorConfig;
    @Output() valueChange = new EventEmitter<NumberData>();
    scaleFactor: string;
    min: number;
    max: number;
    step = 1;
    @HostBinding('class.invalid') isInvalid = false;

    editedValue: number;

    get outputValue() {
        return Math.round(eval(`${this.editedValue} / ${this.scaleFactor}`));
    }

    get innerValue() {
        return Math.round(this.scaleValue(this.value));
    }

    ngOnInit() {
        this.scaleFactor = this.config.formatter?.scaleFactor ?? '1';
        this.editedValue = this.innerValue;
        this.min = Math.ceil(this.scaleValue(this.config.min));
        this.max = Math.floor(this.scaleValue(this.config.max));
        this.step = this.config.step ? Math.round(this.scaleValue(this.config.step)) : 1;
    }

    onInputChange() {
        this.isInvalid = !this.isValid();
        this.valueChange.emit(this.outputValue);
    }

    isValid(): boolean {
        return this.editedValue >= this.min && this.editedValue <= this.max;
    }

    private scaleValue(value: number) {
        return eval(`${value} * ${this.scaleFactor}`);
    }
}
