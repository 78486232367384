import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { removeToasterMessage } from '../../../../site-detail/store/actions/ui';
import { ToasterSiteDeviceErrorMessageContent } from '../../../models/Toaster';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-site-device-error-item',
    templateUrl: './site-device-error-item.component.html',
    styleUrls: ['./site-device-error-item.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class SiteDeviceErrorItemComponent implements OnInit {
    @Input()
    content: ToasterSiteDeviceErrorMessageContent;

    @Input()
    id: string;

    translatedMessage = '';

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        if (this.content.sectionLabel) {
            const stateLabelTranslation = this.translateService.instant(this.content.sectionLabel);
            this.translatedMessage = this.translateService.instant(this.content.errorLabel, {
                sectionLabel: stateLabelTranslation,
            });
        } else {
            this.translatedMessage = this.translateService.instant(this.content.errorLabel);
        }
    }

    onClick() {
        // Redirect to good page
        if (this.content.deviceId) {
            this.router.navigate([`/site/${this.content.siteId}/${this.content.deviceId}`]);
        } else {
            this.router.navigate([`/site/${this.content.siteId}`]);
        }
    }

    onClose() {
        // Remove item from toaster
        this.store.dispatch(removeToasterMessage({ id: this.id }));
    }
}
