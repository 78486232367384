declare global {
    interface Window {
        tC: any;
        tc_vars: any;
        tc_events_global: any;
        // iAdvize
        idzCustomData: any;
        opr: any;
        opera: any;
        StyleMedia: any;
        HTMLElement: any;
        chrome: any;
        CSS: any;
        safari: any;
    }

    interface Document {
        documentMode: any;
    }

}

// eslint-disable-next-line no-var
declare var InstallTrigger: any;

/* eslint-disable */
export function detectBrowser() {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
    // @ts-ignore
    const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function(p) {
        return p?.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

// Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    document.documentElement.setAttribute('data-browser-firefox', `${isFirefox}`);
    document.documentElement.setAttribute('data-browser-chrome', `${isChrome}`);
    document.documentElement.setAttribute('data-browser-safari', `${isSafari}`);
    document.documentElement.setAttribute('data-browser-opera', `${isOpera}`);
    document.documentElement.setAttribute('data-browser-ie', `${isIE}`);
    document.documentElement.setAttribute('data-browser-edge', `${isEdge}`);
    document.documentElement.setAttribute('data-browser-blink', `${isBlink}`);
    document.documentElement.setAttribute('data-browser-iOS', `${iOS}`);
    document.documentElement.setAttribute('data-browser-iOSSafari', `${iOSSafari}`);
}
