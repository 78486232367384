import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { User } from '../../models/User';
import * as LoginActions from '../actions/login';

export interface LoginState {
    user: User;
}

export const LOGIN_INITIAL_STATE: LoginState = {
    user: {
        display_name: '',
        external_provider_id: '',
        family_name: '',
        user_id: '',
        id: '',
        given_name: '',
        user_name: '',
        cgu: -1,
        country: '',
        compagny: '',
    },
};

export default createReducer(
    LOGIN_INITIAL_STATE,

    on(
        LoginActions.setLoginUser,
        (state, { loginuser: user }): LoginState => ({
            ...state,
            // User could have some properties not set (id, cgu, country, compagny -
            // or is User type wrong?) so merge default state and given user
            user: { ...state.user, ...user },
        }),
    ),
);

export const getLoginState = createFeatureSelector<LoginState>('login');
