import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from '../../../shared/store/app-state';
import { FavoriteSitesVm, getFavoriteSitesVm } from '../../store/selectors/favorite-site';
import { FavoriteItemComponent } from '../favorite-item/favorite-item.component';

@Component({
    selector: 'app-favorite-list',
    standalone: true,
    imports: [TranslateModule, FavoriteItemComponent, AsyncPipe],
    templateUrl: './favorite-list.component.html',
    styleUrl: './favorite-list.component.scss',
})
export class FavoriteListComponent implements OnInit {
    protected data$: Observable<FavoriteSitesVm>;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.data$ = this.store.select(getFavoriteSitesVm);
    }
}
